import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notifications } from '@core/models/notifications';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class NotificationService {

  constructor(private _http: HttpClient) { }

  getNotifications(): Observable<Notifications>{
    return this._http.get<any>(`${environment.baseUrl}/notification`);
  }

  deleteNotification(type: number, contentId: number): Observable<any>{
    return this._http.delete<any>(`${environment.baseUrl}/notification?type=${type}&contentId=${contentId}`)
  }

  clearNotifications(): Observable<any>{
    return this._http.delete<any>(`${environment.baseUrl}/notification/all`)
  }
}
