import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Subject } from '@core/models';
import { Observable, shareReplay } from 'rxjs';
import { Params } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SubjectService {
  url: string;
  subjectsWithContent$ = this.getSubjectsWithContent().pipe(shareReplay());

  constructor(private _http: HttpClient) {this.url = `${environment.baseUrl}`}

  /**
   * Get all subjects
  */
  getAll(): Observable<any>{
    return this._http.get<any>(`${environment.apiUrl}/subject`);
  }

  /**
   * Check if subject has data
  */
  checkContentStatus(subjectId: number,form: number): Observable<any>{
    return this._http.get<any>(`${environment.apiUrl}/subject/hasContent?subjectId=${subjectId.toString()}&form=${form.toString()}'`);
  }
 
  /**
   * Get subjects with lessons
  */
  // getSubjectsWithContent(): Observable<any>{
  //   return this._http.get(`${this.url}/subject/content`);
  // }

  getSubjectsWithContent(): Observable<any>{
    if(!this.subjectsWithContent$) {
      this.subjectsWithContent$ = this._http.get<any>(`${environment.apiUrl}/subject/content`).pipe(shareReplay(1))
    }
    return this.subjectsWithContent$;
  }

  getSubjectData(subjectId: number): Observable<any>{
    return this._http.get(`${this.url}/subject/content/records?subjectId=${subjectId}`);
  }

  getSubjectDataByForm(subjectId: number, form: number): Observable<any>{
    return this._http.get<any>(`${environment.apiUrl}/subject/content/records?subjectId=${subjectId}&form=${form}`);
  }

  getTopicsBySubjectAndForm(subjectId: number, classLevel: number): Observable<any>{
    let params = new HttpParams()
    params = params.append('subjectId', subjectId)
    params = params.append('classLevel', classLevel)
    return this._http.get<any>(`${environment.apiUrl}/topic`,{params:params});
  }

  getTopicsWithLessons(subjectId: number, form: number): Observable<any>{
    return this._http.get<any>(`${environment.apiUrl}/lesson/topics?subjectId=${subjectId}&classLevel=${form}`);
  }

  postSelectedSubjects(params: Params): Observable<unknown> {
    return this._http.post<unknown>(`${environment.apiUrl}/subject`, params)
  }

  removeSubjects(params: Params): Observable<unknown> {
    return this._http.put<unknown>(`${environment.apiUrl}/subject`, params)
  }

  createStudentDashboardContent(params: Params): Observable<unknown> {
    return this._http.post<unknown>(`${environment.apiUrl}/content`, params)
  }
   
}