<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            width="36"
          />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content justify-content-between">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <li *ngIf="!hideNightVision" class="nav-item d-none d-lg-block">
      <a
        type="button"
        class="nav-link nav-link-style btn"
        (click)="toggleDarkSkin()"
        placement="bottom"
        ngbTooltip="Enable/Disable Dark Mode Color Theme"
      >
        <span
          [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'"
          class="ficon font-medium-5 feather"
        ></span>
      </a>
    </li>
    <!--/ Toggle skin -->
    <!-- Toggle skin -->

    <!--/ Toggle skin -->
    <ng-container *ngIf="showContentForm && isStudent">
    <app-student-topic-access></app-student-topic-access>
    </ng-container>

    <div class="timer p-auto rounded" *ngIf="showTimer">
      <div class="d-flex align-items-center">
        <span class="countdown">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="#31ABE0"
              d="M10 3q-.425 0-.713-.288T9 2q0-.425.288-.713T10 1h4q.425 0 .713.288T15 2q0 .425-.288.713T14 3h-4Zm2 11q.425 0 .713-.288T13 13V9q0-.425-.288-.713T12 8q-.425 0-.713.288T11 9v4q0 .425.288.713T12 14Zm0 8q-1.85 0-3.488-.713T5.65 19.35q-1.225-1.225-1.938-2.863T3 13q0-1.85.713-3.488T5.65 6.65q1.225-1.225 2.863-1.938T12 4q1.55 0 2.975.5t2.675 1.45l.7-.7q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-.7.7Q20 8.6 20.5 10.025T21 13q0 1.85-.713 3.488T18.35 19.35q-1.225 1.225-2.863 1.938T12 22Zm0-2q2.9 0 4.95-2.05T19 13q0-2.9-2.05-4.95T12 6Q9.1 6 7.05 8.05T5 13q0 2.9 2.05 4.95T12 20Zm0-7Z"
            />
          </svg>
          {{ formatTime(countdownValue) }}
        </span>
      </div>
    </div>

  </div>
  <ul class="nav navbar-nav align-items-center">
    <!-- Notification -->
    <ng-template [ngIf]="isStudent">
      <app-navbar-notification></app-navbar-notification>
    </ng-template>
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container *ngIf="currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder">{{
              currentUser.name | titlecase
            }}</span>
            <span class="font-small-4">
              {{
                currentUser.userRole === 200
                  ? "Customer Support"
                  : currentUser.userRole === 100
                  ? "Super Admin"
                  : currentUser.userRole === 60
                  ? "Parent"
                  : currentUser.userRole === 50
                  ? "Admin"
                  : currentUser.userRole === 40
                  ? "Teacher"
                  : "Student"
              }}
              <ng-container
                *ngIf="currentUser.userRole === 30 && currentUser.form"
              >
                {{
                  " | Form " +
                    currentUser.form +
                    " " +
                    currentUser.studentProfile.streamName
                }}
              </ng-container>
            </span>
          </div>
          <span class="avatar">
            <img
              class="round"
              src="../../../../assets/images/portrait/small/avatar.png"
              alt="avatar"
              height="40"
              width="40"
            />
            <span class="avatar-status-online"></span>
          </span>
        </ng-container>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <a ngbDropdownItem [routerLink]="['/profile']"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        >
        <!--a ngbDropdownItem [routerLink]="['/account-settings/']"><span [data-feather]="'settings'" [class]="'mr-50'"></span> Settings</a-->
        <!--  
          <a ngbDropdownItem [routerLink]="['/']"><span [data-feather]="'check-square'" [class]="'mr-50'"></span> Task</a>
          <a ngbDropdownItem [routerLink]="['/']">
            <span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chats
          </a>
        -->
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="modalOpenVC(shareZl)">
          <span [data-feather]="'share-2'" [class]="'mr-50'"></span>
          Share
        </a>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="logout()">
          <span [data-feather]="'power'" [class]="'mr-50'"></span>
          Logout
        </a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>

<!--Modal for upgrade-->
<ng-template id="upgradeModal" #upgradeModal let-modal>
  <div class="modal-header" id="payment-modal-header">
    <button
      type="button"
      class="close"
      (click)="cancelPaymentProcess()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body p-md-3 p-sm-1"
    id="payment-modal-body"
    style="background-color: #f8f8f8; /*min-height: 48rem*/"
    tabindex="0"
    ngbAutofocus
  >
    <app-upgrade-page
      (cancelPaymentProcessEvt)="cancelPaymentProcess()"
      (processStageEvt)="processStage($event)"
    ></app-upgrade-page>
  </div>
</ng-template>

<!-- share zl modal -->
<ng-template #shareZl let-modal>
  <div class="share">
    <button
      type="button"
      class="close btn close-button"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="equal-columns">
      <div class="column">
        <div class="mx-2 my-2 text-center">
          <h2 class="zl-black pt-2 pb-2">Zeraki Learning Rocks!</h2>
          <img
            src="assets/images/elements/learning illustration.svg"
            alt="share"
          />
        </div>
      </div>
      <div class="column share-bg rounded-right">
        <div class="mx-2 my-2 text-white">
          <h2 class="text-white pt-2 pb-2">Share</h2>
          <p class="card-text-1 mb-2">
            Tell your friends, family and classmates to join Zeraki Learning.
          </p>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              [value]="currentUrl"
              readonly
            />
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-icon share-btn"
                (click)="shareUrl()"
                rippleEffect
              >
                <span [data-feather]="'copy'"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</ng-template>
