<div>
  <div class="header">
    <div>
      <nav
        class="navbar navbar-expand-lg fixed-top bg-darken-4"
        style="background-color: #122800"
      >
        <div class="container-fluid">
          <a
            (click)="scrollToElement(pagetop)"
            class="navbar-brand pb-2 cursor-pointer"
          >
            <img src="assets/img/learning.png" alt="logo" class="nav-image" />
          </a>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="customDiv"
          >
            <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
              <li class="nav-item">
                <a
                  class="nav-link navli cursor-pointer"
                  (click)="scrollToElement(features)"
                  >Features</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link navli cursor-pointer"
                  (click)="scrollToElement(testimonial)"
                  >Testimonials</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link navli cursor-pointer"
                  (click)="scrollToElement(about)"
                  >About Us</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link navli cursor-pointer"
                  (click)="scrollToElement(contact)"
                  >Contact</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div #pagetop>
        <div class="container-xxl pt-150">
          <div class="d-lg-flex pt-lg-50">
            <div class="flex-grow-1 d-sm-flex d-none">
              <div
                class="text-lg-start pt-40"
                [class]="{ 'd-none': showForms }"
              >
                <!-- <img src="assets/img/learning_WHT.png" height="40px" class="mb-2"> -->
                <h1 class="text-white my-1 home-text">
                  Learning <br />
                  Re-Imagined.
                </h1>
                <p class="font-medium-5 text-white my-2">
                  Learn from highly experienced teachers, take tests and track
                  your performance.
                </p>
                <!-- <a href="https://play.google.com/store/apps/details?id=co.ke.litemore.android.lda" target="_blank">
                                    <img src="assets/img/en_badge_web_generic.png" height="50px" class="my-1">
                                </a> -->
                <button
                  class="btn btn-secondary btn-md d-sm-none d-flex mt-2"
                  style="width: fit-content"
                  type="button"
                  (click)="showForms = true"
                >
                  Sign In
                  <i data-feather="arrow-right" class="pl-2"></i>
                </button>
              </div>
            </div>
            <div class="row d-sm-flex" [class]="{ 'd-flex': showForms }">
              <div class="col-12">
                <div class="card loginformwidth">
                  <div class="card-header pb-0 mx-1">
                    <h4 class="p-0" *ngIf="login || register">
                      {{ login ? "Login to" : "Create" }} your account.
                    </h4>
                    <h4 class="p-0" *ngIf="forgot || showForgotUsername">
                      Forgot {{ forgot ? "password" : "username" }}.
                    </h4>
                  </div>
                  <div class="card-body login-form mx-1 mt-0 pt-0">
                    <section *ngIf="login || forgot || forgotUsername">
                      <ul
                        ngbNav
                        #navJustified="ngbNav"
                        class="nav-tabs nav-justified"
                      >
                        <li ngbNavItem>
                          <a ngbNavLink (click)="switchLabels('Student')"
                            >Student</a
                          >
                          <ng-template ngbNavContent> </ng-template>
                        </li>
                        <li ngbNavItem>
                          <a ngbNavLink (click)="switchLabels('Teacher')"
                            >Teacher</a
                          >
                          <ng-template ngbNavContent> </ng-template>
                        </li>
                        <li ngbNavItem>
                          <a ngbNavLink (click)="switchLabels('Parent')"
                            >Parent</a
                          >
                          <ng-template ngbNavContent> </ng-template>
                        </li>
                      </ul>
                      <div [ngbNavOutlet]="navJustified" class="mt-2"></div>
                      <!-- Login Form-->
                      <form
                        *ngIf="login"
                        class="auth-login-form"
                        [formGroup]="loginForm"
                        (ngSubmit)="onSubmit()"
                      >
                        <!-- Alert Boxes -->
                        <!-- <ngb-alert
                          [type]="'secondary'"
                          [dismissible]="false"
                          *ngIf="roleIsSchool && !feedback"
                        >
                          <div class="alert-body">
                            <p>
                              If your school is not using
                              <a
                                href="https://analytics.zeraki.co.ke"
                                target="_blank"
                                >Zeraki Analytics</a
                              >, please register below and we shall get back to
                              you soon.
                            </p>
                          </div>
                        </ngb-alert>
                        <ngb-alert
                          [type]="'primary'"
                          [dismissible]="false"
                          *ngIf="feedback"
                        >
                          <div class="alert-body">
                            <p>{{ feedback }}</p>
                          </div>
                        </ngb-alert> -->
                        <ngb-alert
                          [type]="'info'"
                          [dismissible]="false"
                          *ngIf="teacherHint"
                        >
                          <div class="alert-body">
                            <p>
                              Use your Zeraki Analytics username to log in to
                              your account.
                            </p>
                          </div>
                        </ngb-alert>
                        <ngb-alert
                          [type]="'danger'"
                          [dismissible]="false"
                          *ngIf="error?.title || error?.body"
                        >
                          <div class="alert-body" *ngIf="error?.body">
                            <div [innerHTML]="error?.body"></div>
                          </div>
                        </ngb-alert>
                        <!-- End of Alert Boxes-->
                        <div class="form-group mt-2">
                          <div class="d-flex justify-content-between">
                            <label class="form-label" for="login-username">{{
                              userNameText
                            }}</label>
                            <a
                              *ngIf="userChecked"
                              (click)="changeUser()"
                              href="javascript:void(0)"
                              ><small>Change User</small></a
                            >
                          </div>
                          <input
                            #username
                            type="text"
                            formControlName="username"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid':
                                (submitted || usernameCheck) &&
                                f.username.errors
                            }"
                            placeholder="{{ userNamePlaceholder }}"
                            aria-describedby="login-username"
                            autofocus=""
                            tabindex="1"
                            id="login-username"
                          />
                          <div
                            *ngIf="submitted && f.username.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="f.username.errors.required">
                              Username is required
                            </div>
                            <div *ngIf="f.username.errors.format">
                              Provide a valid phonenumber or username
                            </div>
                          </div>
                          <div
                            *ngIf="usernameCheck && f.username.errors?.format"
                            class="invalid-feedback"
                          >
                            <div>
                              Provide a valid {{ userNameText | lowercase }}
                            </div>
                          </div>
                          <div
                            *ngIf="!userChecked"
                            class="d-flex pt-1 justify-content-end"
                          >
                            <a
                              *ngIf="
                                login &&
                                userNameText == 'Username or Phone Number'
                              "
                              (click)="switchForm('forgotUsername')"
                              href="javascript:void(0)"
                              class="mt-10"
                              >Forgot Username?</a
                            >
                          </div>
                        </div>
                        <div class="form-group mt-2" *ngIf="userChecked">
                          <div class="d-flex justify-content-between">
                            <label for="login-password">Password</label>
                            <a
                              (click)="switchForm('forgot')"
                              href="javascript:void(0)"
                              ><small>Forgot Password?</small></a
                            >
                          </div>
                          <div
                            class="input-group input-group-merge form-password-toggle"
                          >
                            <input
                              [type]="passwordTextType ? 'text' : 'password'"
                              formControlName="password"
                              class="form-control form-control-merge"
                              [ngClass]="{
                                'is-invalid error':
                                  submitted && f.password.errors
                              }"
                              aria-describedby="login-password"
                              tabindex="2"
                              id="login-password"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text cursor-pointer">
                                <i
                                  class="feather font-small-4"
                                  [ngClass]="{
                                    'icon-eye-off': passwordTextType,
                                    'icon-eye': !passwordTextType
                                  }"
                                  (click)="togglePasswordTextType()"
                                >
                                </i
                              ></span>
                            </div>
                          </div>
                          <div
                            *ngIf="submitted && f.password.errors"
                            class="invalid-feedback"
                            [ngClass]="{
                              'd-block': submitted && f.password.errors
                            }"
                          >
                            <div *ngIf="f.password.errors.required">
                              Password is required
                            </div>
                          </div>
                        </div>
                        <div class="form-group" *ngIf="userChecked">
                          <div class="custom-control custom-checkbox">
                            <input
                              formControlName="rememberMe"
                              class="custom-control-input"
                              id="remember-me"
                              type="checkbox"
                              tabindex="3"
                            />
                            <label
                              class="custom-control-label"
                              for="remember-me"
                            >
                              Remember Me</label
                            >
                          </div>
                        </div>
                        <button
                          *ngIf="!userChecked"
                          [disabled]="loading"
                          class="btn btn-primary btn-block"
                          tabindex="4"
                          rippleEffect
                          type="button"
                          (click)="checkUsername(username.value)"
                        >
                          <span
                            *ngIf="loading"
                            class="spinner-border spinner-border-sm mr-1"
                          ></span
                          >Continue
                        </button>
                        <button
                          *ngIf="userChecked"
                          [disabled]="!userChecked || loading"
                          class="btn btn-primary btn-block"
                          tabindex="4"
                          rippleEffect
                        >
                          <span
                            *ngIf="loading"
                            class="spinner-border spinner-border-sm mr-1"
                          ></span
                          >Sign in
                        </button>
                      </form>
                      <!-- End of Login Form -->

                      <!-- Forgot Password Form-->
                      <form
                        class="auth-forgot-password-form mt-2"
                        [formGroup]="forgotPasswordForm"
                        (ngSubmit)="onSubmitForgot()"
                        *ngIf="forgot"
                      >
                        <!-- <div class="form-group" *ngIf="!newPasswords && !resetCodeGenerated">
                                      <label class="form-label" for="forgot-password-username">Username</label>
                                      <input
                                        class="form-control"
                                        type="text"
                                        formControlName="username"
                                        placeholder="0700 000 000 / john@example.com"
                                        aria-describedby="forgot-password-username"
                                        autofocus=""
                                        tabindex="1"
                                        [ngClass]="{ 'is-invalid': f_submitted && ff.username.errors }"
                                      />
                                      <div *ngIf="f_submitted && ff.username.errors" class="invalid-feedback">
                                        <div *ngIf="ff.username.errors.required">Username is required</div>
                                      </div>
                                    </div> -->

                        <!-- ngb-alert component -->
                        <ngb-alert
                          [type]="'info'"
                          [dismissible]="false"
                          *ngIf="
                            !forgotPasswordHint &&
                            !(error?.title || error?.body)
                          "
                        >
                          <div class="alert-body">
                            {{ passwordHint }}
                          </div>
                        </ngb-alert>
                        <!--/ ngb-alert component -->
                        <ngb-alert
                          [type]="'danger'"
                          [dismissible]="false"
                          *ngIf="error?.title || error?.body"
                        >
                          <div class="alert-body" *ngIf="error?.body">
                            <div [innerHTML]="error?.body"></div>
                          </div>
                        </ngb-alert>

                        <div
                          class="form-group"
                          *ngIf="
                            phoneNumberConfirmation &&
                            !newPasswords &&
                            !resetCodeGenerated
                          "
                        >
                          <label
                            class="form-label"
                            for="forgot-password-phone-number"
                            >Phone Number</label
                          >
                          <input
                            class="form-control"
                            type="text"
                            formControlName="phoneNumber"
                            placeholder="0700 000 000"
                            aria-describedby="forgot-password-phone-number"
                            autofocus=""
                            tabindex="1"
                            [ngClass]="{
                              'is-invalid': f_submitted && ff.phoneNumber.errors
                            }"
                          />
                          <div
                            *ngIf="f_submitted && ff.phoneNumber.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="ff.phoneNumber.errors.required">
                              Phone Number is required
                            </div>
                          </div>
                        </div>

                        <button
                          [disabled]="loading"
                          class="btn btn-primary btn-block"
                          tabindex="2"
                          rippleEffect
                          *ngIf="!resetCodeGenerated && !newPasswords"
                        >
                          <span
                            *ngIf="loading"
                            class="spinner-border spinner-border-sm mr-1"
                          ></span>
                          Request reset code
                        </button>
                        <!--button 
                                        type="button" 
                                        (click)="processPhoneNumber()" 
                                        [disabled]="loading" 
                                        class="btn btn-primary btn-block" 
                                        tabindex="2" 
                                        rippleEffect 
                                        *ngIf="phoneNumberConfirmation" >
                                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                      Continue
                                    </button-->
                        <!-- <button class="btn btn-primary" (click)="changePassword()">Change</button> -->

                        <div class="form-group" *ngIf="resetCodeGenerated">
                          <label
                            class="form-label"
                            for="forgot-password-reset-code"
                            >Reset Code</label
                          >
                          <input
                            class="form-control"
                            type="text"
                            formControlName="resetCode"
                            aria-describedby="forgot-password-reset-code"
                            autofocus=""
                            tabindex="1"
                            [ngClass]="{
                              'is-invalid': codeSubmitted && ff.resetCode.errors
                            }"
                          />
                          <div
                            *ngIf="codeSubmitted && ff.resetCode.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="ff.resetCode.errors.required">
                              Reset Code is required
                            </div>
                          </div>
                        </div>
                        <!-- <button  type="button"  (click)="processResetCode()" [disabled]="loading" class="btn btn-primary btn-block" tabindex="2" rippleEffect *ngIf="resetCodeGenerated" >
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Continue
                                      </button> -->

                        <div class="form-group" *ngIf="resetCodeGenerated">
                          <label for="register-password" class="form-label"
                            >Password</label
                          >

                          <div
                            class="input-group input-group-merge form-password-toggle"
                          >
                            <input
                              [type]="passwordTextType ? 'text' : 'password'"
                              formControlName="password"
                              class="form-control form-control-merge"
                              placeholder="············"
                              aria-describedby="register-password"
                              tabindex="3"
                              [ngClass]="{
                                'is-invalid error':
                                  f_submitted && ff.password.errors
                              }"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text cursor-pointer"
                                ><i
                                  class="feather font-small-4"
                                  [ngClass]="{
                                    'icon-eye-off': passwordTextType,
                                    'icon-eye': !passwordTextType
                                  }"
                                  (click)="togglePasswordTextType()"
                                ></i
                              ></span>
                            </div>
                          </div>
                          <div
                            *ngIf="f_submitted && ff.password.errors"
                            class="invalid-feedback"
                            [ngClass]="{
                              'd-block': f_submitted && ff.password.errors
                            }"
                          >
                            <div *ngIf="ff.password.errors.required">
                              Password is required
                            </div>
                          </div>
                        </div>
                        <div class="form-group" *ngIf="resetCodeGenerated">
                          <label
                            for="register-confirmPassword"
                            class="form-label"
                            >Re-enter Password</label
                          >

                          <div
                            class="input-group input-group-merge form-password-toggle"
                          >
                            <input
                              [type]="passwordTextType2 ? 'text' : 'password'"
                              formControlName="confirmPassword"
                              class="form-control form-control-merge"
                              placeholder="············"
                              aria-describedby="register-confirmPassword"
                              tabindex="3"
                              [ngClass]="{
                                'is-invalid error':
                                  f_submitted && ff.confirmPassword.errors
                              }"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text cursor-pointer">
                                <i
                                  class="feather font-small-4"
                                  [ngClass]="{
                                    'icon-eye-off': passwordTextType2,
                                    'icon-eye': !passwordTextType2
                                  }"
                                  (click)="togglePasswordTextType2()"
                                ></i>
                              </span>
                            </div>
                          </div>
                          <div
                            *ngIf="f_submitted && ff.confirmPassword.errors"
                            class="invalid-feedback"
                            [ngClass]="{
                              'd-block':
                                f_submitted && ff.confirmPassword.errors
                            }"
                          >
                            <div *ngIf="ff.confirmPassword.errors.required">
                              Password required
                            </div>
                            <div
                              *ngIf="
                                ff.password.value != ff.confirmPassword.value
                              "
                            >
                              Password does not match
                            </div>
                          </div>
                          <div
                            *ngIf="
                              f_submitted &&
                              ff.password.value !== ff.confirmPassword.value
                            "
                            class="invalid-feedback"
                            [ngClass]="{
                              'd-block':
                                f_submitted &&
                                ff.password.value !== ff.confirmPassword.value
                            }"
                          >
                            <div>Password does not match</div>
                          </div>
                        </div>

                        <button
                          type="button"
                          (click)="changePassword()"
                          [disabled]="loading"
                          class="btn btn-primary btn-block"
                          tabindex="2"
                          rippleEffect
                          *ngIf="resetCodeGenerated"
                        >
                          <span
                            *ngIf="loading"
                            class="spinner-border spinner-border-sm mr-1"
                          ></span>
                          Change Password
                        </button>
                      </form>
                      <!-- End of Forgot Password Form-->

                      <!-- forget username form -->
                      <form
                        [formGroup]="forgotUsernameForm"
                        *ngIf="showForgotUsername"
                        (ngSubmit)="onSubmitForgotUsername()"
                      >
                        <!-- ngb-alert component -->
                        <ngb-alert
                          [type]="'info'"
                          [dismissible]="false"
                          *ngIf="!(error?.title || error?.body)"
                        >
                          <div class="alert-body">
                            Enter your parent’s phone number registered with
                            Zeraki Learning to assist in recovery of your
                            account.
                          </div>
                        </ngb-alert>
                        <!--/ ngb-alert component -->
                        <ngb-alert
                          [type]="'danger'"
                          [dismissible]="false"
                          *ngIf="error?.title || error?.body"
                        >
                          <div class="alert-body" *ngIf="error?.body">
                            <div [innerHTML]="error?.body"></div>
                          </div>
                        </ngb-alert>

                        <div class="form-group">
                          <label
                            class="form-label"
                            for="forgot-password-phone-number"
                            >Phone Number</label
                          >
                          <input
                            class="form-control"
                            type="text"
                            formControlName="phoneNumber"
                            placeholder="0700 000 000"
                            aria-describedby="forgot-password-phone-number"
                            autofocus=""
                            tabindex="1"
                            [ngClass]="{
                              'is-invalid':
                                fu_submitted && fu.phoneNumber.errors
                            }"
                          />
                          <div
                            *ngIf="fu_submitted && fu.phoneNumber.errors"
                            class="invalid-feedback"
                          >
                            Phone Number is required
                          </div>
                        </div>

                        <button
                          [disabled]="loading"
                          class="btn btn-primary btn-block"
                          tabindex="2"
                          rippleEffect
                        >
                          <span
                            *ngIf="loading"
                            class="spinner-border spinner-border-sm mr-1"
                          ></span>
                          Request Logins
                        </button>

                        <!-- <div class="mt-2">
                                I remembered my Password
                                <a (click)="switchForm('login')" href="javascript:void(0)"
                                ><span>&nbsp;Login</span></a
                              >
                             </div> -->
                      </form>
                    </section>
                    <!-- Forms -->

                    <!-- Register Form -->

                    <!-- isolate test form -->
                    <!-- <div class="form-group" [formGroup]="registerForm"
                    (ngSubmit)="onSubmitReg()"
                    *ngIf="register && !success">
                      <label for="register-designation" class="form-label"
                        >Designation</label
                      >
                      <select
                        formControlName="designation"
                        class="form-control"
                        aria-describedby="register-designation"
                      >
                        <option value="0" disabled selected>
                          Select a Designation
                        </option>
                        <option
                          *ngFor="let designation of designations"
                          [value]="designation.titleId"
                        >
                          {{ designation.name }}
                        </option>
                      </select>
                    </div> -->
                    <!-- isolate test form  end-->

                    <section *ngIf="register">
                      <ngb-alert
                        [type]="'info'"
                        [dismissible]="false"
                        *ngIf="success"
                      >
                        <div class="alert-body">
                          <p>
                            {{ feedback }}
                          </p>
                        </div>
                      </ngb-alert>

                      <!-- Register Form -->
                      <form
                        class="auth-register-form mt-2"
                        [formGroup]="registerForm"
                        (ngSubmit)="onSubmitReg()"
                        *ngIf="!success"
                      >
                        <ngb-alert
                          [type]="'secondary'"
                          [dismissible]="false"
                          *ngIf="roleIsSchool && !success"
                        >
                          <div class="alert-body">
                            <p>
                              If your school is not using
                              <a
                                href="https://analytics.zeraki.co.ke"
                                target="_blank"
                                >Zeraki Analytics</a
                              >, please register below and we shall get back to
                              you soon.
                            </p>
                          </div>
                        </ngb-alert>
                        <ngb-alert
                          [type]="'primary'"
                          [dismissible]="false"
                          *ngIf="feedback"
                        >
                          <div class="alert-body">
                            <p>{{ feedback }}</p>
                          </div>
                        </ngb-alert>

                        <!-- <ngb-alert
    [type]="'info'"
    [dismissible]="false"
    *ngIf="register && roleIsSchool && !success"
  >
    <div class="alert-body">
      <p>If your school is not using <span class="text-success">Zeraki Analytics</span>, please register below and we shall get back to you soon.</p>
    </div>
  </ngb-alert>
 
    <ngb-alert
    [type]="'primary'"
    [dismissible]="false"
    *ngIf="register && success"
  >
    <div class="alert-body">
      <p>{{ feedback }}</p>
    </div>
  </ngb-alert> -->

                        <div class="form-group">
                          <label for="register-role" class="form-label"
                            >Register as a</label
                          >
                          <select
                            formControlName="role"
                            (change)="roleChanged()"
                            class="form-control"
                            aria-describedby="register-role"
                          >
                            <option [value]="0" disabled selected>
                              Select a User Type
                            </option>
                            <option
                              *ngFor="let role of roles"
                              [ngValue]="role.id"
                            >
                              {{ role.role }}
                            </option>
                          </select>
                        </div>

                        <div class="form-group">
                          <label for="register-name" class="form-label"
                            >Name</label
                          >
                          <input
                            type="text"
                            formControlName="name"
                            class="form-control"
                            placeholder="Enter your name in full"
                            aria-describedby="register-name"
                            tabindex="1"
                            autofocus
                            [ngClass]="{
                              'is-invalid': r_submitted && rf.name.errors
                            }"
                          />
                          <div
                            *ngIf="r_submitted && rf.name.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="rf.name.errors.required">
                              Name is required
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="register-phoneNumber" class="form-label"
                            >Phone Number</label
                          >
                          <input
                            type="text"
                            formControlName="phone"
                            class="form-control"
                            placeholder="0700111222"
                            aria-describedby="register-phoneNumber"
                            tabindex="2"
                            [ngClass]="{
                              'is-invalid': r_submitted && rf.phone.errors
                            }"
                          />
                          <div
                            *ngIf="r_submitted && rf.phone.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="rf.phone.errors.required">
                              Phone Number is required
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="register-email" class="form-label"
                            >Email <span class="text-muted" style="font-size: small;">(Optional)</span></label
                          >
                          <input
                            type="email"
                            id="register-email"
                            formControlName="email"
                            class="form-control"
                            placeholder="xyz@email.com"
                            aria-describedby="register-email"
                            tabindex="2"
                          />
                        </div>

                        <div
                          class="form-group"
                          *ngIf="rf.role.value && !roleIsSchool"
                        >
                          <label for="register-password" class="form-label"
                            >Password</label
                          >

                          <div
                            class="input-group input-group-merge form-password-toggle"
                          >
                            <input
                              [type]="passwordTextType ? 'text' : 'password'"
                              formControlName="password"
                              class="form-control form-control-merge"
                              placeholder="············"
                              aria-describedby="register-password"
                              tabindex="3"
                              [ngClass]="{
                                'is-invalid error':
                                  r_submitted && rf.password.errors
                              }"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text cursor-pointer"
                                ><i
                                  class="feather font-small-4"
                                  [ngClass]="{
                                    'icon-eye-off': passwordTextType,
                                    'icon-eye': !passwordTextType
                                  }"
                                  (click)="togglePasswordTextType()"
                                ></i
                              ></span>
                            </div>
                          </div>
                          <div
                            *ngIf="r_submitted && rf.password.errors"
                            class="invalid-feedback"
                            [ngClass]="{
                              'd-block': r_submitted && rf.password.errors
                            }"
                          >
                            <div *ngIf="rf.password.errors.required">
                              Password is required
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-group"
                          *ngIf="rf.role.value && !roleIsSchool"
                        >
                          <label
                            for="register-confirmPassword"
                            class="form-label"
                            >Re-enter Password</label
                          >
                          <div
                            class="input-group input-group-merge form-password-toggle"
                          >
                            <input
                              [type]="passwordTextType2 ? 'text' : 'password'"
                              formControlName="confirmPassword"
                              class="form-control form-control-merge"
                              placeholder="············"
                              aria-describedby="register-confirmPassword"
                              tabindex="3"
                              [ngClass]="{
                                'is-invalid error':
                                  r_submitted && rf.confirmPassword.errors
                              }"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text cursor-pointer">
                                <i
                                  class="feather font-small-4"
                                  [ngClass]="{
                                    'icon-eye-off': passwordTextType2,
                                    'icon-eye': !passwordTextType2
                                  }"
                                  (click)="togglePasswordTextType2()"
                                ></i>
                              </span>
                            </div>
                          </div>
                          <div
                            *ngIf="
                              r_submitted &&
                              (rf.confirmPassword.errors || registerForm.errors)
                            "
                            class="invalid-feedback"
                            [ngClass]="{
                              'd-block':
                                r_submitted &&
                                (rf.confirmPassword.errors ||
                                  registerForm.errors)
                            }"
                          >
                            <div *ngIf="rf.confirmPassword.errors?.required">
                              Password required
                            </div>
                            <div *ngIf="registerForm.errors?.noMatch">
                              Password does not match
                            </div>
                          </div>
                          <!--div
            *ngIf="r_submitted && rf.password.value !== rf.confirmPassword.value "
            class="invalid-feedback"
            [ngClass]="{ 'd-block': r_submitted && rf.password.value !== rf.confirmPassword.value }"
        >
            <div>Password does not match</div>
        </div-->
                        </div>

                        <div class="form-group" *ngIf="roleIsSchool">
                          <label for="register-county" class="form-label"
                            >County</label
                          >
                          <select
                            formControlName="county"
                            class="form-control"
                            [class.error]="r_submitted && rf.county.invalid"
                            aria-describedby="register-county"
                            [ngClass]="{
                              'd-block': r_submitted && rf.county.errors
                            }"
                          >
                            <option value="" disabled selected>
                              Select a County
                            </option>
                            <option
                              *ngFor="let county of counties"
                              [ngValue]="county.countyId"
                            >
                              {{ county.name }}
                            </option>
                          </select>
                          <div
                            *ngIf="r_submitted && rf.county.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="rf.county.errors.required">
                              County is required
                            </div>
                            <div *ngIf="rf.county.errors.min">
                              County is required
                            </div>
                          </div>
                        </div>

                        <div class="form-group" *ngIf="roleIsSchool">
                          <label for="register-designation" class="form-label"
                            >Designation</label
                          >
                          <select
                            formControlName="designation"
                            class="form-control"
                            aria-describedby="register-designation"
                          >
                            <option value="" disabled selected>
                              Select a Designation
                            </option>
                            <option
                              *ngFor="let designation of designations"
                              [value]="designation.titleId"
                            >
                              {{ designation.name }}
                            </option>
                          </select>
                        </div>

                        <div class="form-group" *ngIf="roleIsSchool">
                          <label for="register-school-name" class="form-label"
                            >School Name</label
                          >
                          <input
                            type="text"
                            formControlName="schoolName"
                            class="form-control"
                            placeholder="Zeraki High School"
                            aria-describedby="register-school-name"
                            tabindex="1"
                            autofocus
                            [ngClass]="{
                              'is-invalid': r_submitted && rf.schoolName.errors
                            }"
                          />
                          <div
                            *ngIf="r_submitted && rf.schoolName.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="rf.schoolName.errors.required">
                              School name is required
                            </div>
                          </div>
                        </div>

                        <app-terms-and-conditions
                          (childMethodEvt)="getAcceptedTermsStatus($event)"
                        ></app-terms-and-conditions>

                        <button
                          [disabled]="loading || !agreedToTerms"
                          class="btn btn-primary btn-block"
                          tabindex="5"
                          rippleEffect
                        >
                          <span
                            *ngIf="loading"
                            class="spinner-border spinner-border-sm mr-1"
                          ></span
                          >Sign up
                        </button>
                      </form>
                      <!-- End of Register Form-->
                    </section>

                    <!-- Form toggling buttons -->
                    <p
                      class="text-center mt-2"
                      *ngIf="forgot || showForgotUsername"
                    >
                      <a
                        (click)="switchForm('login')"
                        href="javascript:void(0)"
                      >
                        <i data-feather="chevron-left" class="mb-25"></i> Back
                        to login
                      </a>
                    </p>
                    <p class="text-center mt-2" *ngIf="register">
                      <span>Already have an account?</span>
                      <a (click)="switchForm('login')" href="javascript:void(0)"
                        ><span>&nbsp;Sign in instead</span></a
                      >
                    </p>
                    <p class="text-center mt-2" *ngIf="login">
                      <span>New on our platform?</span
                      ><a
                        (click)="switchForm('register')"
                        href="javascript:void(0)"
                        ><span>&nbsp;Join for Free</span></a
                      >
                    </p>
                    <!-- End of toggle form buttons -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-60" #features>
    <div class="container-xxl text-center">
      <h3 class="text-center pt-3 pb-1 headers-color">Why You Should Choose</h3>
      <div class="text-center">
        <img src="assets/img/learning.png" height="50px" />
      </div>
    </div>
    <div class="features-boxed">
      <div class="container-fluid">
        <div class="row justify-content-center features">
          <div class="col-sm-6 col-md-4 px-50 py-4">
            <div class="text-center">
              <img class="feature-icons" src="assets/img/1.png" />
              <p class="font-medium-2 fs-13">
                Multimedia lessons from expert teachers covering the entire high
                school syllabus
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 px-50 py-4">
            <div class="text-center">
              <img class="feature-icons" src="assets/img/m3.png" />
              <p class="font-medium-2 name">
                Students can take tests at the end of every topic or at the end
                of every form to assess and cement their learning
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 px-50 py-4">
            <div class="text-center">
              <img class="feature-icons" src="assets/img/m2.png" />
              <p class="font-medium-2 name">
                See how well you perform in each subject, form and topic and
                monitor your study patterns on Zeraki
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 px-50 py-4">
            <div class="text-center">
              <img class="feature-icons" src="assets/img/m4.png" />
              <p class="font-medium-2 name">
                The app is the results of years of expertise in education and
                technology and a deep understanding of what students need
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 px-50 py-4">
            <div class="text-center">
              <img class="feature-icons" src="assets/img/m5.png" />
              <p class="font-medium-2 fs-13">
                Innovative design makes learning simple, engaging, elegant,
                modern and fun across all devices.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 px-50 py-4">
            <div class="text-center">
              <img class="feature-icons" src="assets/img/m6.png" />
              <p class="font-medium-2 name">
                Access all features of Zeraki on the app offline, including
                tests, analytics and downloaded lessons. Sync the app to access
                any new content available online
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div #testimonial class="py-60 testimonials-clean">
    <div class="container-xxl px-3">
      <div class="intro">
        <h3 class="text-center headers-color fs-30">Testimonials</h3>
        <div class="row justify-content-center py-30">
          <div class="col-md-5">
            <p class="font-medium-2" class="text-center fw-light">
              Our customers love us! Read what they have to say below.
            </p>
          </div>
        </div>
      </div>
      <div class="row people match-height">
        <div class="col-md-6 col-lg-4 item">
          <div class="bg-white p-4 rounded">
            <p class="description">
              I have been struggling to get a tutor for Chemistry and Biology
              for my daughter. She is using Zeraki Learning now and she says all
              the points she missed in class are being handled by the app. This
              is wonderful.
            </p>
          </div>
          <div class="d-flex py-4">
            <div class="mr-1">
              <img
                class="rounded-circle"
                src="assets/images/testimonials/edna.jpg"
                width="90px"
              />
            </div>
            <div class="flex-grow-1">
              <h3 class="mb-0 text-secondary">Edna Ndeva</h3>
              <p class="text-success mb-0">Parent</p>
              <p class="text-success">
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 item">
          <div class="bg-white p-4 rounded">
            <p class="description">
              When the government announced closure of schools, we had not
              prepared any work for our students to do at home. Currently, my
              students can access assignments and video lessons from the comfort
              of their homes on phone through Zeraki Learning.
            </p>
          </div>
          <div class="d-flex py-4">
            <div class="mr-1">
              <img
                class="rounded-circle"
                src="assets/images/testimonials/zacch.png"
                width="90px"
              />
            </div>
            <div class="flex-grow-1">
              <h3 class="mb-0 text-secondary">Zacch M’copondo</h3>
              <p class="text-success mb-0">Teacher</p>
              <p class="text-success">
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 item">
          <div class="bg-white p-4 rounded">
            <p class="description">
              Because of the learning crisis created by the COVID-19 pandemic,
              many parents have been worried about how to keep their children
              engaged. Zeraki Learning has provided a platform for my students
              to access interactive Chemistry and Biology lessons. With Zeraki
              Learning, digital learning is very effective!
            </p>
          </div>
          <div class="d-flex py-4">
            <div class="mr-1">
              <img
                class="rounded-circle"
                src="assets/images/testimonials/dickens.jpeg"
                width="90px"
              />
            </div>
            <div class="flex-grow-1">
              <h3 class="mb-0 text-secondary">Dickens Menya</h3>
              <p class="text-success mb-0">Teacher - St. Mary's School, Yala</p>
              <p class="text-success">
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
                <i class="fa fa-star icons"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-xxl text-center py-30">
    <h3 class="text-center py-4 headers-color fs-30">Satisfied Schools</h3>
    <!-- primeng-carousel component -->
    <p-carousel
      [value]="satisfiedSchools"
      [numVisible]="4"
      [numScroll]="1"
      [responsiveOptions]="responsiveOptions"
      [autoplayInterval]="4000"
      [circular]="true"
      [showNavigators]="false"
      [showIndicators]="false"
    >
      <ng-template let-school pTemplate="item">
        <div class="card mr-1">
          <div class="card-body pb-1">
            <img [src]="school" />
          </div>
        </div>
      </ng-template>
    </p-carousel>
    <!-- primeng-carousel component -->
  </div>

  <div
    class="d-flex align-items-center mt-5"
    data-bs-parallax-bg="true"
    #about
    style="
      background-image: url('assets/img/zl-2.jpg');
      min-height: 500px;
      background-position: center;
      background-size: cover;
    "
  >
    <div class="container-fluid">
      <h2 class="text-center text-white pb-30 font-large-3">Who We Are</h2>
      <p class="text-center text-white zerakitxt mt-5 font-medium-5">
        Zeraki is all about using technology to solve some of the toughest
        challenges encountered in providing quality education in Africa. Our
        cutting edge solutions, provide insights that enable informed decision
        making, provide access to quality instruction and take the stress out of
        everyday administrative tasks. We strive to improve the lives of
        educators and students at every stage and in every setting. Our Core
        product, Zeraki analytics, is transforming the way educational data is
        collected, analyzed and used
      </p>
    </div>
  </div>

  <!--div class="bg-secondary-light">
        <div class="container-xxl py-50">
            <h3 class="text-center py-4 fs-30 headers-color">Latest News</h3>
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3">
                        <div class="col mb-4">
                            <div class="d-flex align-items-center">
                                <img class="img-fluid" width="100%" src="assets/img/gpe_newsdec28-2_0.jpg">
                                <button class="btn btn-success btn-sm position-absolute" data-bs-hover-animate="pulse" type="button" style="margin-left:-10px;"><i class="fa fa-plus pr-3"></i>Read
                                    More</button>
                            </div>
                            <div style="height:50px;"><small class="py-3">By Felix Mokua</small>
                                <p class="text-left zeraki-heading4">Tips To Help You Find Great Talent For Your Business
                                    <br>
                                </p>
                            </div>
                        </div>
                        <div class="col mb-4">
                            <div class="d-flex align-items-center"><img class="img-fluid" width="100%" src="assets/img/gpe_newsdec28-2_0.jpg"><button class="btn btn-success btn-sm position-absolute" data-bs-hover-animate="pulse" type="button" style="margin-left:-10px;"><i class="fa fa-plus pr-3"></i>Read
                                    More</button></div>
                            <div style="height:50px;"><small class="py-3">By Felix Mokua</small>
                                <p class="text-left zeraki-heading4">Tips To Help You Find Great Talent For Your Business
                                    <br>
                                </p>
                            </div>
                        </div>
                        <div class="col mb-4">
                            <div class="d-flex align-items-center"><img class="img-fluid" width="100%" src="assets/img/gpe_newsdec28-2_0.jpg"><button class="btn btn-success btn-sm position-absolute" data-bs-hover-animate="pulse" type="button" style="margin-left:-10px;"><i class="fa fa-plus pr-3"></i>Read
                                    More</button></div>
                            <div style="height:50px;"><small class="py-3">By Felix Mokua</small>
                                <p class="text-left zeraki-heading4">Tips To Help You Find Great Talent For Your Business
                                    <br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div-->

  <div class="container-xxl pt-40" #contact>
    <h3 class="text-center py-4 headers-color fs-30">Get In Touch</h3>
    <div class="row text-fade">
      <div class="col-lg-6 py-4">
        <div class="responsive__tabs">
          <ul class="nav nav-tabs nav-fill scrollable-tabs" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                (click)="request_quote = false"
                [class.bg-secondary-light]="!request_quote"
              >
                <i class="fa fa-phone pe-2" aria-hidden="true"></i> Contact Us
              </a>
            </li>
            <!--li class="nav-item" role="presentation">
                            <a class="nav-link" (click)="request_quote = true" [class.bg-secondary-light]="request_quote">
                                <i class="fa fa-file-pdf-o pe-2" aria-hidden="true"></i> Request Quote
                            </a>
                        </li-->
          </ul>
          <div class="tab-content">
            <div class="tab-pane active" role="tabpanel" id="tab-5">
              <form class="py-4">
                <div
                  class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 pt-4"
                >
                  <div class="col-md-6">
                    <div class="form-group pb-3">
                      <label>Name</label
                      ><input
                        class="form-control form-control-lg"
                        type="text"
                        name="name"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Email</label
                      ><input
                        class="form-control form-control-lg"
                        type="text"
                        name="email"
                        required=""
                        inputmode="email"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Subject</label
                      ><input
                        class="form-control form-control-lg"
                        type="text"
                        name="subject"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Mobile</label
                      ><input
                        class="form-control form-control-lg"
                        type="text"
                        name="mobile"
                        required=""
                        inputmode="tel"
                      />
                    </div>
                  </div>
                </div>
                <div *ngIf="request_quote">
                  <div class="form-row mb-3">
                    <div class="col">
                      <label for="usertype">I am a : </label>
                      <select
                        class="form-control form-select"
                        required=""
                        id="usertype"
                      >
                        <option value="" selected="">
                          Please click here to select user type
                        </option>
                        <option value="Parent">Parent</option>
                        <option value="Teacher">Teacher</option>
                        <option value="Admin">Admin</option>
                        <option value="Alumni">Alumni</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row row-cols-1">
                    <div class="col pb-3">
                      <label>Name of School</label
                      ><input
                        class="form-control"
                        type="text"
                        name="school"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="form-row row-cols-1">
                    <div class="col pb-3">
                      <label>Number of Students</label
                      ><input
                        class="form-control"
                        type="text"
                        name="students"
                        required=""
                        inputmode="numeric"
                      />
                    </div>
                  </div>
                </div>
                <div class="row row-cols-1">
                  <div class="col">
                    <label>Message</label
                    ><textarea
                      class="form-control form-control-lg"
                      name="message"
                      required=""
                    ></textarea>
                  </div>
                  <div class="col">
                    <button class="btn btn-success btn-sm mt-4" type="submit">
                      Send Message
                      <i class="fa fa-long-arrow-right pl-3"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 hidden-lg-down">
        <div class="row">
          <div class="col-md-10">
            <img class="img-fluid" src="assets/img/conn.png" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="zerakifooter" class="footer-dark bg-secondary bg-darken-4">
    <footer>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6 col-md-3 item">
            <img
              class="mb-3"
              src="assets/img/Zeraki-horizontal_white.png"
              height="30px"
            />
            <p>
              Litemore Ltd.<br />P.O. Box 51235 - 00100<br />Nairobi, Kenya<br />
              <strong>+254 790 493 495</strong>
            </p>
          </div>
          <div class="col-sm-6 col-md-3 item">
            <h3 class="text-primary">Products</h3>
            <ul>
              <li>
                <a href="https://analytics.zeraki.co.ke">Zeraki Analytics</a>
              </li>
              <li><a href="https://finance.zeraki.co.ke">Zeraki Finance</a></li>
              <li><a href="https://zeraki.co.ke">Zeraki Touch</a></li>
            </ul>
          </div>
          <div class="col-md-6 item text-light">
            <h3 class="text-primary">About Zeraki</h3>
            <p>
              Zeraki is all about using technology to solve some of the toughest
              challenges encountered in providing quality education in Africa.
              <br /><br />
              Our cutting edge solutions, provide insights that enable informed
              decision making, provide access to quality instruction and take
              the stress out of everyday administrative tasks.&nbsp;
            </p>
          </div>
          <div class="col text-center pt-4">
            <a href="#">
              <i class="fa fa-facebook text-white px-2" aria-hidden="true"></i>
            </a>
            <a href="#"
              ><i class="fa fa-twitter text-white px-2" aria-hidden="true"></i
            ></a>
            <a href="#"
              ><i
                class="fa fa-youtube-play text-white px-2"
                aria-hidden="true"
              ></i
            ></a>
            <a href="#"
              ><i
                class="fa fa-instagram text-white px-2"
                aria-hidden="true"
              ></i>
            </a>
          </div>
        </div>
        <div class="text-center text-light small pt-3">
          <p class="copyright">
            © 2020. All rights reserved. Litemore Limited. Designed By
            <a href="https://analytics.zeraki.co.ke/" class="text-light"
              >Litemore Ltd</a
            >.
          </p>
        </div>
      </div>
    </footer>
  </div>
</div>
