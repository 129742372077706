import { County, TeacherRole } from ".";

export interface TemporarySchool {
  schoolId: number;
  name: string;
  county: County;
  teacherRole: TeacherRole;
  userName: string;
  userPhone: string;
}

export interface PendingRequests {
  totalPages?: number;
  totalSchools?: number;
  schoolTempDTOList?: SchoolTempDTOList[];
}

export interface SchoolTempDTOList {
  id?: number;
  name?: string;
  userName?: string;
  phoneNumber?: string;
  level?: number;
  verificationStatus?: number;
  deletionStatus?: number;
  teacherRole?: number;
  county?: number;
}
