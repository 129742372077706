import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

export interface Plan {
    planName: string,
    planId: number,
    amount: number,
    hasDiscount: boolean,
    discountedAmount: number
}

@Component({
    selector: 'app-provide-subscription-plan',
    templateUrl: './provide-subscription-plan.component.html',
    styleUrls: ['./provide-subscription-plan.component.scss']
})
export class ProvideSubscriptionPlanComponent implements OnInit {

    @Input() isLoading: boolean = true;
    @Input() plans: Plan[] = [];
    @Output() planEvt: EventEmitter<any> = new EventEmitter<any>();
    @Output() loadHomepageEvt: EventEmitter<any> = new EventEmitter<any>();
    @Output() phoneNumberEvt: EventEmitter<any> = new EventEmitter<any>();

    planForm: FormGroup;

    submitted = false;

    selectedPlan:any = "";
    alertClose = true;

    constructor(private formBuilder: FormBuilder, private ngbModel: NgbModal) {
    }

    ngOnInit(): void {
        this.initForm();
        this.selectedPlan = 0;
    }

    // initForm(){
    //   this.planForm = this.formBuilder.group({
    //     plan:["",[Validators.required]]
    //   });
    // }
    //
    // get f(){
    //   return this.planForm.controls;
    // }

    submitPlan() {
        this.submitted = true;
        if (this.planForm.invalid) return;

        const plan = this.planForm.value.plan;
        this.planEvt.emit(plan);
    }

    loadHomepage() {
        this.loadHomepageEvt.emit();
    }

    phoneForm: FormGroup;
    enablePhoneEdit = true;


    initForm() {
        this.phoneForm = this.formBuilder.group({
            phone: [
                null,
                [
                    Validators.required,
                    Validators.pattern("[0-9]*$"),
                    Validators.minLength(10),
                    Validators.maxLength(12)
                ]
            ]
        });
    }

    get f() {
        return this.phoneForm.controls;
    }

    setSelectedPlan(index:number){
        this.selectedPlan = index;
        this.alertClose = true;
    }

    submitPhoneNumber() {
        this.submitted = true;
        if((this.selectedPlan+"") == ""){
            this.alertClose = false;
        }

        if(this.phoneForm.invalid || (this.selectedPlan+"") == "") return;


        this.alertClose = true;
        const phone: any = this.phoneForm.value.phone;

        this.phoneNumberEvt.emit({phone: phone, plan: this.selectedPlan});

    }

    toggleEnablePhoneEdit() {
        this.enablePhoneEdit = !this.enablePhoneEdit;
    }
}
