import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Notifications } from '@core/models/notifications';
import { NotificationService } from '@core/services/notification.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: Notifications;

  constructor(
    private _notificationsService: NotificationService,
    private _router: Router
  ) {}

    
  ngOnInit(): void {
    // this.fetchNotifications();
  }

  deleteNotification(typeId: number, contentId: number){
    this._notificationsService.deleteNotification(typeId,contentId)
    .pipe(first())
    .subscribe(
      () => this.fetchNotifications(),
      err => console.log(err.error)
    )
  }

  clearNotifications(){
    this._notificationsService.clearNotifications()
    .pipe(first())
    .subscribe(
      () => this.fetchNotifications(),
      err => console.log(err.error)
    ) 
  }

  fetchNotifications(){
    this._notificationsService.getNotifications()
    .pipe(first())
    .subscribe(res => {
      this.notifications = res;
    });
  }

  processAssignmentNotification(message){

    this._router.navigate(message.type == 3 ?  
    [ '/assignments/perform', message.assignmentId ] :
    [ '/assignments/view', message.assignmentId ]);

    this.deleteNotification(10,message.assignmentId);
  }

}
