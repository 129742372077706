import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-payment-success-mobile',
  templateUrl: './payment-success-mobile.component.html',
  styleUrls: ['./payment-success-mobile.component.scss']
})
export class PaymentSuccessMobileComponent implements OnInit {

  @Input() coreConfig:any;
  @Input() planName:any;
  @Input() expiryDate:any;
  constructor() { }

  ngOnInit(): void {
  }

  getPlanName(){
    return this.planName;
  }

  getExpiryDate(){
    return this.expiryDate;
  }

}
