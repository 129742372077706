import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';

import {NgSelectModule} from '@ng-select/ng-select';
import { LoaderComponent } from './components/loader/loader.component';
import { QuizPerformanceComponent } from './components/quiz-performance/quiz-performance.component';
import { CoreCommonModule } from '@core/common.module';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { StudentTopicAccessComponent } from './components/student-topic-access/student-topic-access.component';
import { QuestionSelectionFormComponent } from './components/question-selection-form/question-selection-form.component';

@NgModule({
  declarations: [
    LoaderComponent,
    QuizPerformanceComponent,
    StudentTopicAccessComponent,
    QuestionSelectionFormComponent
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    NgxDatatableModule,
    NgSelectModule,
    FormsModule,

  ],
  exports: [
    LoaderComponent,
    QuizPerformanceComponent,
    StudentTopicAccessComponent,
    QuestionSelectionFormComponent
  ]
})
export class SharedModule { }
