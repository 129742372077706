import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { HelpService } from '@core/services/help.service';

@Component({
  selector: 'app-ad-redirecting-page',
  templateUrl: './ad-redirecting-page.component.html',
  styleUrls: ['./ad-redirecting-page.component.scss']
})
export class AdRedirectingPageComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private helpService: HelpService,
    private _coreConfigService: CoreConfigService,
  ) { }

  ngOnInit(): void {
    this.configureLayout();
    this.redirectUserToLogin();
  }

  redirectUserToLogin(): void {
    const id = this.route.snapshot.queryParams.id;
    console.log(id)
    if (id) {
      this.helpService.redirectUserToMainPage(id).subscribe(
        (res) => {
          setTimeout( () => {
            this.router.navigate(['/login']);
            console.log(res)
          }, 2000)
        },
        (err) => {
          console.error(err)
        }
      )
    }
  }

  configureLayout() {
    this._coreConfigService.config = {
        layout: {
            navbar: {
                hidden: true
            },
            footer: {
                hidden: true
            },
            menu: {
                hidden: true
            },
            customizer: false,
            enableLocalStorage: false
        }
    };
}

}
