import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import * as Excel from "exceljs";
import * as fs from "file-saver";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getUsersPaymentAttempt(page: number, pageSize: number): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/subscription/attempts?page=${page}&pageSize=${pageSize}`
    );
  }

  getContactedAttemptedSubscribers(
    page: number,
    pageSize: number
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/subscription/attempts/done?page=${page}&pageSize=${pageSize}`
    );
  }

  updatePaymentAttempts(userId: number): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/subscription/attempt?userId=${userId}`,
      null
    );
  }

  getSubscribedUserCountBreakdown() {
    return this.http.get<any>(`${this.apiUrl}/subscription/count/plan`);
  }

  getUserSubDates() {
    return this.http.get<any>(`${this.apiUrl}/subscription`);
  }

  //subscription/schools?page&pageSize
  getSchoolSubStats(page: number, pageSize: number): Observable<any> {
    // return this.getSchollData();
    return this.http.get<any>(
      `${this.apiUrl}/subscription/schools?page=${page}&pageSize=${pageSize}`
    );
  }

  downloadSchoolSubStats(): Observable<any> {
    // return this.getSchollData();
    return this.http.get<any>(
      `${this.apiUrl}/subscription/schools?page=${1}&pageSize=${50000}`
    );
  }

  getSubscriberByCode(query: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/subscription/support?transactionCode=${query}`
    );
  }

  swapSubscribers(swapDetails: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/subscription/support`,
      swapDetails
    );
  }

  getSchoolDataForExcel(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/subscription/schools`);
  }

  exportAsExcel(schoolData: any[]) {
    let date = this.getDateString();

    const fileName = "Subscribed-users(" + date + ")";
    const workSheetName = "Subscription Breakdown";

    // create the excel document.
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet(workSheetName);

    let schoolNameWidth = 12;

    //create Data Array
    const data: any[] = [];

    schoolData.forEach((school: any) => {
      schoolNameWidth =
        school.school.toString().length > schoolNameWidth
          ? school.school.toString().length + 1
          : schoolNameWidth;
      data.push([
        school.school,
        school.monthlySubscribers,
        school.weeklySubscribers,
        school.dailySubscribers,
        school.expiredSubscriptions,
      ]);
    });
    //create header array
    const header = [
      { header: "School", key: "school", width: schoolNameWidth },
      { header: "Monthly Subscribers", key: "month", width: 15 },
      { header: "Weekly Subscribers", key: "week", width: 15 },
      { header: "Daily Subscribers", key: "day", width: 15 },
      { header: "Expired Subscriptions", key: "expired", width: 15 },
    ];

    sheet.addRows([]);
    sheet.mergeCells("A1", "A2");
    sheet.mergeCells("B1", "E1");

    //set header columns
    sheet.columns = header;

    sheet.getCell("B1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    sheet.getCell("B1").value = "Zeraki Learning Subscribers";
    sheet.getCell("B2").value = "Monthly";
    sheet.getCell("C2").value = "Weekly";
    sheet.getCell("D2").value = "Daily";
    sheet.getCell("E2").value = "Expired";

    //formart first row
    sheet.getRow(1).font = {
      name: "Calibri",
      color: { argb: "000000" },
      bold: true,
    };
    sheet.getRow(2).font = {
      name: "Calibri",
      color: { argb: "000000" },
      bold: true,
    };

    //add Data
    sheet.addRows(data);

    //save the excel
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      fs.saveAs(blob, `${fileName}.xlsx`);
    });
    return header;
  }

  getDateString() {
    let date: string = new Date().toDateString();
    let dateArray = date.split(" "); //.splice(1, 1);
    dateArray.splice(0, 1);
    const d = dateArray[1];
    dateArray[1] = dateArray[0];
    dateArray[0] = d;
    return dateArray.join("-");
  }
}
