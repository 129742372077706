import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HowToVideo } from '@core/models/how-to-video';
import { QuickTourVideo } from '@core/models/quick-tour-video';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  constructor(private http: HttpClient) { }

  getHowToVideos(): Observable<HowToVideo[]> {
    return this.http.get<HowToVideo[]>(environment.apiUrl + "/tutorial")
  }

  checkQuickTourVideoStatus(): Observable<QuickTourVideo> {
    return this.http.get<QuickTourVideo>(environment.apiUrl + "/tutorial/tour/web")
  }

  logQuickTourVideoStatus(): Observable<any> {
    return this.http.put<any>(environment.apiUrl + "/tutorial/tour", {})
  }

  redirectUserToMainPage(id: number): Observable<any> {
    return this.http.put<any>(environment.apiUrl + `/messages?id=${id}`, {})
  }
}
