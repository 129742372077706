<div class="text-center">
    <img class="img-fluid" style="padding: 5em; height: 21rem;"
         [src]="coreConfig.layout.skin === 'dark' ? 'assets/images/payments/iphone_spinner.svg' : 'assets/images/payments/iphone_spinner.svg' "
         alt="Error page" />

    <p class="mb-2" style="
                    font-style: normal;
                    font-size: 20px;
                    line-height: 26px;

                    color: #555555;
                    ">Unable to confirm your payment, if you have paid and received
        a confirmation message kindly contact customer service on 0709953000
        to activate your subscription.
    </p>

    <div class="form-group mb-0" style="display: flex; justify-content: space-between">
<!--    <span></span>-->
        <button (click)="loadHomepage()" class="btn btn-outline-danger">Cancel
<!--            &lt;!&ndash; <i data-feather="arrow-right" class="cursor-pointer"></i> &ndash;&gt;-->

        </button>

        <button class="btn btn-primary waves-effect waves-light" (click)="addSubscription()">Retry </button>
    </div>
</div>