import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { AuthenticationService, UserService } from '@core/services/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HelperFunctionsService } from '@core/services/helper-functions.service';
import { User } from '@core/models';
import { Router } from '@angular/router';


@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountSettingsComponent implements OnInit {
  //Public
  public contentHeader: object;
  public currentUser : User;
  public currentUserInfo: any;
  public isStudent = false;
  public isIndependentStudent = false;
  public passwordTextTypeOld = false;
  public passwordTextTypeNew = false;
  public passwordTextTypeRetype = false;
  public avatarImage: string;
  public birthDateOptions: FlatpickrOptions = {
    altInput: true,
    dateFormat: 'd/m/Y'
  };
  public data = {
    accountSetting: {
      general: {
        avatar: 'assets/images/portrait/small/avatar.png',
        username: 'johndoe@zerakilearningschool',
        fullName: 'John Doe',
        email: 'granger007@hogward.com',
        school: 'Zeraki Learning School'
      },
      info: {
        bio: '',
        dob: null,
        country: 'Kenya',
        website: '',
        phone: '(+254)--- --- ---'
      },
      social: {
        socialLinks: {
          twitter: 'https://www.twitter.com',
          facebook: '',
          google: '',
          linkedIn: 'https://www.linkedin.com',
          instagram: '',
          quora: ''
        },
        connections: {
          twitter: {
            profileImg: 'assets/images/avatars/11-small.png',
            id: '@johndoe'
          },
          google: {
            profileImg: 'assets/images/avatars/3-small.png',
            id: '@luraweber'
          },
          facebook: {},
          github: {}
        }
      },
      notification: {
        commentOnArticle: true,
        AnswerOnForm: true,
        followMe: false,
        newAnnouncements: true,
        productUpdates: true,
        blogDigest: false
      }
    }
  };
  public editMode = false;

  public userInfoForm : FormGroup;

  public changePasswordForm : FormGroup = this._fb.group({
    currentPassword: ['',Validators.required],
    password: ['',Validators.required],
    confirmPassword: ['',Validators.required]
  });

  public submitted = false;
  public p_submitted = false;
  public passwordCheckFailed = false;
  

  // private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {AuthenticationService} _authenticationService
  */
  constructor(
    private _authenticationService: AuthenticationService,
    private _usersService: UserService,
    private _fb : FormBuilder,
    private _helperFunctions : HelperFunctionsService,
    private _router : Router
  ) {
    this.isStudent = this._authenticationService.isStudent;
    this.isIndependentStudent = this._authenticationService.isIndependentStudent;

    this.userInfoForm = this._fb.group({
      school: [{value : '',disabled : true}, Validators.required],
      streamName: [{value : '',disabled : true}, Validators.required],
      form: [{value : '',disabled : !this.isIndependentStudent }],
      username: [{value : '',disabled : true}, Validators.required],
      name: [{value : '',disabled : !this.isIndependentStudent}, Validators.required],
      email: [{value : '',disabled : false}, Validators.required],
      phoneNumber: [{value : '',disabled : this.isIndependentStudent}],
      dateOfBirth: [{value : '',disabled : false}]
    });

    this._authenticationService.getUserInfo().subscribe(
      x => {
        this.currentUserInfo = x;
        this.populateForm();
      }
    );

  }


  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  get f(){
    return this.userInfoForm.controls;
  }

  get pf(){
    return this.changePasswordForm.controls;
  }

  /**
   * Process submitted form data and update user details
  */
  onSubmit() {
    this.submitted = true;

    if(!this.userInfoForm.valid){
      return;
    }

    console.log('birthday ',this.f.dateOfBirth.value);
    let formattedBirthday = null;

    if(this.f.dateOfBirth.value.length > 0){
      const birthday = new Date(this.f.dateOfBirth.value);
      formattedBirthday = this.f.dateOfBirth.dirty ? `${birthday.getDate()}/${birthday.getMonth() + 1}/${birthday.getFullYear()}` : this.f.dateOfBirth.value;
    }

    this._usersService.updateUser(this.f.email.value, this.f.name.value, this.f.phoneNumber.value, formattedBirthday, this.f.form.value)
    .pipe(first())
    .subscribe(
      res => {
        this.toggleMode();
        this._helperFunctions.showSucessToastr(res.message);
        this._router.navigate(['/pages/profile'])
      },
      err => { 
        this._helperFunctions.showWarningToastr(err.error.message)
      }
    )

  }

  /**
   * Process submitted passwords and update
  */
  onSubmitPassword() {
    this.p_submitted = true;

    if(!this.changePasswordForm.valid){
      return;
    }

    this._authenticationService.checkPassword(this.pf.currentPassword.value)
    .pipe(first())
    .subscribe(
      res=>{this.passwordCheckFailed = false;this.changePassword();},
      err=>this.passwordCheckFailed = true
    )
  }

  changePassword(){
    this._authenticationService.changePassword(this.pf.password.value)
    .pipe(first())
    .subscribe(
      res => { 
        this._helperFunctions.showSucessToastr(res.message);
      },
      err => { 
        this._helperFunctions.showWarningToastr(err.error.message)
      }
    )
  }

  populateForm(){
    this.userInfoForm.patchValue({
      ...this.currentUserInfo
    });
    
    this.birthDateOptions.defaultDate = this.f.dateOfBirth.value;
   
  }
  
  toggleMode(){
    Object.keys(this.userInfoForm.controls).forEach(key => {

      if(
        key == 'school' || key == 'streamName' || key == 'username' ||
        (key == 'name' && !this.isIndependentStudent) ||
        (key == 'form' && !this.isIndependentStudent) ||
        (key == 'username' && this.isIndependentStudent) ||
        (key == 'phoneNumber' && this.isIndependentStudent)  
      ){
        return;
      }

    });
  }
  
  /**
   * Toggle Password Text Type Old
   */
  togglePasswordTextTypeOld() {
    this.passwordTextTypeOld = !this.passwordTextTypeOld;
  }

  /**
   * Toggle Password Text Type New
   */
  togglePasswordTextTypeNew() {
    this.passwordTextTypeNew = !this.passwordTextTypeNew;
  }

  /**
   * Toggle Password Text Type Retype
   */
  togglePasswordTextTypeRetype() {
    this.passwordTextTypeRetype = !this.passwordTextTypeRetype;
  }

  /**
   * Upload Image
   *
   * @param event
   */
  uploadImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.avatarImage = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // content header
    this.contentHeader = {
      headerTitle: 'Account Settings',
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Pages',
            isLink: true,
            link: '/'
          },
          {
            name: 'Account Settings',
            isLink: false
          }
        ]
      }
    };
  }

}
