import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User } from '@core/models';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   * @param {AuthenticationService} _authenticationService
  */
  constructor(
    private _http: HttpClient,
    private _router: Router
  ) {}

  /**
   * Get all users
  */
  getAll() {
    return this._http.get<User[]>(`${environment.apiUrl}/users`);
  }

  /**
   * Get user by id
  */
  getById(id): Observable<any>{
    return this._http.get<any>(`${environment.apiUrl}/user?userId=${id}`)
      .pipe(
        tap( (user) => (user))
      );
  }

  getSubscribedUserCount():Observable<any>{
    return this._http.get<any>(`${environment.apiUrl}/subscription/count`);
  }
  getActiveZerakiUserCount():Observable<any>{
    return this._http.get<any>(`${environment.apiUrl}/user/active-users`);
  }

  addUser(user: any): Observable<any>{
    return this._http.post<any>(`${environment.apiUrl}/user`,  user);
  }

  getTeacherDesignations(): Observable<any>{
    return this._http.get<any>(`${environment.apiUrl}/user/teacher/roles`);
  }

  deleteUser(userId: number){
    return this._http.delete<User>(`${environment.apiUrl}/user?userId=${userId}`);
  }

  updateUser(email: string, name: string, phone: number, dateOfBirth: string, formNumber: number){
    return this._http.put<any>(`${environment.apiUrl}/user/update?email=${email}&name=${name}&phone=${phone}&dob=${dateOfBirth}&form=${formNumber}`,{})
  }

  updateTeacher(userId: number, userName: string, userRole: number){
    return this._http.put<any>(`${environment.apiUrl}/user/teacher?userId=${userId}&name=${userName}&role=${userRole}`,{})
  }

  updateStudent(userId: number, userName: string, admNo: number, form: number, stream: number){
    return this._http.put<any>(`${environment.apiUrl}/user/student?userId=${userId}&name=${userName}&admNo=${admNo}&form=${form}&stream=${stream}`,{})
  }

  addTeachers(users: any){
    return this._http.post<any>(`${environment.apiUrl}/user/teachers`,{users}); 
  }
  
  addStudents(students: any,form: any){
    return this._http.post<any>(`${environment.apiUrl}/user/students`,{students,form}); 
  }

  getStudentStatisticsByIntakeAndSubject(userId: number, subjectId: number, form: number){
    return this._http.get<any>(`${environment.apiUrl}/access/statistics?userId=${userId}&subjectId=${subjectId}&form=${form}`);  
  }

  getStudentHistory(subjectId: number, form: number){
    return this._http.get<any>(`${environment.apiUrl}/user/student/history?&subjectId=${subjectId}&form=${form}`);  
  }

  getUserTotals(){
    return this._http.get<any>(`${environment.apiUrl}/user/total-users`);
  }

  setStudentForm(formNumber: number){
    return this._http.post<any>(`${environment.apiUrl}/user/form?form=${formNumber}`,{});  
  }

}
