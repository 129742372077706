<!-- Footer -->
<div class="flex">
  <div>
    <p class="mb-0">
        COPYRIGHT &copy; {{ year }}
        <a class="ml-25" href="https://zeraki.co.ke" target="_blank">Zeraki</a>
        <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </p>
  </div>
<!--  <div>-->
<!--    <app-terms-and-conditions [showCheckbox]="false"></app-terms-and-conditions>-->
<!--  </div>-->
  <div class="d-none d-md-block">
    <p class="mb-0">Lessons by Kenya's Best Teachers</p>
  </div>
</div>
<!--/ Footer --> 

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<div class="buy-now">
  <a href="https://1.envato.market/vuexy_admin" target="_blank" class="btn btn-danger" *ngIf="coreConfig.layout.buyNow"
    >Buy Now</a
  >
</div>
