import { Role } from './role';

// export class User {
//   userRole: Role;
//   name: string;
//   username: string;
//   userid: number;
//   access_token: string;
//   refresh_token: string;
//   contentForm?: 4
//   isTeacher?: true
//   phoneNumber?: null
//   school?: string;
//   schoolId?: number;
//   streamId?: number;
//   streamName?: string;
//   password?: string;
//   form?: string;
//   confirmPassword?: string;
//   isStudent: boolean;
//   subscribed: boolean;
// }
export interface User {
  userId?:         number;
  username?:       string;
  schoolId?:       number;
  currentSchool?:  CurrentSchool;
  name?:           string;
  status?:         number;
  studentProfile?: StudentProfile;
  selfRegistered?: boolean;
  schoolRole?:     number;
  form?:           number;
  subscription?:   Subscription;
  userRole?:       number;
  role?:           number;
  streamId?:       number;
  access_token?:   string;
  refresh_token?:  string;
}

export interface CurrentSchool {
  id?:             number;
  name?:           string;
  registration?:   string;
  schoolCategory?: string;
  schoolStatus?:   string;
  boardingStatus?: string;
  setupStage?:     number;
}

export interface StudentProfile {
  admNo?:           string;
  currentStreamId?: number;
  deleted?:         boolean;
  streamName?:      string;
}

export interface Subscription {
  expiresAt?:    number;
  subscribedAt?: number;
  subscribed?:   boolean;
  plan?:         string;
}



/* // prettier-ignore
export interface User {
  name         : string;
  phone        : string;
  role         : string;
  password     : string
} */
