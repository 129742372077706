<div class="w-100">


    <div class="text-center">
        <h2 style="line-height: 22px;color: #555555;">Payment Confirmed</h2>

        <img class="img-fluid" style="padding: 5em; height: 26rem;"
             [src]="coreConfig.layout.skin === 'dark' ? 'assets/images/payments/check.png' : 'assets/images/payments/check.png' "
             alt="Error page"/>

        <p class="mb-2" style="
                    font-style: normal;
                    font-size: 20px;
                    line-height: 26px;
                    
                    color: #555555;
                    "> {{ 'Your subscription for ' + getPlanName() + ' is valid until ' + getExpiryDate() }} .</p>
    </div>

</div>