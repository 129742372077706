import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';

import {CoreCommonModule} from '@core/common.module';
import {ContentHeaderModule} from 'app/layout/components/content-header/content-header.module';

import {MiscellaneousModule} from './miscellaneous/miscellaneous.module';
import {AccountSettingsModule} from '../account-settings/account-settings.module';
import {ProfileModule} from './profile/profile.module';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {RouterModule, Routes} from '@angular/router';
import {CountyService} from '@core/services/county.service';
import {CarouselModule} from 'primeng/carousel';
import {PaymentsModule} from './payment-page/payments.module';
import {MatStepperModule} from "@angular/material/stepper";
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ForgotUsernameComponent } from './landing-page/components/forgot-username/forgot-username.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { AdRedirectingPageComponent } from './ad-redirecting-page/ad-redirecting-page.component';
import { SubjectEditPageComponent } from './subject-edit-page/subject-edit-page.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    declarations: [
        LandingPageComponent,
        TermsAndConditionsComponent,
        ForgotUsernameComponent,
        OnboardingComponent,
        AdRedirectingPageComponent,
        SubjectEditPageComponent,
    ],
    imports: [
        CoreCommonModule,
        ContentHeaderModule,
        NgbModule,
        NgSelectModule,
        FormsModule,
        MiscellaneousModule,
        AccountSettingsModule,
        CarouselModule,
        PaymentsModule,
        MatStepperModule,
        SharedModule
    ],
    providers: [
        CountyService
    ],
    exports: [
        TermsAndConditionsComponent,
    ]
})
export class PagesModule {
}
