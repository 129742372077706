export interface Subject{
  name: string;
  icon?: string;
  subjectId: number;
  selected?: boolean;
  subjectStyle?: {
    background: string;
  }
}

export interface SubjectWithContent {
  subjectId?:         number;
  name?:              string;
  hasQuestions?:      boolean;
  hasKCSEPractical?:  boolean;
  lessonsTakenCount?: number;
  allLessonsCount?:   number;
}