import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "dateTransform",
})
export class DateTransformPipe extends DatePipe implements PipeTransform {
  transform(value: any): any {
    return super.transform(value, "shortDate");
  }
}
