import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SubjectService } from "@core/services/subject.service";
import { Topic } from "@core/models/topic";
import * as models from "@core/models";
import { LearnService } from "app/main/learn/learn-module.service";
import { SubscriptionService } from "@core/services/subscription.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-student-topic-access",
  templateUrl: "./student-topic-access.component.html",
  styleUrls: ["./student-topic-access.component.scss"],
})
export class StudentTopicAccessComponent implements OnInit {
  studentProfile = new FormGroup({
    topicId: new FormControl(null, Validators.required),
    subjectId: new FormControl(null, Validators.required),
    form: new FormControl(null, Validators.required),
  });

  private unsubscribe$ = new Subject();
  topics!: Topic[];
  subjects!: models.Subject[];
  subjectId!: number;
  form!: number;
  forms = [1, 2, 3, 4];
  initialSubjectId = null;
  initialForm = null;
  loadingTopics = false;
  loadingSubjects = false;
  autoWidth = 200;

  constructor(
    private subjectService: SubjectService,
    private learnService: LearnService,
    private subscriptionService: SubscriptionService
  ) {}

  getTopics(): void {
    const subjectId = this.studentProfile.get("subjectId")?.value;
    const form = this.studentProfile.get("form")?.value;

    if (subjectId && form) {
      this.loadingTopics = true;
      this.learnService
        .getAllTopics(subjectId, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (res) => {
            this.loadingTopics = false;

            this.topics = res;

            if (
              subjectId !== this.initialSubjectId ||
              form !== this.initialForm
            ) {
              this.setFirstTopic();
              this.setStudentContentSelection();
            }

            this.learnService.setNavTopicId(this.TopicId);
          },
          error: (err) => {
            this.loadingTopics = false;
            console.log(err);
          },
        });
    }
  }

  get TopicId() {
    return this.studentProfile.get("topicId").value;
  }

  setFirstTopic(): void {
    if (this.topics && this.topics.length) {
      const firstTopicId = this.topics[0].topicId;
      this.studentProfile.get("topicId")?.setValue(firstTopicId);
    }
  }

  changeTopic(): void {
    this.setStudentContentSelection();
    this.learnService.setNavTopicId(this.TopicId);
  }

  setStudentContentSelection(): void {
    if (this.studentProfile.value && this.studentProfile.valid) {
      const params = { ...this.studentProfile.value };
      console.log(
        "I have noticed the topic change and I am updating student content selection"
      );
      this.subjectService
        .createStudentDashboardContent(params)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            // console.log(res);
          },
          (err) => {
            console.log("err:", err);
          }
        );
    }
  }

  ngOnInit(): void {
    this.getAllSubjects();
    this.getUserSubscriptionDates();
    this.getDashboardTopicIdChange();
  }

  getUserSubscriptionDates() {
    this.subscriptionService
      .getUserSubDates()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          console.log(res);
          this.studentProfile.patchValue({
            ...res.contentSelection,
          });

          this.initialSubjectId = res.contentSelection.subjectId;
          this.initialForm = res.contentSelection.form;

          this.getTopics();
        },
        (err: any) => {
          console.error(err.error.message);
        }
      );
  }

  getAllSubjects(): void {
    this.loadingSubjects = true;
    this.subjectService
      .getSubjectsWithContent()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.loadingSubjects = false;
          // console.log(res);
          this.subjects = res;
        },
        (err) => {
          this.loadingSubjects = false;
          console.log(err);
        }
      );
  }

  getDashboardTopicIdChange(): void {
    this.learnService.topicId$.subscribe((topicId: number) => {
      if (!topicId) return;
      console.warn(
        "I was listening for topic id change which has changed to ",
        topicId
      );
      this.studentProfile.get("topicId").setValue(topicId);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }
}
