import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "@core/models";
import { AuthenticationService, UserService } from "@core/services/auth";
import { CoreConfigService } from "@core/services/config.service";
import { CountyService } from "@core/services/county.service";
import { HelperFunctionsService } from "@core/services/helper-functions.service";
import { SchoolService } from "@core/services/school.service";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import { CookieService } from "ngx-cookie";
import { Buffer } from "buffer";
import { SubscriptionService } from "@core/services/subscription.service";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  public returnUrl: string;
  // public counties: Array<any>;
  counties: any[] = [];
  public designations: Array<any>;

  public userChecked = false;
  public feedback: any;
  public success = false;
  public error: any = {};
  public showForms = false;

  public loginForm: FormGroup;
  public submitted = false;
  public loading = false;
  public passwordTextType: boolean;

  public regPasswordTextType: boolean;
  public regPasswordTextType2: boolean;
  // public registerForm: FormGroup = this._formBuilder.group({
  //   phone: ['', Validators.required],
  //   name: ['', Validators.required],
  //   role: [0, Validators.required],
  // });
  registerForm: FormGroup;

  public r_submitted = false;

  public login = true;
  public register = false;
  public reset = false;
  public usernameCheck = false;

  public showForgotUsername = false;

  public f_submitted = false;
  public fu_submitted = false;
  public forgot = false;
  public forgotUsername = true;
  forgotUsernameForm: FormGroup;
  public forgotPasswordForm: FormGroup;
  public phoneNumberConfirmation = false;
  public resetCodeGenerated = false;
  public currentUser: User;
  public codeSubmitted = false;
  public phoneSubmitted = false;
  public newPasswords = false;
  public request_quote = false;
  agreedToTerms: boolean;
  userNameText: string = "Username or Phone Number";
  userNamePlaceholder: string = "Username/Phone Number";
  passwordHint: string =
    "Enter your parent’s phone number registered with Zeraki Learning to assist in recovery of your account.";
  forgotPasswordHint = false;
  teacherHint = false;
  hasSelectedSubjects!: boolean;

  public satisfiedSchools = [
    "assets/img/Anjego-Friends-High-School.png",
    "assets/img/Asumbi-Girls-High-School.png",
    "assets/img/Barani-Secondary-School.png",
    "assets/img/Beth-Mugo-High-School.png",
    "assets/img/Bungoma-High-School.png",
    "assets/img/Buru-Buru-Girls-School.png",
    "assets/img/Butula-School.png",
  ];

  public responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  private storedUsername: any;
  private storedPassword: any;
  teacherForm: FormGroup;
  parentForm: FormGroup;
  navJustified: any;

  constructor(
    private _coreConfigService: CoreConfigService,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _schoolService: SchoolService,
    private _countyService: CountyService,
    private toastr: ToastrService,
    private _helperFunctions: HelperFunctionsService,
    private router: Router,
    private _cookieService: CookieService,
    private _activatedRoute: ActivatedRoute,
    private _subscriptionService: SubscriptionService,

  ) {
    this._authenticationService.configureLoginLayout();
    if (this.router.url == "/logout") {
      this.logout();
    }

    this.currentUser = this._authenticationService.currentUserValue;

    if (this.currentUser) {
      this._helperFunctions.navigateToUsersDashboard(this.currentUser);
      return;
    }

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  onboard(): void {
    this._router.navigate(["/onboarding"])
  }

  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl = this._activatedRoute.snapshot.queryParams["returnUrl"];

    this.registerForm = this._formBuilder.group({
      phone: ["", Validators.required],
      name: ["", Validators.required],
      email: [""],
      role: [0, Validators.required],
      county: ["", Validators.required],
      schoolName: ["", Validators.required],
      designation: ["", Validators.required],
    });

    this.loginForm = this._formBuilder.group({
      username: ["", [Validators.required, this.userNameValidator]],
      password: ["", [Validators.required]],
      rememberMe: [false],
    });

    //Check if 'Remember Me' is active
    if (this._cookieService.get("rememberMe") == "true") {
      this.storedUsername =
        Buffer.from(this._cookieService.get("username"), "base64").toString(
          "binary"
        ) || "";
      this.storedPassword =
        Buffer.from(this._cookieService.get("password"), "base64").toString(
          "binary"
        ) || "";

      this.f.rememberMe.setValue(true);
      this.f.username.setValue(this.storedUsername);
      this.f.password.setValue(this.storedPassword);
    }

    this.forgotUsernameForm = this._formBuilder.group({
      phoneNumber: [""],
    });

    this.forgotPasswordForm = this._formBuilder.group({
      username: ["", [Validators.required]],
      phoneNumber: [""],
      resetCode: [""],
      password: [""],
      confirmPassword: [""],
    });
  }

  switchLabels(type: string) {
    this.f.username.clearValidators();
    if (type == "Student") {
      this.userNameText = "Username or Phone Number";
      this.userNamePlaceholder = "Username/Phone Number";
      this.passwordHint =
        "Enter your parent’s phone number registered with Zeraki Learning to assist in recovery of your account.";
      this.f.username.setValidators([Validators.required]);
      this.forgotPasswordHint = false;
      this.forgotUsername = true;
      this.teacherHint = false;
    }
    if (type == "Teacher") {
      this.userNameText = "Username";
      this.userNamePlaceholder = "Username";
      this.passwordHint =
        "Enter the phone number you registered with Zeraki Analytics to get the reset code";
      this.f.username.setValidators([Validators.required]);
      this.forgotPasswordHint = false;
      this.forgotUsername = false;
      this.teacherHint = true;
    }
    if (type == "Parent") {
      this.userNameText = "Phone Number";
      this.userNamePlaceholder = "Phone Number";
      this.f.username.setValidators([
        Validators.required,
        Validators.pattern("^\\d{10}$"),
      ]);
      this.forgotPasswordHint = true;
      this.forgotUsername = false;
      this.teacherHint = false;
    }

    this.f.username.enable();
    this.f.username.updateValueAndValidity();
    this.userChecked =
      this.feedback =
      this.submitted =
      this.usernameCheck =
        false;
    this.error = null;
    this.loginForm.reset();
    this.login = true;
    this.forgot = false;
    this.showForgotUsername = false;

    // this.forgotUsername = false;
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout();
    this._router.navigate(["login"]);
  }

  //Getter
  get roles(): Array<any> {
    var roles = [
      {
        id: 30,
        role: "Student",
      },
      {
        id: 60,
        role: "Parent",
      },
      {
        id: 70,
        role: "School",
      },
    ];

    return roles;
  }

  get f() {
    return this.loginForm.controls;
  }

  get rf() {
    return this.registerForm.controls;
  }

  get ff() {
    return this.forgotPasswordForm.controls;
  }

  get fu() {
    return this.forgotUsernameForm.controls;
  }

  get roleIsSchool() {
    return this.rf.role.value == 70;
  }

  //Public Methods
  userNameValidator(control: AbstractControl): ValidationErrors | null {
    const username = control.value?.toLowerCase();
    let notANumber = isNaN(+Number(username));

    if (notANumber) {
      //Validate username
      const reg_username = /^(.*)+@(.*)+$/;
      // const reg_username = /^\S+@\S+\.\S+$/;

      if (username?.match(reg_username)) {
        return null;
      }
    } else {
      //Validate phonenumber
      const reg_phonenumber = /^\+?\d+$/;
      if (username.match(reg_phonenumber)) {
        return null;
      }
    }

    return { format: true };
  }

  switchForm(form: string) {
    this.clearAlerts();
    this.login =
      this.forgot =
      this.forgotUsername =
      this.register =
      this.reset =
        false;
    if (form === "login") {
      this.login = true;
      this.resetCodeGenerated = false;
      this.newPasswords = false;
      this.showForgotUsername = false;
    }
    if (form === "register") {
      this.register = true;
      this.r_submitted = false;
      this.registerForm.reset(this.registerForm.value);
    }
    if (form === "forgot") {
      this.forgot = true;
      this.ff.username.setValue(this.f.username.value);
      this.ff.username.disable();
      this.phoneNumberConfirmation = true;
      this.togglePhoneValidator();
      // if (this.ff.username.value.includes('@')) {
      //   this.checkUsernameForgotPassword(this.ff.username.value);
      // } else {
      //   this.processPhoneNumber();
      // }
    }
    if (form === "forgotUsername") {
      this.forgotUsername = true;
      this.showForgotUsername = true;
      this.phoneNumberConfirmation = true;
      this.togglePhoneValidator();
    }
    if (form === "reset") {
      this.reset = true;
    }
  }

  scrollToElement(targetDiv: HTMLElement): void {
    targetDiv.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  onSubmit() {
    this.submitted = true;

    //Clear existing credentials
    this._cookieService.remove("username");
    this._cookieService.remove("password");

    // Stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // Login
    this.loading = true;

    this.loginUser(this.f.username.value.toLowerCase(), this.f.password.value);
  }

  loginUser(username, password) {
    let isSelfRegistered = false;
    if (/^[0-9 +]*$/.test(username)) {
      isSelfRegistered = true;
    }
    this._authenticationService
      .login(username, password, isSelfRegistered)
      .subscribe(
        (usr) => {
          this.loading = false;

          if (this.f.rememberMe) {
            this._cookieService.put(
              "username",
              Buffer.from(this.f.username.value).toString("base64")
            );
            this._cookieService.put(
              "password",
              Buffer.from(this.f.password.value).toString("base64")
            );
            this._cookieService.put(
              "rememberMe",
              JSON.stringify(this.f.rememberMe.value),
              { secure: true }
            );
          }
          const isStudent = (usr.role == 30 || usr.role == 31)
          if (isStudent) {
            this.getUserSubscriptionDates();
            return;
          }

          if (this.returnUrl) {
            this._router.navigate([this.returnUrl]);
          }
           else {
            this._helperFunctions.navigateToUsersDashboard(usr);
          }
        },
        (err) => {
          this.loading = false;
          this.feedback = "";
          this.error.title = "Error";
          this.error.body = err.error.message;
        }
      );
  }

  getUserSubscriptionDates() {
    this._subscriptionService
      .getUserSubDates()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.hasSelectedSubjects = res.subjectsSelected === true;
          if (!this.hasSelectedSubjects) {
            this._router.navigate(["/onboarding"])
            return
          }

          if (this.returnUrl) {
            this._router.navigate([this.returnUrl]);
          }
           else {
            this.router.navigate(["/dashboard/new"]);
          }
        },
        (err: any) => {
          console.error(err.error.message);
        }
      );
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  toggleRegPasswordTextType() {
    this.regPasswordTextType = !this.regPasswordTextType;
  }

  toggleRegPasswordTextType2() {
    this.regPasswordTextType2 = !this.regPasswordTextType2;
  }

  // loadCounties() {
  //   this._schoolService.getAllCounties()
  //   .subscribe(
  //     (data: any) => {
  //       this.counties = data;
  //       console.log('seee',this.counties);
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  // loadTeacherRoles() {
  //   this._schoolService.getTeacherRoles().subscribe(
  //     (respData) => {
  //       this.designations = respData;
  //       console.log(respData);
  //     },
  //     (err) => {
  //       console.error(err);
  //     }
  //   );
  // }

  onSubmitReg() {
    this.r_submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      console.log(this.registerForm.value);
      return;
    }

    this.loading = true;
    if (this.roleIsSchool) {
      console.log(this.rf);
      this._schoolService
        .registerSchool(
          this.rf.schoolName.value,
          this.rf.name.value,
          this.rf.phone.value,
          this.rf.county.value,
          this.rf.designation.value
        )
        .subscribe(
          (res) => {
            this.loading = false;
            this.feedback =
              "Thank you for registering with Zeraki Learning.Your application has been received and we will notify you once your application has been verified";
            this.success = true;
            this.registerForm.reset();
          },
          (err) => {
            this.loading = false;
            this._helperFunctions.showWarningToastr(
              "Error!",
              err.error.message
            );
            this.error.body = err.error.message;
          }
        );
    } else {
      const userDetails = {
        firstName: this.registerForm.value.name,
        lastName: "",
        email: this.registerForm.value.email,
        phoneNumber: this.registerForm.value.phone,
        schoolRole: this.registerForm.value.role,
        password: this.registerForm.value.password,
      };
      this._userService.addUser(userDetails).subscribe(
        (res) => {
          this.loading = false;
          this.showSuccess("Registration successful");
          this.loginUser(this.rf.phone.value, this.rf.password.value);
        },
        (err) => {
          this.loading = false;
          this.showFailed(err.error.message);
        }
      );
    }
  }

  toggleCodeValidator(): void {
    this.ff.resetCode.setValidators([Validators.required]);
    this.ff.resetCode.updateValueAndValidity();
  }

  togglePhoneValidator(): void {
    this.ff.phoneNumber.setValidators([Validators.required]);
    this.ff.phoneNumber.updateValueAndValidity();
  }

  clearAlerts() {
    this.error = {};
    this.feedback = null;
    this.success = false;
  }

  checkUsername(username) {
    this.loading = true;
    this.usernameCheck = true;
    this.clearAlerts();

    if (this.f.username.errors?.format) {
      this.loading = false;
      return;
    }

    //Validate username
    this._authenticationService
      .checkUsernameOrPhone(username)
      .pipe(first())
      .subscribe(
        () => {
          this.loading = false;
          this.clearAlerts();
          this.feedback = "Enter password to login";
          // this.showSuccess("login")

          if (username !== this.storedUsername) {
            this.f.rememberMe.setValue(false);
            this.f.password.setValue("");
          }

          this.userChecked = true;
          this.f.username.disable();
        },
        (err) => {
          this.clearAlerts();
          this.loading = false;
          // this.error.body = JSON.parse(err.error.message).message;
          this.error.body = err.error.message;
          this.error.title = "Error";
        }
      );
  }

  changeUser() {
    this.clearAlerts();
    this.f.username.enable();
    this.userChecked = false;
  }

  checkUsernameForgotPassword(username) {
    this._authenticationService
      .checkUsernameOrPhone(username)
      .pipe(first())
      .subscribe(
        (res) => {
          this.clearAlerts();
          //If username provided
          if (username.includes("@")) {
            //Check if user has a valid phone number
            if (res.passwordReset.noPhone) {
              //Add condition for valid phone number as well
              this.error.title = "Error";
              this.error.body = res.passwordReset.message;
              this.loading = false;
              return;
            }

            this.feedback = res.passwordReset.message;
            this.ff.username.disable();
            this.phoneNumberConfirmation = true;
            this.togglePhoneValidator();
            this.loading = false;
          }
        },
        (err) => {
          this.error.title = err.error.title;
          this.error.body = JSON.parse(err.error.message).message;
          this.loading = false;
        }
      );
  }

  onSubmitForgot() {
    this.f_submitted = true;
    const username: string = this.ff.username.value;
    const phoneNumber: string = this.ff.phoneNumber.value;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    //Check username
    this.loading = true;

    //check if the user is associated to the number they have provided
    this._authenticationService
      .checkPhoneAndSendCode(username, phoneNumber ? phoneNumber : username)
      .pipe(first())
      .subscribe(
        (res) => {
          if (this.error.title != "" && this.error.body != "") {
            this.error.title = "";
            this.error.body = "";
          }

          this.showSuccess(res.message);
          // this.feedback = res.message;
          this.ff.username.disable();
          this.resetCodeGenerated = true;
          this.toggleCodeValidator();
          // this.ff.phoneNumber.disable();
        },
        (err) => {
          this.loading = false;
          this.error.title = "Error";
          this.error.body = err.error.message;
        },
        () => (this.loading = false)
      );
  }

  onSubmitForgotUsername() {
    this.fu_submitted = true;
    const phoneNumber: string = this.fu.phoneNumber.value;

    if (this.forgotUsernameForm.invalid) {
      return;
    }

    this.loading = true;

    this._authenticationService.getUsernameFromPhone(phoneNumber).subscribe(
      (respData) => {
        const success = "New login credentials have been sent to the number"
        this.showSuccess(success);
        this.toggleCodeValidator();
      },
      (err) => {
        this.loading = false;
        this.error.title = "Error";
        this.error.body = err.error.message;
        console.error("error", err.error);
      },
      () => (this.loading = false)
    );
  }

  getAllCounties() {
    this._schoolService.getAllCounties().subscribe(
      (data: any) => {
        this.counties = data;
        console.log("seee", this.counties);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getTeacherDesignations() {
    this._schoolService.getTeacherRoles().subscribe(
      (respData) => {
        this.designations = respData;
        console.log(respData);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  roleChanged() {
    if (this.roleIsSchool) {
      this.getAllCounties();
      this.getTeacherDesignations();
      this.registerForm.addControl(
        "schoolName",
        new FormControl("", [Validators.required])
      );
      this.registerForm.addControl(
        "designation",
        new FormControl("", [Validators.required])
      );
      this.registerForm.addControl(
        "county",
        new FormControl("", [Validators.required, Validators.min(0)])
      );

      this.registerForm.removeControl("password");
      this.registerForm.removeControl("confirmPassword");
    } else {
      this.registerForm.addControl(
        "password",
        new FormControl("", [Validators.required])
      );
      this.registerForm.addControl(
        "confirmPassword",
        new FormControl("", [Validators.required])
      );
      this.registerForm.setValidators(
        this._helperFunctions.matchPassword("password", "confirmPassword")
      );

      this.registerForm.removeControl("county");
      this.registerForm.removeControl("schoolName");
      this.registerForm.removeControl("designation");
    }

    if (this.rf.schoolName) {
      this.rf.schoolName.updateValueAndValidity();
      this.rf.designation.updateValueAndValidity();
      this.rf.county.updateValueAndValidity();
    } else {
      this.rf.password.updateValueAndValidity();
      this.rf.confirmPassword.updateValueAndValidity();
    }
  }

  showSuccess(msg) {
    this.toastr.success(`🙌 ${msg}`, "Success!", {
      timeOut: 5000,
      toastClass: "toast ngx-toastr",
      closeButton: true,
    });
  }

  showFailed(msg) {
    this.toastr.warning(`⚠️ ${msg}`, "Failed!", {
      timeOut: 5000,
      toastClass: "toast ngx-toastr",
      closeButton: true,
    });
  }

  processResetCode() {
    this.codeSubmitted = true;

    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.clearAlerts();

    this._authenticationService
      .checkResetCode(
        this.ff.resetCode.value,
        this.ff.username.value,
        this.ff.username.value
      )
      .pipe(first())
      .subscribe(
        (res) => {
          this.feedback = res.message + ". Enter your new password.";
          this.resetCodeGenerated = false;
          this.newPasswords = true;
        },
        (err) => {
          this.error.title = "Error";
          this.error.body = err.error.message;
        }
      );
  }

  processPhoneNumber() {
    this.phoneSubmitted = true;

    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this._authenticationService
      .checkPhoneAndSendCode(
        this.ff.username.value,
        this.ff.phoneNumber.pristine
          ? this.ff.username.value
          : this.ff.phoneNumber.value
      )
      .pipe(first())
      .subscribe(
        (res) => {
          this.clearAlerts();
          this.feedback = res.message;
          this.phoneNumberConfirmation = false;
          this.resetCodeGenerated = true;
          this.toggleCodeValidator();
        },
        (err) => {
          console.error(err.error);
          this.error.title = "Error";
          this.error.body = err.error.message;
        }
      );
  }

  changePassword() {
    const changePasswordDetails = {
      currentPassword: "",
      newPassword: this.ff.password.value,
      confirmPassword: this.ff.password.value,
      resetCode: this.ff.resetCode.value,
    };
    this._authenticationService
      .resetPassword(this.ff.username.value, changePasswordDetails)
      .pipe(first())
      .subscribe(
        (res) => {
          this.clearAlerts();
          this.feedback = res.message;

          this._authenticationService
            .login(this.ff.username.value, this.ff.password.value, false)
            .subscribe(
              (usr) => {
                this.loading = false;
                this._helperFunctions.navigateToUsersDashboard(usr);
              },
              (err) => {
                this.clearAlerts();
                this.error.body = err;
                this.loading = false;
              }
            );
        },
        (err) => {
          this.clearAlerts();
          this.error.title = "error";
          this.error.body = err.error.message;
          this.loading = false;
        }
      );
  }

  getAcceptedTermsStatus($event) {
    this.agreedToTerms = $event;
  }
}
