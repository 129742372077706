import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {CoreConfigService} from '@core/services/config.service';
import {PaymentService} from '@core/services/payment.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '@core/services/auth';
import {Location} from '@angular/common';
import {User} from '@core/models';

export enum paymentStages {
    PROVIDE_PAYMENT_PLAN = 1,
    DISPLAY_PAYMENT_INSTRUCTIONS = 2,
    CONRIFM_PAYMENT = 3,
    DISPLAY_PAYMENT_VERIFY = 4,
    DISPLAY_PAYMENT_SUCCESS = 5,
    DISPLAY_PAYMENT_FAILED = 6,
    DISPLAY_PAYMENT_SUCCESS_FOR_MOBILE = 7
}

@Component({
    selector: 'app-upgrade-page',
    templateUrl: './upgrade-page.component.html',
    styleUrls: ['./upgrade-page.component.scss']
})


export class UpgradePageComponent implements OnInit {

    public coreConfig: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    @Input() plans: Array<any> = [];
    @Input() isLoadingPlans = false;

    @Output() processStageEvt: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancelPaymentProcessEvt: EventEmitter<any> = new EventEmitter<any>();
    public paymentStage = paymentStages;
    isLoading = false;
    public upgradeStatus = 'pending';
    private inter: any;
    private counter: number = 0;
    public subscription: any;
    public data: any;
    private hasHistory: boolean;

    paymentInfo: any = {};
    processState = paymentStages.PROVIDE_PAYMENT_PLAN;
    isPageView = false;

    constructor(
        private _coreConfigService: CoreConfigService,
        private paymentService: PaymentService,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private _authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private _location: Location) {
        this._unsubscribeAll = new Subject();
        this.hasHistory = this.router.navigated;
    }

    ngOnInit(): void {
        // this.route.queryParams.subscribe(params => {
        //     if (Object.keys(params).length > 0) {
        //       if (params.tokenId) {
        //         const inputParam: string = params.tokenId;
        //         let tok: any = {};
        //         tok.access_token = inputParam;
        //         this._authenticationService.setTokens(tok).subscribe(() => {
        //         });
        //       } else {
        //         console.error('Token ID missing from query params');
        //       }
        //     } else {
        //       if (this.isPageView) this._location.back();
        //     }
        //   });         
        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });

        if(Object.keys(this.route.snapshot.queryParams).length > 0) {
            this.initMobile();
        } else {
            this.getPlans();
            this.initWebApp();
        }
    }

    initWebApp() {
        this.isPageView = false;
    }

    initMobile() {
        this.isPageView = true;
        this.configureLayout();
        this.getTokenId();
    }

    configureLayout() {
        this._coreConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                menu: {
                    hidden: true
                },
                customizer: false,
                enableLocalStorage: false
            }
        };
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
        clearInterval(this.inter);
    }
    

    getTokenId(): void {
        console.log('getTokenId() called');
        const tokenId = this.route.snapshot.queryParams.token;
        if (!tokenId) {
          console.error('Token missing from query params');
          return;
        }
        this.paymentService.getTokenId(tokenId).subscribe(
          respData => {
            let tok: any = {};
            tok.access_token = respData.accessToken;
            this._authenticationService.setTokens(tok).subscribe(() => {
                this.getPlans();
            });
          },
          error => {
            console.error(error);
          }
        );
      }
    
    getPlans() {
        console.log('getPlans() called');
        this.isLoadingPlans = true;
        this.paymentService.getPlans().subscribe(
            data => {
                this.plans = data;
                this.isLoadingPlans = false
            },
            () => {
                this.isLoadingPlans = false
            },
        );
    }


    updateProcessState(state: number) {
        this.processState = state;
        this.processStageEvt.emit(state);
    }

    //get plan from plan component
    getPaymentPlan(phonePlan: any) {
        this.paymentInfo.plan = this.plans[parseInt(phonePlan.plan)];
        this.paymentInfo.phone = phonePlan.phone;
        this.updateProcessState(paymentStages.CONRIFM_PAYMENT)
    }
    cancelPaymentInfo(){
        this.updateProcessState(paymentStages.PROVIDE_PAYMENT_PLAN)
    }
    approvePaymentInfo(){
        this.addSubscription();
    }
    addSubscription() {
        this.counter = 0;
        this.updateProcessState(paymentStages.DISPLAY_PAYMENT_INSTRUCTIONS)
        if (!this.isLoading) {
            const data = this.processFormData();
            this.isLoading = true;

            this.paymentService.addSubscription(data)
                .subscribe(
                    () => {
                        this.isLoading = false;
                        this.upgradeStatus = 'sent';
                        // this.showSuccess(res.message);
                        setTimeout(() => {
                            this.checkSubscription();
                        }, 5000);
                    },
                    () => {
                        // this.showFailed(error.error.message);
                        this.updateProcessState(paymentStages.DISPLAY_PAYMENT_FAILED);
                        this.isLoading = false;
                    }
                );
        }
    }
    processFormData() {
        this.data = this.paymentInfo;
        let formData: any = {};
        if (this.data.plan) {
            formData.amount = this.data.plan.hasDiscount ? this.data.plan.discountedAmount : this.data.plan.amount;
            formData.planId = this.data.plan.planId;
            formData.phoneNumber = this.data.phone;
        }
        return formData;
    }



    checkSubscription() {
        this.upgradeStatus = 'awaiting';

        setTimeout(() => {
            this.updateProcessState(paymentStages.DISPLAY_PAYMENT_VERIFY);
        }, 5000);


        this.inter = setInterval(() => {
            this.getSubscription();
        }, 10000);
    }

    getSubscription() {
        if (this.counter >= 5) {
            clearInterval(this.inter);
            // this.clearInterval(this.inter);
            this.updateProcessState(paymentStages.DISPLAY_PAYMENT_FAILED);
            return;
        }
        this.counter++;
        this.paymentService.getSubscription().subscribe(
            data => {
                this.subscription = data.subscription;
                if (this.subscription && this.subscription.subscribed) {
                    clearInterval(this.inter);
                    // let paymentState: number = (this.isPageView) ? paymentStages.DISPLAY_PAYMENT_SUCCESS_FOR_MOBILE : paymentStages.DISPLAY_PAYMENT_SUCCESS;
                    let paymentState = paymentStages.DISPLAY_PAYMENT_SUCCESS_FOR_MOBILE;
                    if(!this.isPageView){
                        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
                        currentUser.subscription = this.subscription;
                        localStorage.setItem('currentUser',  JSON.stringify(currentUser));
                        paymentState = paymentStages.DISPLAY_PAYMENT_SUCCESS;
                    }
                    this.updateProcessState(paymentState);
                    setTimeout(() => {
                        this.loadHomepage();
                    }, 5000);
                }
            },
            () => {
                this.updateProcessState(paymentStages.DISPLAY_PAYMENT_FAILED);
            },
        );
    }

    getExpiryDate() {
        return (this.subscription) ? this.formatDate(new Date(this.subscription.expiresAt)) : '-';
    }

    getPlanName() {
        return (this.data && this.data.plan) ? this.data.plan.planName : '-';
    }

    loadHomepage() {
        // initiate close payment popup,or
        (!this.isPageView) ? this.cancelPaymentProcessEvt.emit() : this._authenticationService.logout();
    }


    // clearInterval(timer) {
    //     if (timer) clearInterval(timer);
    // }


    // showSuccess(msg) {
    //     this.toastr.success(`🙌 ${msg}`, 'Success!', {
    //         timeOut: 5000,
    //         toastClass: 'toast ngx-toastr',
    //         closeButton: true
    //     });
    // }

    // showFailed(msg) {
    //     this.toastr.warning(`⚠️ ${msg}`, 'Failed!', {
    //         timeOut: 5000,
    //         toastClass: 'toast ngx-toastr',
    //         closeButton: true
    //     });
    // }


    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    formatDate(date) {
        return (
            [
                this.padTo2Digits(date.getMonth() + 1),
                this.padTo2Digits(date.getDate()),
                date.getFullYear(),
            ].join('/') +
            ' ' +
            [
                this.padTo2Digits(date.getHours()),
                this.padTo2Digits(date.getMinutes()),
                // this.padTo2Digits(date.getSeconds()),
            ].join(':')
        );
    }


}


