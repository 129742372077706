import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "environments/environment";
import { School } from "@core/models";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class SchoolService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get school details
   */
  getById(schoolId: number): Observable<any> {
    return this._http.get<School>(
      `${environment.apiUrl}/school?schoolId=${schoolId}`
    );
  }

  /**
   * Get school students
   */
  getStudentsByForm(schoolId: number, form: number): Observable<any> {
    return this._http.get<any>(
      `${environment.apiUrl}/school/students?schoolId=${schoolId}&form=${form}`
    );
  }

  /**
   * Register School
   */
  registerSchool(
    schoolName: string,
    adminName: string,
    phone: string,
    countyId: number,
    designationId: number
  ): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}/school/register?schoolName=${schoolName}&name=${adminName}&phone=${phone}&county=${countyId}&role=${designationId}`,
      {}
    );
  }

  getAllCounties(): Observable<any> {
    return this._http.get<any[]>(`${environment.apiUrl}/county/all`);
  }

  getTeacherRoles(): Observable<any> {
    return this._http.get<any[]>(`${environment.apiUrl}/user/teacher/roles`);
  }

  /**
   * Verify School
   */
  verifySchool(payload: {
    schoolId: number;
    verifyStatus: number;
    registrationCode: string;
    schoolName: string;
    name: string;
    phone: number;
    county: number;
  }): Observable<any> {
    return this._http.post<School>(
      `${
        environment.apiUrl
      }/school/verify?schoolId=${payload.schoolId.toString()}&verifyStatus=${
        payload.verifyStatus
      }&registration=${payload.registrationCode}&schoolName=${
        payload.schoolName
      }&name=${payload.name}&phone=${payload.phone.toString()}&county=${
        payload.county
      }`,
      null
    );
  }

  /**
   * Reject School Request
   */
  rejectSchool(schoolId: number): Observable<any> {
    return this._http.delete<School>(
      `${environment.apiUrl}/school/unverified?schoolId=${schoolId.toString()}`
    );
  }

  /**
   * Get unverified schools
   */
  getUnverifiedSchools(page: number, size: number): Observable<any> {
    return this._http.get<any>(
      `${environment.apiUrl}/school/pending?page=${page}&pageSize=${size}`
    );
  }

  /**
   * Get list of schools
   */
  getSchools(page: number, size: number): Observable<any> {
    return this._http.get<Array<School>>(
      `${environment.apiUrl}/school/all?page=${page}&pageSize=${size}`
    );
  }

  /**
   * Get intakes
   */
  getIntakes(): Observable<any> {
    return this._http.get<Array<any>>(`${environment.apiUrl}/intakes`);
  }

  /**
   * Get active students data
   */
  getActiveStudentsData(): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/school/students/active`);
  }

  search(
    schoolName: string,
    status: number,
    page: number,
    size: number
  ): Observable<any> {
    return this._http.get<any>(
      `${environment.apiUrl}/school/search?name=${schoolName}&verifiedStatus=${status}&page=${page}&pageSize=${size}`
    );
  }

  createIntake(formNumber: number) {
    return this._http.post<any>(
      `${environment.apiUrl}/school/intake/create?form=${formNumber}`,
      {}
    );
  }

  getSchoolsByCode(code: string): Observable<any> {
    return this._http.get(
      `${environment.apiUrl}/school/registration?registration=${code}`
    );
  }

  syncSchool(registration: string) {
    return this._http.post(
      `${environment.apiUrl}/school/registration?registration=${registration}`,
      null
    );
  }
}
