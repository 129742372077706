import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { ClickStopPropagationDirective } from './click-stop-propagation/click-stop-propagation.directive';

@NgModule({
  declarations: [RippleEffectDirective, FeatherIconDirective, ClickStopPropagationDirective],
  exports: [RippleEffectDirective, FeatherIconDirective, ClickStopPropagationDirective]
})
export class CoreDirectivesModule {}
