import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-payment-verify',
  templateUrl: './payment-verify.component.html',
  styleUrls: ['./payment-verify.component.scss']
})
export class PaymentVerifyComponent implements OnInit {

  @Input() coreConfig:any;

  @Output() loadHomepageEvt:EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  loadHomepage(){
    this.loadHomepageEvt.emit();
  }

}
