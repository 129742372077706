import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  @Output() childMethodEvt:EventEmitter<boolean> = new EventEmitter()
  @Input() showCheckbox: boolean = true;

  constructor(
    private _modalService:NgbModal

  ) {}

  agreedToTerms:boolean;

      // modal Basic
      modalOpenSLCIM(modalSLCIM) {
        this._modalService.open(modalSLCIM, { scrollable: true, size: 'lg' });
      }

      checkboxChange() {
        this.childMethodEvt.emit(this.agreedToTerms)
      }

  ngOnInit(): void {
  }

}
