<div class="study-profile text-center">
    <form [formGroup]="studentProfile" class="d-flex align-items-center">
        <ng-select formControlName="form" [clearable]="false" placeholder="Select Form"
            (change)="getTopics()">
            <ng-option *ngFor="let form of forms" [value]="form">Form {{form}}</ng-option>
        </ng-select>

        <ng-select formControlName="subjectId" [loading]="loadingSubjects" placeholder="Select Subject" (change)="getTopics()">
            <ng-option *ngFor="let subject of subjects" [value]="subject.subjectId"
                [attr.selected]="subjects[0]">{{subject.name}}</ng-option>
        </ng-select>

        <ng-select formControlName="topicId" [loading]="loadingTopics" placeholder="Select Topic" (change)="changeTopic()">
                <ng-option *ngFor="let topic of topics" [value]="topic.topicId">{{topic.title}}</ng-option>
        </ng-select>
    </form>
</div>