import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CountyService {

  constructor(private _http: HttpClient) { }

  getAll(): Observable<any>{
    return this._http.get<any>(`${environment.apiUrl}/county/all`);
  }
   

}
