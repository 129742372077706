export * from './core-config';
export * from './core-menu';
export * from './auth/role';
export * from './auth/user';
export * from './examination';
export * from './school';
export * from './student';
export * from './assignment';
export * from './assignment-question';
export * from './assignment-response';
export * from './subject';
export * from './note';
export * from './dependent';
export * from './quiz-question';
export * from './quiz-answer';
export * from './teacher';
export * from './county';
export * from './temp-school';
export * from './teacher-role';
export * from './intake';
export * from './stream';
