import { Component, Input, OnInit } from "@angular/core";
import * as models from "@core/models";
import { SubjectService } from "@core/services/subject.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-subject-edit-page",
  templateUrl: "./subject-edit-page.component.html",
  styleUrls: ["./subject-edit-page.component.scss"],
})
export class SubjectEditPageComponent implements OnInit {
  @Input() isRemovingSubjects = false;
  @Input() isAddingSubjects = false;
  loadingSubjects = false;
  subjectsWithContent!: models.Subject[];
  subjects!: models.Subject[];
  allSubjects!: models.Subject[];
  selectedSubjects = [];
  removingSubjects = false;
  addingSubjects = false;
  private unsubscribe$ = new Subject();

  constructor(
    private subjectService: SubjectService,
    private modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.getSubjectsWithContent();
  }

  getSubjectsWithContent(): void {
    this.loadingSubjects = true;
    this.subjectService
      .getSubjectsWithContent()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.loadingSubjects = false;
          this.subjectsWithContent = res;
          if (this.isRemovingSubjects) {
            this.subjects = this.subjectsWithContent;
          }
          // // add the property selected to each subject
          this.subjectsWithContent.forEach((subject) => {
            subject.selected = true;
          });

          // populate the selectedSubjects array with subjects id
          if (this.subjectsWithContent && this.subjectsWithContent.length) {
            this.selectedSubjects = this.subjectsWithContent.map(
              (subject) => subject.subjectId
            );
          }
          if (this.isAddingSubjects) {
            this.getAllSubjects();
          }
        },
        (err) => {
          this.loadingSubjects = false;
          console.log(err);
        }
      );
  }

  getAllSubjects(): void {
    this.loadingSubjects = true;
    this.subjectService
      .getAll()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.loadingSubjects = false;
          this.allSubjects = res;
          this.subjects = this.allSubjects.filter(
            (subject) =>
              !this.subjectsWithContent.some(
                (s) => s.subjectId === subject.subjectId
              )
          );
          console.log(this.subjects);
        },
        (err) => {
          this.loadingSubjects = false;
          console.error(err);
        }
      );
  }

  selectSubject(subject: any): void {
    subject.selected = !subject.selected;
    if (subject.selected) {
      this.selectedSubjects.push(subject.subjectId);
    } else {
      const index = this.selectedSubjects.indexOf(subject.subjectId);
      if (index !== -1) {
        this.selectedSubjects.splice(index, 1);
      }
    }
  }

  saveChanges(): void {
    this.isAddingSubjects ? this.saveSelectedSubjects() : this.removeSubjects();
  }

  saveSelectedSubjects(): void {
    const params = {
      subjects: this.selectedSubjects.map((subjectId) => ({ subjectId })),
    };
    this.subjectService.postSelectedSubjects(params).subscribe(
      (res) => {
        this.modal.close();
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  removeSubjects(): void {
    const unselectedSubjects = this.subjects.filter(
      (subject) => !subject.selected
    );
    const unselectedSubjectsIds = unselectedSubjects.map(
      (subject) => subject.subjectId
    );
    const params = {
      subjects: unselectedSubjectsIds.map((subjectId) => ({ subjectId })),
    };

    this.subjectService.removeSubjects(params).subscribe(
      (res) => {
        this.modal.close();
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }
}
