<!-- Error page-->
<div class="misc-wrapper">
    <div class="misc-inner" [ngClass]="{'is-p-5':isPageView}">

        <!-- pay-padding -->
        <div class="d-lg-flex">
            <div class="w-100">

                <h2 class="make-payment">
                    <span class="make-payment-icon">
                        <img
                                src="assets/images/payments/receive_cash.png" height="35px"/>
                    </span>
                    <span class="make-payment-text">
                        Make Payment for Zeraki Learning</span>
                </h2>
                <ul class="pay-progress">
                    <li style="margin-right: 3em;"></li>
                    <li [ngClass]="{'is-not-highlighted':processState < 2}"
                        class="active">
                    </li>
                </ul>

                <hr class="payment-line">

                <div style="display: flex; flex-direction: column; justify-content: center; min-height: 28rem">

                    <!-- First view. == Collect payment plan -->
                    <app-provide-subscription-plan
                            *ngIf="processState == paymentStage.PROVIDE_PAYMENT_PLAN"
                            (loadHomepageEvt)="loadHomepage()"
                            (planEvt)="getPaymentPlan($event)"
                            (phoneNumberEvt)="getPaymentPlan($event)"
                            [plans]="plans"
                            [isLoading]="isLoadingPlans">
                    </app-provide-subscription-plan>

                    <div *ngIf="processState == paymentStage.CONRIFM_PAYMENT">
                        <div class="card payment-confirmation">
                            <div class="card-body">
                                <div class="price-details">
                                    <p class="mb-0 text-center">You are about to pay <strong>{{(paymentInfo.plan.hasDiscount ? paymentInfo.plan.discountedAmount : paymentInfo.plan.amount) | currency:'KES'}}</strong>
                                    for your <strong>{{paymentInfo.plan.planName}}</strong></p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between">
                            <button class="btn btn-outline-danger waves-effect waves-light"
                                    (click)="cancelPaymentInfo()">Cancel
                            </button>
                            <button class="btn btn-primary waves-effect waves-light" (click)="approvePaymentInfo()">
                                Proceed to payment
                            </button>
                        </div>
                    </div>


                    <!--Third view. == Display Instruction View -->
                    <app-payment-instruction
                            [coreConfig]="coreConfig"
                            [paymentInfo]="paymentInfo"
                            *ngIf="processState == paymentStage.DISPLAY_PAYMENT_INSTRUCTIONS"
                            (loadHomepageEvt)="loadHomepage()"
                            (addSubscriptionEvt)="addSubscription()"></app-payment-instruction>

                    <!-- Fourth. Display verify Payment view-->
                    <app-payment-verify
                            *ngIf="processState == paymentStage.DISPLAY_PAYMENT_VERIFY"
                            (loadHomepageEvt)="loadHomepage()"
                            [coreConfig]="coreConfig"></app-payment-verify>

                    <app-payment-success
                            *ngIf="processState == paymentStage.DISPLAY_PAYMENT_SUCCESS"
                            [coreConfig]="coreConfig"
                            [planName]="getPlanName()"
                            [expiryDate]="getExpiryDate()"
                    ></app-payment-success>

                    <app-payment-success-mobile
                            *ngIf="processState == paymentStage.DISPLAY_PAYMENT_SUCCESS_FOR_MOBILE"
                            [coreConfig]="coreConfig"
                            [planName]="getPlanName()"
                            [expiryDate]="getExpiryDate()"
                    ></app-payment-success-mobile>

                    <app-payment-error
                            *ngIf="processState == paymentStage.DISPLAY_PAYMENT_FAILED"
                            (loadHomepageEvt)="loadHomepage()"
                            [coreConfig]="coreConfig"
                            (addSubscriptionEvt)="addSubscription()"
                    ></app-payment-error>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- / Error page-->