import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from '@core/models';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Route, Router } from '@angular/router';
import {CoreConfigService} from "../config.service";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _router: Router,
    private _http: HttpClient,
    private _toastrService: ToastrService,
              private _coreConfigService:CoreConfigService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }


  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  setUserValue() {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  //clear : currentUserValue
  clearCurrentUserValue(){
    this.currentUserSubject.next(null);
  }

  /**
   *  Confirms if user is admin
   */
  get isStudent() {
    return this.currentUser && this.currentUserSubject.value.userRole === Role.Student;
  }

  /**
   *  Confirms if user is admin
   */
  get isIndependentStudent() {
    return this.currentUser && this.currentUserSubject.value.userRole === Role.independentStudent;
  }

  /**
   *  Confirms if user is admin
  */
  get isregisteredToSchool() {
    return this.currentUser && this.currentUserSubject.value.streamId != null;
  }

  /**
   *  Confirms if user is admin
  */
  get isParent() {
    return this.currentUser && this.currentUserSubject.value.userRole === Role.Parent;
  }

  /**
   *  Confirms if user is admin
   */
  get isTeacher() {
    return this.currentUser && this.currentUserSubject.value.userRole === Role.Teacher;
  }

  /**
   *  Confirms if user is admin
  */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.userRole === Role.Admin;
  }

  /**
   *  Confirms if user is admin
   */
  get isSuperAdmin() {
    return this.currentUser && this.currentUserSubject.value.userRole === Role.SuperAdmin;
  }


  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(username: string, password: string, isSelfRegistered: boolean) {

    const payload = {
      'username': username,
      'password': password,
      'isSelfRegistered': isSelfRegistered
    }

    return this._http
      .post<any>(`${environment.baseUrl}/login`, payload)
      .pipe(
        map(data => {
          // login successful if there's a jwt token in the response
          if (data && data.user) {
            data.user.user.userRole = data.user.user.schoolRole + (data.user.user.schoolRole == 30 && data.user.user.studentProfile == undefined ? 1 : 0);
            data.user.user.schoolRole = data.user.user.userRole;
            data.user.user.role = data.user.user.userRole;
            if(data.user.user.userRole == (30 || 31)) 
              data.user.user.streamId = data.user.user.studentProfile.currentStreamId

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(
              {
                ...data.user.user,
                access_token: data.accessToken,
                refresh_token: data.accessToken
              }
            ));

            // notify
            this.currentUserSubject.next(
              {
                ...data.user.user,
                access_token: data.accessToken,
                refresh_token: data.accessToken              }
            );
          }

          return { ...data.user.user, userRole: data.user.user.userRole, access_token: data.accessToken };
        })
      );
  }

  setTokens(tkn):Observable<any>{
    return new Observable<any>((subscriber)=>{
      this.currentUserSubject.next(
          {
            ...this.currentUserValue,
            access_token: tkn.access_token,
            refresh_token: tkn.refresh_token
          }
      );
      localStorage.setItem('currentUser', JSON.stringify(
          {
            ...this.currentUserValue,
            access_token: tkn.access_token,
            refresh_token: tkn.refresh_token
          }
      ));
      subscriber.next()
    })

  }

  setToken(tkn){
      this.currentUserSubject.next(
          {
            ...this.currentUserValue,
            access_token: tkn.access_token,
            refresh_token: tkn.refresh_token
          }
      );
      console.log(this.currentUserSubject);
      localStorage.setItem('currentUser', JSON.stringify(
          {
            ...this.currentUserValue,
            access_token: tkn.access_token,
            refresh_token: tkn.refresh_token
          }
      ));
    console.log(localStorage.getItem('currentUser'))
  }

  /**
   * Refresh token
   *
   */
  refreshToken() {
    return
    if (this.currentUserValue && this.currentUserValue.refresh_token) {
      return this._http
        .post<any>(`${environment.apiUrl}/authentication/refresh-token`, this.currentUserValue.refresh_token)
        .pipe(
          map(data => {
            return data;
          })
        )
    } else {
      return throwError("Error");
    }
  }

  getRole(roleId) {
    return roleId == 31 ? 'Student' : Role[roleId];
  }

  clearTokenCurrentUser(){
    localStorage.removeItem('currentUser');
  }


  clearLocalStorage() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('timerRunning');
    localStorage.removeItem('currentUserStatus'); 
    localStorage.removeItem('countdownValue');
    localStorage.setItem('timerRunning', null);

  }
  

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    // console.log("Called");
    if (this.currentUserValue && this.currentUserValue.refresh_token && this.currentUserValue.access_token) {
      this.logoutServer().subscribe(
        data => {
          this.clearLocalStorage();
          // notify
          this.currentUserSubject.next(null);
          this.configureLoginLayout()
          this._router.navigate(['login']);
        },
        error => {
       this.clearLocalStorage();
    
          this.currentUserSubject.next(null);
          this.configureLoginLayout()
          this._router.navigate(['login']);
          return;
        },
      );
    } else {
      this.clearLocalStorage() 
      this.currentUserSubject.next(null);
      this.configureLoginLayout()
      this._router.navigate(['login']);
    }

  }

  configureLoginLayout(){
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  showFailed(msg) {
    this._toastrService.warning(`⚠️ ${msg}`, 'Failed!', {
      timeOut: 5000,
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  /**
  * User logout
  *
  */
  logoutServer() {
    // remove user from local storage to log user out
    return this._http.get<any>(`${environment.apiUrl}/logout`, {});
  }


  getUserInfo(): Observable<any> {
    return new Observable((subscriber) => {
      subscriber.next(JSON.parse(localStorage.getItem('currentUser')));
    })
  }

  getUserDetails(userId: number): Observable<any> {
    return this._http
      .get<any>(`${environment.apiUrl}/user?userId=${userId.toString()}`)
      .pipe(
        tap((user) => (user))
      );
  }

  checkUsernameOrPhone(usernameOrPhone: string) {
    const params = new URLSearchParams()
    let phoneChecker = new RegExp('^[0-9 +]*$');
    params.append(phoneChecker.test(usernameOrPhone) ? "phone" : "username",usernameOrPhone)
    return this._http.post<any>(`${environment.apiUrl}/check?${params}`, null)
  }

  getUsernameFromPhone(phoneNumber: string) {
    const body = { phoneNumber };
    return this._http.post(`${environment.apiUrl}/user/phone`, body);

  }

  checkPhoneAndSendCode(username: string, phone: string): Observable<any> {
    const params = new URLSearchParams()
    params.append('username',username)
    params.append('phone', phone)
    return this._http.post<any>(`${environment.apiUrl}/admins/request-password-reset?${params}`, null);
  }

  checkResetCode(resetCode: string, phone: string, username: string): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}/authentication/check/reset-code`, { resetCode, phone, username });
  }

  checkPassword(password: string): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}/authentication/password`, { password });
  }

  requestPasswordResetCode(username: string, phone: string): Observable<any> {
    return this._http
      .post<any>(`${environment.apiUrl}/authentication/request/reset-code`, { username, phone });
  }

  checkPasswordResetCode(username: string, resetCode: string): Observable<any> {
    return this._http
      .post<any>(`${environment.apiUrl}/authentication/request/reset-code`, { username, resetCode });
  }

  resetPassword(username: string, payload: any) {
    return this._http
      .post<any>(`${environment.apiUrl}/admins/reset-password?username=${username}`, payload);
  }

  changePassword(password: string) {
    return this._http.post<any>(`${environment.apiUrl}/authentication/change-password`, { password });
  }

}
