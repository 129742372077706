import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss']
})
export class ForgotUsernameComponent implements OnInit {

  forgotUsername: FormGroup;

  constructor(
    private formbuilder: FormBuilder,
    ) { }

  ngOnInit(): void {
    this.forgotUsername = this.formbuilder.group({
      fullname: ['', Validators.required],
      phone: ['', [Validators.required]],
      school: ['', [Validators.required]],
    });

  }

}
