import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "environments/environment";
import { Dependent } from "@core/models";
import { Observable } from "rxjs";
import { StudentAccessSummary } from "@core/models/studentAccessSumary";
import { SubjectAccess } from "@core/models/subject-access";
import { Params } from "@angular/router";
import { QuizAccess } from "@core/models/quiz-access";

@Injectable({ providedIn: "root" })
export class DependentService {
  url: string;

  constructor(private _http: HttpClient) {
    this.url = `${environment.baseUrl}`;
  }

  /**
   * Create a Parent User
   */
  addParent(payload: any): Observable<any> {
    return this._http.post<any>(`${this.url}/dependant/parent`, payload);
  }

  /**
   * Create a Parent to Student Connection Request
   */
  createStudentConnectionRequest(dependantUsername: string): Observable<any> {
    const body = { dependantUsername };
    return this._http.post(`${this.url}/dependant`, body);
  }

  /**
   * Accept a Parent to student Connection Request
   */
  acceptStudentConnectionRequest(parentId: number): Observable<any> {
    return this._http.put<any>(
      `${this.url}/dependant/accept?parentId=${parentId.toString()}`,
      {}
    );
  }

  /**
   * Reject Parent to student Connection Request
   */
  rejectStudentConnectionRequest(parentId: number): Observable<any> {
    return this._http.delete<any>(
      `${this.url}/dependant/reject?parentId=${parentId.toString()}`,
      {}
    );
  }

  /**
   * Cancel a Pending Parent to student Connection Request
   */
  // cancelStudentConnectionRequest(dependantId: number): Observable<any> {
  //   return this._http.get(`${this.url}/dependant/cancel?dependantId=${dependantId}`)
  // }
  cancelStudentConnectionRequest(dependantId: number): Observable<any> {
    return this._http.put(
      `${this.url}/dependant/cancel?dependantId=${dependantId}`,
      {}
    );
  }

  /**
   * Unlink a dependent
   */
  unlinkDependent(dependentUserId: number): Observable<any> {
    return this._http.delete<any>(
      `${this.url}/dependant/remove?userid=${dependentUserId.toString()}`,
      {}
    );
  }

  /**
   * Gets student accounts connected to parent account
   */
  getDependents(): Observable<Dependent[]> {
    return this._http.get<Dependent[]>(`${this.url}/dependant`);
  }

  /**
   * Gets pending parent to student connection requests
   */
  getPendingRequests(): Observable<any> {
    return this._http.get<any>(`${this.url}/dependant/requests`);
  }

  /**
   * Get all accounts connected to logged in student account
   */
  getParents(): Observable<any> {
    return this._http.get<any>(`${this.url}/dependant/parents`);
  }

  getDependantAccessSummary(userId: number, form: number): Observable<StudentAccessSummary> {
    return this._http.get<StudentAccessSummary>(environment.baseUrl + `/dependant/report/summary?userId=${userId}&form=${form}`)
  }

  getSubjectLearningProgress(userId: number, form: number): Observable<SubjectAccess[]> {
    return this._http.get<SubjectAccess[]>(environment.baseUrl + `/dependant/report/subjects?userId=${userId}&form=${form}`)
  }

  getQuizPerformance(userId: number,form: number, subjectId: number): Observable<QuizAccess[]> {
    return this._http.get<QuizAccess[]>(environment.baseUrl + `/dependant/report/quiz?userId=${userId}&form=${form}&subjectId=${subjectId}`)
  }
}
