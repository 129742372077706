
  <ng-container *ngIf="showCheckbox">
    <div class="custom-control custom-checkbox form-group form-check form-check-inline">
        <input type="checkbox" [(ngModel)]="agreedToTerms" (change)="checkboxChange()" class="custom-control-input" id="customCheck2" />
        <label class="custom-control-label checkbox" for="customCheck2">I agree to the
            <a (click)="modalOpenSLCIM(modalSLCIM)" href="javascript:void(0)"><span>Terms and conditions</span></a>
        </label>
      </div>
  </ng-container>
  <ng-container *ngIf="!showCheckbox">
    <a (click)="modalOpenSLCIM(modalSLCIM)" href="javascript:void(0)"><span>Terms and conditions</span></a>
  </ng-container>



                                                <!-- Modal -->
                                                <ng-template #modalSLCIM let-modal>
                                                    <div class="modal-header">
                                                    <h4 class="modal-title" id="myModalLabel1">Public Privacy and Data Protection Policy</h4>
                                                    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    </div>
                                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                                    <p>
                                                        Welcome to the Data Privacy Policy for Litemore Limited (“Litemore”, “we”, “us”, “our”). 
                                                        We  respect  your  privacy  and  are  committed  to  protecting  your  information  that  is  collected  or 
                                                        disclosed to us (called “personal data” explained below). Since we want to empower you to make the 
                                                        best  decisions  about  your  privacy  and personal data, we  have  made  this  Privacy  Policy  as  clear  and 
                                                        transparent as possible to ensure that you understand your rights under the law.  
                                                        This Privacy Policy explains how Litemore processes personal data about people with whom we come 
                                                        into contact (referred to as “you” or “your” in this document) in the course of our dealings.   
                                                    </p>

                                                    <p class="mb-0">This includes:</p>
                                                    <ol type="a">
                                                        <li>all users of Litemore's applications, software, databases, websites, social media platforms and 
                                                            all other resources;</li>
                                                        <li> third  parties  associated with the users of Litemore's applications, software, databases, 
                                                            websites, social media platforms and all other resources; </li>
                                                        <li>Litemore's associated parties such as trustees, implementing partners, vendors, contractors, 
                                                            its regional branches and any other third party who handle and use Litemore's information; 
                                                            and</li>
                                                            <li>members of the public.  </li>
                                                      </ol>

                                                    <p>
                                                        It is important that you read this Privacy Policy carefully and understand how we intend to use your 
                                                        personal data. This Privacy Policy will inform you on how we collect, use, disclose, transfer and store 
                                                        your  personal data when you  interact  with us. It  will  also  inform  you  about  your  privacy  rights  and 
                                                        how the law protects you in accordance with the provisions of the Data Protection Act, 2019 (“the 
                                                        Act”).
                                                    </p>

                                                    <h4>  Who is responsible for your personal data and how to contact them?</h4>
                                                    <p>
                                                        Litemore is the data controller in relation to the processing activities described below. This means that 
                                                        we determine the purpose and means of processing your personal data.  
                                                        If you have any concerns about the use of your personal data or questions about this Privacy  Policy 
                                                        including any requests to exercise your legal rights under the law, complaints, please contact our Data 
                                                        Protection Officer using the details set out below: <br>
                                                        Email address: info@litemore.co.ke   <br>
                                                        Postal address:  P.O BOX 51235-0100   <br>
                                                        Physical address: Brookside Grove Westlands  <br>
                                                        Telephone number: +254 731533461
                                                    </p>

                                                    <h4> What types of personal data do we process?</h4>
                                                    <p>
                                                        When used in this Privacy Policy, the term “personal data” means any information that can be used 
                                                        to  identify  an  individual  natural  person.  Please  note  that  there  are  "special  categories"  of  personal data that are more sensitive and require a higher level of protection. The personal data we collect will 
                                                        vary according to the circumstances surrounding our relationship with you.  
                                                        We  may  collect,  use,  store  and  transfer  the  following  types  of  personal  data  about  you  or  persons 
                                                        connected to you:
                                                    </p>
                                                    <ol type="a">
                                                        <li><b> Personal Details</b>(such as your name, national identity card number or passport number, date 
                                                            of  birth, gender,  the  Kenya Revenue  Authority  (KRA)  PIN,  nationality,  title,  children’s name, 
                                                            name of the school, name of the teachers in the respective schools; </li>
                                                        <li><b>Contact Information</b>(such as email address, telephone number and postal address); and</li>
                                                        <li><b>  Information about your interactions with Litemore</b>(such as when you use our applications, 
                                                            software, databases,  websites,  social  media platforms and  other resources; when  you 
                                                            communicate with us for example through emails or calls which we might record and monitor 
                                                            or when you participate in our programs).</li>
                                                    </ol>

                                                    <p>
                                                        Should we require information about other people connected to you, we may request you to provide 
                                                        such information.  If  you are  providing  information  about  another  person,  we  expect  you  to  ensure 
                                                        that they are aware of your actions and consent to the disclosure by you of their information to us.
                                                    </p>

                                                    <h5>What happens if you fail to provide the requested personal data?</h5>
                                                    <p>
                                                        If you do  not provide  us with the  requested personal data, we may not be able to  provide you with 
                                                        the services you require. In some instances, we may be forced to cancel a service you currently have 
                                                        with us but we will notify you if this is the case at that time.  
                                                        Third parties that we contract with are also obliged to provide personal data to us. Failure to provide 
                                                        this personal data may result in the termination of your contract/relationship with us; however, we 
                                                        will notify you within reasonable time and according to the terms of your contract if this is the case. 
                                                    </p>

                                                    <h4>How do we collect your personal data? </h4>
                                                    <p>We may collect or receive your personal data in a number of different ways:</p>
                                                    <ul>
                                                        <li>Where you provide the personal data directly to us, for example by: 
                                                            <ol type="a">
                                                                <li>corresponding with us by phone or email; </li>
                                                                <li>using  or  registering  to  use  our  applications,  software,  databases,  websites,  social 
                                                                    media platforms and other resources; </li>
                                                                <li>entering into a contract with us. </li>
                                                            </ol>
                                                        </li>
                                                        <li>From third parties, such as people who work on our behalf and our associated partners; and</li>
                                                        <li>From  publicly  available  sources  including  but  not  limited  to  internet  search  engines,  public 
                                                            records and registers and social media accounts (e.g., Facebook, LinkedIn and Instagram).   </li>
                                                    </ul>

                                                    <p>
                                                        Generally, you have no obligation to provide us with your personal data, but if you do not provide us 
                                                        with the information we need, we may be unable to assist and work with you. 
                                                        We will seek to minimise the amount of information we request for, to only that which is needed to 
                                                        perform the relevant function or service at the time. 
                                                    </p>

                                                    <h4>Cookies</h4>
                                                    <p>Please note that in order to improve our internet service to you, we will occasionally use a “cookie” 
                                                        and/or other similar technologies which may place certain information on your computer's hard drive 
                                                        when you visit our website or any of Litemore's online platforms.  
                                                        A  cookie  is  a  small  amount  of  data  that  our  web  server  sends  to  your  web  browser  when  you  visit 
                                                        certain parts of our site.  
                                                        We  use  cookies  to  do  many  different  jobs,  like  letting  you  navigate  between  pages  efficiently, 
                                                        identifying  you  after  you  have  logged  in  by  storing  a  temporary  reference  number  in  the  cookie, 
                                                        allowing you to access stored information if you register for any of our online platforms, and generally 
                                                        improving your online experience. </p>

                                                    <p>

                                                    <h4>Why do we process your personal data?</h4>
                                                    <p>We will process your personal data for the following purposes:</p>
                                                    <ol type="a">
                                                        <li> to  provide  products  and  services  to  our  clients  and  to  communicate  with  you  and/or  our 
                                                            clients about them;  </li>
                                                        <li>to  manage,  administer  and  improve  our  business,  client  and  service  provider  engagements 
                                                            and relationships and for corporate marketing, business development, analysis and 
                                                            operational purposes;  </li>
                                                        <li> to  monitor  and  analyze  the  use  of  our  products  and  services  for  system  administration, 
                                                            operation, testing and support purposes;</li>
                                                        <li>to manage our information technology and to ensure the security of our systems and premises;</li>
                                                        <li>to establish, exercise and/or defend legal claims or rights and to protect, exercise and enforce 
                                                            our rights, property or safety, or to assist our clients or others to do this; </li>
                                                        <li>to  investigate  and  respond  to  complaints  or  incidents  relating  to  us  or  our  business,  to 
                                                            maintain service quality and to train staff to deal with complaints and disputes; </li>
                                                        <li> to cooperate with, respond to requests from, and to report transactions and/or other activity 
                                                            to, government, tax or regulatory bodies, courts or other third parties;  </li>
                                                        <li>for any additional purposes expressly authorized by you; and </li>
                                                        <li>to comply with applicable laws and regulations. </li>
                                                    </ol>

                                                    <h4>What legal basis do we rely on for processing your personal data? </h4>
                                                    <p>We will rely on the following lawful bases for processing your personal data:</p>
                                                    <ol type="a">
                                                        <li><b> Legitimate  interests: </b>We  will  process  your  personal  data  to  pursue  our  legitimate  business 
                                                            and other interests or of a third party, except if the processing is unwarranted in any particular 
                                                            case having regard to the harm and prejudice to your rights, freedoms or legitimate interests.</li>
                                                        <li><b>Legal  and  regulatory  obligations: </b>We  will  process  your  personal  data  for  the  purpose  of 
                                                            complying  with  a  legal  or  regulatory  obligation  such  as  when  it  is  necessary  to  pursue  our legitimate  interests  in  cooperating  with  our  regulators  and  other  government  authorities, 
                                                            complying with foreign laws and protecting our businesses.</li>
                                                        <li><b> Contractual performance: </b>We will process your personal data to perform our obligations with 
                                                            respect to the contract we have with you or to take steps, prior to entering into such a contract 
                                                            with you.</li>
                                                        <li><b> Consent: </b>We will rely on your data as a lawful basis for processing personal data relating to a 
                                                            child, processing sensitive personal data outside Kenya, and processing your personal data for 
                                                            the purpose of direct marketing to you.  
                                                            Where you have provided your consent to the processing of your personal data for a specific 
                                                            purpose, you have the right to withdraw your consent for that specific processing at any time. 
                                                            Please  note  that  by  withdrawing  your  consent,  the  withdrawal  will  not  render  unlawful  our 
                                                            prior processing of your personal data or the processing which is based on other legal bases 
                                                            for processing of your personal data. </li>
                                                        <li><b>Vital interests: </b>We will process your personal data to protect your vital interests or another 
                                                            natural person.</li>
                                                        <li><b>Public interest: </b>We will also process your personal data for the performance of a task carried 
                                                            out in the public interest.</li>
                                                    </ol>

                                                    <h4> To whom do we disclose your personal data? </h4>
                                                    <p>Litemore  will  confidentially  and  only  to  the  extent  reasonably  required  and  permitted,  under 
                                                        applicable  data protection and bank secrecy  laws,  share  personal data about  you  and your  account 
                                                        with: 
                                                    </p>
                                                    <ol type="a">
                                                        <li>third  parties such  as external  accountants, external legal teams, regulators and  other 
                                                            professional bodies;</li>
                                                        <li>third parties to establish, exercise or defend our legal rights;</li>
                                                        <li>public  authorities  or  governments  when  required  by  law,  public  interest,  national  security, 
                                                            regulation, legal process or enforceable governmental request; </li>
                                                        <li>our external service providers who  we contract with for  various functions, including but not 
                                                            limited  to,  our  IT  and  office  systems;  administrative  services  providers;  universities  and 
                                                            research organizations (who may contact you to gather information relating to our products 
                                                            and services). We will only disclose personal data to our external service providers when it is 
                                                            essential for them to provide their service and we have a contract in place that requires them 
                                                            to keep your information secure and not to use it other than in accordance with our specific 
                                                            instructions; and</li>
                                                        <li>authorised third parties.  </li>
                                                    </ol>
                                                    <p>Please note that if you request us, in writing, to share your personal data with third parties,  we will 
                                                        follow  your  request  to  share  the  relevant  information.  However,  we  do  not  have  control  over  how 
                                                        those third parties will use your information. Before you make your request, we recommend that you 
                                                        (or  the  person  acting  on  your  behalf)  consider  the  data  protection  practices  of  that  third  party  by 
                                                        reading their privacy policies or contacting them.
                                                    </p>

                                                    <h4>Where do we transfer your personal data? </h4>
                                                    <p>We  may  transfer  your  personal  data  to  regulatory,  prosecuting,  tax  and  governmental  authorities, 
                                                        courts and other tribunals,  service providers and other  business counterparties located in countries 
                                                        outside Kenya including countries which have different data protection standards to those which apply 
                                                        in Kenya. This includes transfers of personal data to the United States of America, among other African 
                                                        countries. When we transfer personal data to entities in these countries, we will take reasonable steps 
                                                        to ensure that they protect your personal data in accordance with appropriate safeguards and other 
                                                        requirements of the Act.  
                                                        In  order  to  mitigate  risks  associated  with  transfer  of  data  to  third  parties,  the  company  will  only 
                                                        transfer data to a third party if:
                                                    </p>
                                                    <ol type="a">
                                                        <li>the data is stripped off personal and identifiable information where appropriate; </li>
                                                        <li>we have a lawful basis for such transfer;</li>
                                                        <li>the third party maintains a high level of data security that protect personal data against the 
                                                            risk of accidental or unlawful/illegitimate destruction, loss, alteration unauthorized disclosure 
                                                            of, or access to it.
                                                        </li>
                                                    </ol>

                                                    <h4>Data Security</h4>
                                                    <p>We  will  take  and  implement  appropriate  technical  and  organisational  security  procedures  and 
                                                        measures to protect the security and confidentiality of  personal data and prevent the unauthorised 
                                                        or unlawful processing of personal data and accidental loss or destruction of, or damage of personal 
                                                        data.  
                                                        We will implement the following measures: 
                                                    </p>
                                                    <ol type="a">
                                                        <li> personal data will be filed and stored in a way that is accessible only to authorized staff and 
                                                            transferred only using protected means of communication; </li>
                                                        <li>our  staff  who  are  allowed  access  to  the  personal data shall  sign a nondisclosure  agreement 
                                                            banning them from using the content for business other than the company’s core mandate; 
                                                            and </li>
                                                        <li>  private email accounts shall not be used to transfer the personal data.  </li>
                                                    </ol>

                                                    <h4>How long do we keep your personal data?</h4>
                                                    <p>We will only retain your personal data for as long as may be reasonably necessary to fulfil the purpose 
                                                        for which it was collected, including to comply with any legal, regulatory, tax, accounting or reporting 
                                                        information requirements.  We may retain your personal data for a longer period if the retention is:
                                                    </p>
                                                    <ul>
                                                        <li>required or authorised by law; </li>
                                                        <li>reasonably necessary for a lawful purpose;</li>
                                                        <li>authorised or consented by you;  </li>
                                                        <li>for personal data that has been anonymised; or</li>
                                                        <li>for historical, statistical, journalistic, literature and art or research purposes. </li>
                                                    </ul>

                                                    <h4>What are your rights in relation to your personal data? </h4>
                                                    <p>You have a right to: </p>
                                                    <ol type="a">
                                                        <li>be informed of the use to which your personal data is to be put to as we have endeavoured 
                                                            to outline in this Privacy Policy. </li>
                                                        <li>access to your personal data and receive a copy of the personal data we hold about you.</li>
                                                        <li>object or restrict the processing of all or part of your personal data.</li>
                                                        <li>correction of any false or misleading personal data that we hold about you.</li>
                                                        <li>deletion of any false or misleading personal data that we hold about you. </li>
                                                    </ol>
                                                    <p>To exercise any of these rights, please write to our Data Protection Officer via the contact details given 
                                                        in Section 2 above.  
                                                    </p>

                                                    <h4>Other Terms and Conditions</h4>
                                                    <p>There  may  be  specific  terms  and  conditions  that  govern  the  collection,  use  and  disclosure  of  your 
                                                        personal  data.  Please  note  that  such  terms  and  conditions  must  be  read  in  conjunction  with  this 
                                                        Privacy Policy. 
                                                    </p>

                                                    <h4>Changes to this Privacy Policy</h4>
                                                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting 
                                                        the new Privacy Policy on this page.  
                                                         
                                                        We will let you know via email and/or a prominent notice on Our Service prior to the change 
                                                        becoming effective and update the last updated date at the top of this Privacy Policy. 
                                                         
                                                        You are advised to review this Privacy policy periodically for any changes. Changes to this policy are 
                                                        effective when they are posted on this page.
                                                    </p>
                                                    </div>
                                                    <div class="modal-footer">
                                                    <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
                                                        Close
                                                    </button>
                                                    </div>
                                                </ng-template>
                                                <!-- / Modal -->
