export interface Examination {
  examId                    : number;
  adminAccessibilityDate    : number;
  nonAdminAccessibilityDate : number;
  title                     : string;
  status                    : number;
  createdBy                 : number;
  schoolId                  : number;
  form                      : number;
  subject                   : string;
  questionPdfUrl            : string;
  questionWordUrl           : string;
  markPdfUrl                : string;
  markWordUrl               : string;
}