<div class="payment-instruction">
    <!-- <img class="img-fluid" style="height: 23rem;"
         [src]="coreConfig.layout.skin === 'dark' ? 'assets/images/payments/instructions.jpg' : 'assets/images/payments/instructions.jpg' "
         alt="Error page" /> -->
        <div class="row">
            <div class="col-sm-10 col-md-10 col-xl-7 mx-auto">
                <div class="card mt-2">
                    <div class="card-body">
                            <h3 class="bold">Send Instructions</h3><br>
                            <p class="font">Do you want to pay {{(paymentInfo.plan.hasDiscount ? paymentInfo.plan.discountedAmount : paymentInfo.plan.amount)  | currency:'KES '}} to Litemore-Limited<br>
                            Account no. 4635980017?<br><br>
                            ENTER M-PESA PIN:</p>
                            <hr class="mpesa-pin-line">
                            <div class="flex">
                                <p class="action-buttons ml-2">Cancel</p>
                                <p class="action-buttons mr-2">Send</p>
                            </div>
                    </div>
                </div>
                
            </div>
        </div>
   

    <p class="mb-2 fs-sm-16" style="
                    color: #555555;
                    ">A prompt has been sent to your phone number,the confirmation will display as shown above.</p>
</div>
<div class="row">

    <div class="form-group text-left" style="text-align: left">

<!--        <a class="btn btn-outline-success" (click)="loadHomepage()">Cancel-->
<!--            &lt;!&ndash; <i data-feather="arrow-right" class="cursor-pointer"></i> &ndash;&gt;-->
<!--        </a>-->
        <a href=""></a>
    </div>
</div>