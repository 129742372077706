export interface Response{
  updatedOn: string;
  score: string;
  student: {
    form: string;
    name: string;
    admNo: string;
  };
  responseId: number;
  canSubmitAssignment: boolean;
  canSubmitMarks: boolean;
  text: string;
  remarks: string;
  responseMedia: Array<
    {
      mediaId: number;
      url: string;
      status: number;
    }
  >
}