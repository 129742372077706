import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  @Input() coreConfig:any;
  @Input() planName:any;
  @Input() expiryDate:any;
  constructor() { }

  ngOnInit(): void {
  }

  getPlanName(){
    return this.planName;
  }

  getExpiryDate(){
    return this.expiryDate;
  }
}
