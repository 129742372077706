import {
    EventEmitter, Injectable
} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from 'environments/environment';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable({providedIn: 'root'})
export class PaymentService {
    url: string

    paymentEvent = new EventEmitter();
    subsVar: Subscription;
    currentUserSubscription: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private _http: HttpClient, private _modalService: NgbModal) {
        this.url = `${environment.baseUrl}`;
    }

    getFreemiumStatus(): Observable<any> {
        return this._http.get(`${this.url}/freemium/weekly`);
    }
    /**
     * Get payment by form and subject
     */
    // getTokenId(tokenId: string): Observable<any> {
    //     const url = `${this.url}/token/:tokenId`;
    //     return this._http.get<any>(url, { params: { tokenId } });
    //   }     
    getTokenId(tokenId: string): Observable<any> {
        const url = `${this.url}/token/${tokenId}`;
        return this._http.post<any>(url, {});
      } 

    getPlans(): Observable<any> {
        return this._http.get<any>(`${this.url}/subscription/plan`);
    }

    addSubscription(subscription: any): Observable<any> {
        return this._http.post<any>(`${this.url}/subscription`, subscription);
    }

    getSubscription(): Observable<any> {
        return this._http.get<any>(`${this.url}/subscription`);
    }

    updatePrivacyPolicyStatus(): Observable<any> {
        return this._http.put<any>(`${this.url}/user`, {});
    }

    checkCurrentUserSubscription(): Observable<any> {
        return this._http.get<any>(`${this.url}/subscription`);
    }

    updatePaymentEvt() {
        this.paymentEvent.emit();
    }

    getSubscriptionType(subscribedAt: any, expiresAt: any) {
        let days = Math.floor((expiresAt - subscribedAt) / (24 * 3600 * 1000));
        switch (days) {
            case 1 :
                return "Daily";
            case 7 :
                return "Weekly";
            default :
                return "Monthly";
        }
    }

    getSubscriptionDays(expiresAt: any) {
        return Math.floor((expiresAt - Date.parse(new Date().toDateString())) / (24 * 3600 * 1000));
    }

}
