import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export interface CustomerSupportUser{
  name:string,
  region:string,
  phone:string
}

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss']
})

export class PaymentErrorComponent implements OnInit {

  @Input() coreConfig:any;

  @Output() loadHomepageEvt:EventEmitter<any> = new EventEmitter<any>();
  @Output() addSubscriptionEvt:EventEmitter<any> = new EventEmitter<any>();
  contactNumbers:Array<CustomerSupportUser> = [
    {
      name:"Zeraki Office",
      region:"Main Phone nmber",
      phone:"0790 493495"
    }
    ,{
    name:"",
      phone:"0798 666333",
      region:""
    },{
    name:"",
      phone:"0798 666333",
      region:""
    },{
    name:"",
      phone:"0798 666333",
      region:""
    },{
    name:"",
      phone:"0798 666000",
      region:""
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

  loadHomepage(){
    this.loadHomepageEvt.emit();
  }

  addSubscription(){
    this.addSubscriptionEvt.emit();
  }

  getPhoneNumber():string{
    return this.contactNumbers[(Math.floor(Math.random() * (this.contactNumbers.length-1)))].phone;
  }

}
