<div class="text-center">
    <img class="img-fluid" style="padding: 5em; height: 24rem;"
         [src]="coreConfig.layout.skin === 'dark' ? 'assets/images/payments/iphone_spinner.svg' : 'assets/images/payments/iphone_spinner.svg' "
         alt="Error page" />
    <p class="mb-2 fs-sm-16" style="
                    font-style: normal;
                    color: #555555;
                    ">Hold on a moment as we confirm your payment.</p>
    <div class="form-group " style="display:flex;justify-content: space-between">

<!--        <a (click)="loadHomepage()" class="btn btn-outline-success">Cancel-->
            <!-- <i data-feather="arrow-right" class="cursor-pointer"></i> -->

<!--        </a>-->
    </div>
</div>


