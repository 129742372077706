import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubjectService } from '@core/services/subject.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-question-selection-form',
  templateUrl: './question-selection-form.component.html',
  styleUrls: ['./question-selection-form.component.scss']
})
export class QuestionSelectionFormComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  filterForm: FormGroup;
  subjectList: any[] = [];
  submitted = false;
  isSearching = false;
  questionList: any;
  page: number = 1;
  pageSize: number = 10;
  currentQuestion: any;
  currentQuestionIndex: number = 0;
  currentQuestionId: number;




  constructor(private subjectService: SubjectService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.getSubjects();
    this.initForm();
  }

  getSubjects(): void {
    this.subjectService.getSubjectsWithContent().subscribe(
      (res) => {
        this.subjectList = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  initForm() {
    this.filterForm = this.formBuilder.group({
      form: ["", [Validators.required]],
      subject: ["", [Validators.required]],
      topic: ["", [Validators.required]],
    });
  }

  get f() {
    return this.filterForm.controls;
  }

  findQuestions() {
    this.submitted = true;
    if (!this.filterForm.value.form || !this.filterForm.value.subject) return;

    this.isSearching = true;
    this.page = 1;
    this.currentQuestionIndex = 0;
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next(true);
      this.unsubscribe$.unsubscribe();
  }

}
