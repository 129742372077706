<div *ngIf="isLoading; then showLoading else showContent"></div>
<ng-template #showLoading>
    <div class="card text-center">
        <div class="card-body" style="min-height: 150px">
            <div style="margin-top: 45px" class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #showContent>
    <div class="row">
        <div class="col-12">
            <div *ngIf="!alertClose && submitted ">
                <ngb-alert [type]="'danger'" (closed)="alertClose = !alertClose">
                    <div class="alert-body">
                        Click on a subscription plan to select it.
                        <button type="button" class="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </ngb-alert>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="upgrade">
                <div *ngFor="let plan of plans; let index = index">
    
                    <div class="card  mb-0" [ngClass]="{'border-danger':!alertClose && submitted,'border-secondary': alertClose,'border-success':index == selectedPlan}">
                        <div class="card-body p-sm-10">
                            <div class="mother-container flex">
                                <!--                   <div style="padding-top: 45px">-->
                                <input style="flex-grow: 1" type="radio" id="customRadio_{{plan.planId}}"
                                       [value]="index" (click)="setSelectedPlan(index)" name="customRadio"
                                       [checked]="selectedPlan == index" style="scale: 1.6"/>
                                <!--                   </div>-->
                                <div class="text-container">
                                    <label style="cursor: pointer; width: 100%" for="customRadio_{{plan.planId}}">
                                        <ng-container>
                                            <h3 class="fs-sm-16">{{plan.planName}}</h3>
                                            <div class="fs-sm-16  mx-auto col-10 border-2 border-primary text-primary"
                                            >
                                                <!--                                            style="padding-top: 5px;padding-bottom: 5px; font-weight: bolder"-->
                                                KES {{plan.discountedAmount |currency:'':''}}
                                            </div>
                                        </ng-container>
                                    </label>
                                </div>
                            </div>
                            <div class="" style="min-height: 21px">
                                <label  for="customRadio_{{plan.planId}}" class="text-center mb-0 fs-sm-16" style="display: block; cursor: pointer" [hidden]="!plan.hasDiscount">
                                    Discounted from
                                    <b style="margin-right: 5px">KES</b>
                                    <s>{{plan.amount| currency :'':''}} </s>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form [formGroup]="phoneForm" class="fs-sm-16" (ngSubmit)="submitPhoneNumber()">
        <div class="row form-group">
            <div class="col-md-4">
                <label class="form-label fs-sm-16">Payment Source </label>
            </div>

            <div class="col-md-8">
                <select class="form-control fs-sm-16">
                    <option value="null" disabled selected>M-PESA</option>
                </select>
            </div>
        </div>

        <div class="row form-group">
            <div class=" col-md-4 fs-sm-16">
                <label class="form-label fs-sm-16">Phone Number </label>
            </div>
            <div class="col-md-8">
                <input  formControlName="phone"
                        type="text"
                        class="form-control fs-sm-16"
                        [ngClass]="{'error':submitted && f.phone.invalid}"
                        placeholder="Mpesa Number"
                        name="phone_number"/>
                <div class="text-danger" *ngIf="submitted && f.phone.errors">
                    <p class="mb-0" *ngIf="f.phone.errors?.required">This field is required</p>
                    <p class="mb-0" *ngIf="f.phone.errors?.minlength || f.phone.errors?.maxlength || f.phone.errors?.pattern">Provide valid phone number</p>
                </div>
            </div>
        </div>


        <div class="form-group mb-0" style="display: flex; justify-content: space-between">
            <!--                <span></span>-->
            <button (click)="loadHomepage()" class="btn btn-outline-danger fs-sm-16">Cancel
                <!--                &lt;!&ndash; <i data-feather="arrow-right" class="cursor-pointer"></i> &ndash;&gt;-->

            </button>
            <button
                    class="btn btn-primary btn-small-padding fs-sm-16"> Make Payment
                <!--                <i data-feather="arrow-right" class="cursor-pointer"></i>-->
            </button>
        </div>

    </form>
</ng-template>

<!--<form class="row" [formGroup]="planForm" (ngSubmit)="submitPlan()">-->
<!--    <div class="col-sm-12">-->
<!--        <div class="form-group">-->
<!--            <label for="plan">Select Subscription Plan</label>-->
<!--            <select formControlName="plan" id="plan" class="form-control"-->
<!--                    [ngClass]="{'is-invalid':submitted && f.plan.invalid}">-->
<!--                <option value="">Select Plan</option>-->
<!--                <option *ngFor="let plan of plans;index as index" [value]="index"> {{plan.planName}}</option>-->
<!--            </select>-->
<!--            <div class="text-danger" *ngIf="submitted && f.plan.invalid">-->
<!--                <p class="mb-0" *ngIf="f.plan?.errors?.required">Select a subscription plan</p>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="form-group d-flex justify-content-between">-->
<!--            <button class="btn btn-outline-primary waves-effect waves-light" type="button" (click)="loadHomepage()">-->
<!--                Cancel-->
<!--            </button>-->
<!--            <button class="btn btn-primary waves-effect waves-light" type="submit">Next <i-->
<!--                    class="fa fa-arrow-right"></i></button>-->
<!--        </div>-->
<!--    </div>-->
<!--</form>-->

