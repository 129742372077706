<div class="card mb-0">
  <div class="card-body">
    <ng-container *ngIf="isRemovingSubjects && subjects && subjects.length">
      <h6 class="card-title text-center">Unselect Subjects you wish to drop</h6>
      <div class="row">
        <div class="col-6 mb-2" *ngFor="let subject of subjects">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="{{ 'customCheck' + subject.subjectId }}"
              (click)="selectSubject(subject)"
              [(ngModel)]="subject.selected"
              [checked]="subject.selected"
            />
            <label
              class="custom-control-label"
              for="{{ 'customCheck' + subject.subjectId }}"
              >{{ subject.name }}</label
            >
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <button
          class="btn btn-primary btn-sm"
          (click)="saveChanges()"
          [disabled]="!this.selectedSubjects.length"
        >
          Save Changes
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="isAddingSubjects && subjects && subjects.length">
        <h6 class="card-title text-center">Select more subjects you wish to study</h6>
        <div class="row">
          <div class="col-6 mb-2" *ngFor="let subject of subjects">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="{{ 'customCheck' + subject.subjectId }}"
                (click)="selectSubject(subject)"
                [(ngModel)]="subject.selected"
              />
              <label
                class="custom-control-label"
                for="{{ 'customCheck' + subject.subjectId }}"
                >{{ subject.name }}</label
              >
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <button
            class="btn btn-primary btn-sm"
            (click)="saveChanges()"
            [disabled]="!this.selectedSubjects.length"
          >
            Save Changes
          </button>
        </div>
      </ng-container>
    <ng-container *ngIf="loadingSubjects">
      <app-loader [height]="150"></app-loader>
    </ng-container>
  </div>
</div>
