import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSubjects'
})
export class FilterSubjectsPipe implements PipeTransform {

  transform(subjects: any[], subjectNames: string[]): any[] {
    if (!subjects) {
      return [];
    }
    if (!subjectNames) {
      return subjects;
    }

    return subjects.filter(subject => {
      return subjectNames.includes(subject.name);
    });
  }
}
