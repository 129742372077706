<div class="card" [style.min-height.em]=height>
  <div class="card-body">
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="quiz-heading">Quiz Performance</h5>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center mr-3">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="6" cy="6" r="6" fill="#46AF4D" />
          </svg>
          <h6 class="p-0 m-0">Great</h6>
        </div>
        <div class="d-flex align-items-center">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="6" cy="6" r="6" fill="#BD270A" />
          </svg>
          <h6 class="p-0 m-0">Work Hard</h6>
        </div>
      </div>
    </div>
    <hr />
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="form-group">
        <label for="basicSelect">Subject</label>
        <select
          id="basicSelect"
          class="form-control"
          [(ngModel)]="subjectId"
          (change)="getQuizPerformance()"
        >
          <ng-container *ngIf="subjects?.length; else loadingSubjects">
            <option
              *ngFor="let subject of subjects"
              [value]="subject.subjectId"
            >
              {{ subject.name }}
            </option>
          </ng-container>
          <ng-template #loadingSubjects>
            <option disabled>Loading...</option>
          </ng-template>
        </select>
      </div>
      <ng-container *ngIf="(quizPerformance$ | async) as quizPerformance">
        <div class="d-flex align-items-center justify-content-between">
          <div class="scores mr-2">
            <p class="text-center mb-0 score">
              {{ quizPerformance.averageScore }}/{{
                quizPerformance.averageTotal
              }}
            </p>
            <p class="score-text">Average Score</p>
          </div>
          <div class="scores">
            <p class="text-center mb-0 score">
              {{ quizPerformance.bestScore ? quizPerformance.bestScore : '-' }}/{{ quizPerformance.bestTotal ? quizPerformance.bestTotal : '-'}}
            </p>
            <p class="score-text">Best Score</p>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- ngx-datatables responsive -->
    <ng-container
      *ngIf="
        (quizPerformance$ | async) as quizPerformance;
        else loadingQuizPerformance
      "
    >
      <ngx-datatable
        class="bootstrap core-bootstrap"
        [columnMode]="ColumnMode.force"
        [headerHeight]="40"
        [footerHeight]="50"
        [rowHeight]="58"
        [limit]="10"
        [rows]="quizPerformance.accesses"
        [scrollbarH]="true"
      >
        <ngx-datatable-column name="Date" [width]="150">
          <ng-template ngx-datatable-cell-template let-row="row">
            {{ row.date | dateTransform }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Topic" [width]="350">
          <ng-template ngx-datatable-cell-template let-row="row">
            {{ row.topic }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Score" [width]="150">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="d-flex align-items-center">
              {{ row.correct }} / {{ row.attempts }}
              <svg
                *ngIf="row.correct >= row.attempts / 2; else workHardSvg"
                class="score-svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill="#46AF4D" />
              </svg>
              <ng-template #workHardSvg>
                <svg
                  class="score-svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="6" cy="6" r="6" fill="#BD270A" />
                </svg>
              </ng-template>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </ng-container>
    <ng-template #loadingQuizPerformance>
      <app-loader [height]="250"></app-loader>
    </ng-template>
    <!--/ ngx-datatables responsive -->
  </div>
</div>
