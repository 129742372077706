import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '@core/services/auth';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if ([401, 403].includes(error.status)) {
          // If the error is due to an unauthorized request
          if (error.status === 401) {
            const refreshToken = this.authService.refreshToken();

            // Check if there's a refresh token available
            if (refreshToken) {
              // Attempt to refresh the access token
              return this.authService
                .refreshToken()
                .pipe(
                  first(),
                  catchError((refreshError) => {
                    // If refresh token is invalid/expired, log the user out
                    if ([401, 403].includes(refreshError.status)) {
                      this.authService.logout();
                    }
                    return throwError(refreshError);
                  })
                )
                .pipe(
                  switchMap((tokens) => {
                    if (tokens) {
                      // Save the new tokens
                      this.authService.setTokens(tokens);
                      return next.handle(request);
                    } else {
                      // No tokens received, so log the user out
                      this.authService.logout();
                      return throwError(error);
                    }
                  })
                );
            } else {
              // No refresh token available, so log the user out
              this.authService.logout();
            }
          }

          // If the error is due to a forbidden request, navigate to the not-authorized page
          if (error.status === 403) {
            this.router.navigate(['/pages/miscellaneous/not-authorized']);
          }
        }

        // Pass any other errors through
        return throwError(error);
      })
    );
  }
}
