import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { QuizAccess } from "@core/models/quiz-access";
import { DependentService } from "@core/services/dependent.service";
import { SubjectService } from "@core/services/subject.service";
import { Observable, of } from "rxjs";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Subject } from "@core/models";
import { QuizService } from "@core/services/quiz.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-quiz-performance",
  templateUrl: "./quiz-performance.component.html",
  styleUrls: ["./quiz-performance.component.scss"],
})
export class QuizPerformanceComponent implements OnInit {
  studentId: number;
  @Input() form: number;
  @Input() isStudent: boolean;
  @Input() isParent: boolean;
  @Input() height: number;
  ColumnMode: any = ColumnMode;
  subjectId: number;
  quizPerformance$?: Observable<QuizAccess[]>;
  subjects$?: Observable<unknown>;
  subjects: Subject[];

  constructor(
    private dependentService: DependentService,
    private subjectService: SubjectService,
    private quizService: QuizService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getStudentsInfo();
  }

  getStudentsInfo(): void {
    if (this.isParent) {
      this.route.params.subscribe((params) => {
        (this.studentId = params.id), (this.form = params.form);
        if (this.form > 4) {
          this.form = 4;
        }
        this.getAllSbujects();
      });
    } else {
      this.getAllSbujects();
    }
  }

  getAllSbujects(): void {
    this.subjectService.getAll().subscribe(
      (res) => {
        this.subjects = res;
        this.subjectId = this.subjects[0].subjectId;
        this.getQuizPerformance();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getQuizPerformance(): void {
    if (this.isStudent) {
      this.quizPerformance$ = this.quizService.getQuizPerformance(
        this.form,
        this.subjectId
      );
      return;
    }
    if (this.isParent) {
      this.quizPerformance$ = this.dependentService.getQuizPerformance(
        this.studentId,
        this.form,
        this.subjectId
      );
    }
  }
}
