import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Role } from '@core/models';

import { AuthenticationService } from '@core/services/auth';
import { HelperFunctionsService } from '@core/services/helper-functions.service';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _helperFunctions: HelperFunctionsService
  ) { }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;

    if (currentUser) {
      const path = '/' + route.pathFromRoot
        .filter(v => v.routeConfig)
        .map(v => v.routeConfig!.path)
        .join('/');

      if (path.includes('assignments')) {
        this._helperFunctions.navigateToAssignmentsView(currentUser);

      } else {
        this._helperFunctions.navigateToUsersDashboard(currentUser);
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
