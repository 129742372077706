import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { CarouselModule } from "primeng/carousel";

import { UpgradePageComponent } from "./upgrade-page.component";
import { ProvideSubscriptionPlanComponent } from "./components/provide-subscription-plan/provide-subscription-plan.component";
import { PaymentInstructionComponent } from "./components/payment-instruction/payment-instruction.component";
import { PaymentSuccessComponent } from "./components/payment-success/payment-success.component";
import { PaymentErrorComponent } from "./components/payment-error/payment-error.component";
import { PaymentVerifyComponent } from "./components/payment-verify/payment-verify.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatStepperModule } from "@angular/material/stepper";
import { PaymentSuccessMobileComponent } from "./components/payment-success-mobile/payment-success-mobile.component";

// routing
const routes: Routes = [
  {
    path: "upgrade",
    component: UpgradePageComponent,
    data: { animation: "misc" },
  },
];

@NgModule({
  declarations: [
    UpgradePageComponent,
    ProvideSubscriptionPlanComponent,
    PaymentInstructionComponent,
    PaymentSuccessComponent,
    PaymentVerifyComponent,
    PaymentErrorComponent,
    PaymentSuccessMobileComponent,
  ],
  exports: [UpgradePageComponent],
  imports: [
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MatStepperModule,
  ],
})
export class PaymentsModule {}
