import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "environments/environment";
import { RequestParams } from "./interfaces/topicDetail";
import Swal from "sweetalert2";
import { Topic } from "@core/models/topic";
import { LessonAccess } from "@core/models/lessonAccessSummary";
import { FormBuilder, FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class LearnService {
  private upgradeEventSubject: Subject<void> = new Subject<void>();

  private topicId = new BehaviorSubject<number>(null);
  topicId$ = this.topicId.asObservable();

  private navbarFormTopicId = new BehaviorSubject<number>(null);
  navbarFormTopicId$ = this.navbarFormTopicId.asObservable();


  // studentProfile = this.fb.group({
  //   subjectId: [null],
  //   form: [null],
  //   topicId: [null],
  // });

  constructor(private http: HttpClient, private fb: FormBuilder) {}

  // updateStudentProfileContentFormValues(form: FormGroup) {
  //   this.studentProfile.patchValue(form.value)
  //   this.studentProfileContentForm.next(this.studentProfile)
  // }

  setDashboardTopicId(topicId: number): void {
    this.topicId.next(topicId);
  }

  resetDashboardTopicId(): void {
    this.topicId.next(null);
  }

  setNavTopicId(topicId: number): void {
    this.navbarFormTopicId.next(topicId);
  }

  resetNavTopicId() : void {
    this.navbarFormTopicId.next(null);
  }

  getSubjects(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/subject/content`).pipe(
      map((response) => response),
      catchError((e: any) => {
        throw new Error(e);
      })
    );
  }

  getSubjectsData(subjectId: number): Observable<any> {
    return this.http
      .get(
        `${environment.baseUrl}/subject/content/records?subjectId=${subjectId}`
      )
      .pipe(
        map((response) => response),
        catchError((e: any) => {
          throw new Error(e);
        })
      );
  }

  getForms(subjectId: number, form: number): Observable<any> {
    return this.http
      .get(
        `${environment.baseUrl}/subject/content/records?subjectId=${subjectId}&form=${form}`
      )
      .pipe(
        map((response) => response),
        catchError((e: any) => {
          throw new Error(e);
        })
      );
  }

  getTopicsWithLessons(subjectId: number, form: number): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}/lesson/topics?subjectId=${subjectId}&classLevel=${form}`
    );
  }

  getTopicsData(subjectId: number, classLevel: number): Observable<any> {
    const params = new HttpParams()
      .append("subjectId", subjectId.toString())
      .append("classLevel", classLevel.toString());

    return this.http.get<any>(`${environment.baseUrl}/topic/lesson`, {
      params,
    });
  }

  getTopics(subjectId: number, classLevel: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}/subject/content`).pipe(
      map((response) => response),
      catchError((e: any) => {
        throw new Error(e);
      })
    );
  }

  getTopicLessons(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/topic`).pipe(
      map((response) => response),
      catchError((e: any) => {
        throw new Error(e);
      })
    );
  }

  getLessonsData(topicId: number): Observable<any> {
    return this.http
      .get(`${environment.baseUrl}/topic?topicId=${topicId}`)
      .pipe(
        map((response) => response),
        catchError((e: any) => {
          throw new Error(e);
        })
      );
  }

  getPhetSimulations(topicId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}/simulation?topicId=${topicId}`
    );
  }

  getAllTopics(subjectId: number, form: number): Observable<Topic[]> {
    return this.http.get<Topic[]>(`${environment.baseUrl}/topic/lesson?subjectId=${subjectId}&classLevel=${form}`)
  }

  getTopicSummary(topicId: number): Observable<LessonAccess> {
    return this.http.get<LessonAccess>(`${environment.baseUrl}/summary?topicId=${topicId}`)
  }

  // getKcseData(topicId: number, classLevel: number) {
  //   return this.http.get<any>(
  //     `${environment.baseUrl}/practical/?topicId=${topicId}&classLevel=${classLevel}`
  //   );
  // }

  updateLessonTaken(lessonId: number, seconds: number) {
    return this.http.post<any>(
      `${environment.baseUrl}/lesson/record?=${lessonId}&seconds=${seconds}`,
      {}
    );
  }

  getKcseData(subjectId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}/practical/kcse?subjectId=${subjectId}`
    );
  }

  // logs
  logComplete(lessonId: number) {
    return this.http.post<any>(
      `${environment.baseUrl}/lesson/record?lessonId=${lessonId}`,
      {}
    );
  }

  logPartial(lessonId: number, seconds: number) {
    return this.http.post<any>(
      `${environment.baseUrl}/lesson/record?lessonId=${lessonId}&seconds=${seconds}`,
      {}
    );
  }

  logFreeAccess(payload: any) {
    return this.http.post<any>(
      `${environment.baseUrl}/freemium/weekly/lesson`,
      {}
    );
  }

  logPracticalPartial(practicalId: number, seconds: number) {
    return this.http.post<any>(
      `${environment.baseUrl}/practical/record?practicalId=${practicalId}&seconds=${seconds}`,
      {}
    );
  }

  logPracticalComplete(practicalId: number) {
    return this.http.post<any>(
      `${environment.baseUrl}/practical/record?practicalId=${practicalId}`,
      {}
    );
  }

  // test
  getLessons(topicId: number, lessonId?: number): Observable<any> {
    let url = `${environment.baseUrl}/lesson?topicId=${topicId}`
    if (lessonId !== null && lessonId !== undefined) {
      url += `&lessonId=${lessonId}`
    }
    return this.http.get<any>(url);
  }

  getPracticals(topicId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}/practical?topicId=${topicId}`
    );
  }

  getKCSEPracticalBySubject(subjectId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}/practical/kcse?subjectId=${subjectId}`
    );
  }

  getSubjectsWithKCSEPracticals(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/subject/kcse`)
  }

  // shared service
  // loadUpgradePage() {
  //   Swal.fire({
  //     title: "You are trying to access premium content",
  //     icon: "warning",
  //     showCancelButton: true,
  //     customClass: {
  //       confirmButton: "btn btn-primary",
  //       cancelButton: "btn btn-danger",
  //     },
  //     confirmButtonText: "Upgrade",
  //     reverseButtons: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.upgradeEventSubject.next();
  //     }
  //   });
  // }

  // getUpgradeEvent() {
  //   return this.upgradeEventSubject.asObservable();
  // }

  // logPracticalPartial(practicalId: number, seconds: number){
  //   return this.http.post<any>(`${environment.baseUrl}/practical/record?practicalId=${practicalId}&seconds=${seconds}`,{});
  // }

  // logPracticalComplete(practicalId: number){
  //   return this.http.post<any>(`${environment.baseUrl}/practical/record?practicalId=${practicalId}`,{});
  // }
}
