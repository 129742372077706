
<div class="content-wrapper container-xxl p-0 d-flex align-items-center justify-content-center" style="height: 100vh;">
  <div class="content-body">
    <div class="card mb-4 pb-4">
      <div class="card-overlay">
        <div class="logo-container">
          <img src="assets/images/logo/zeraki_logo.png" alt="Logo" class="overlay-logo" />
        </div>
      </div>
      <div class="card-body d-flex align-items-center">
        <h5 class="font-weight-bolder mb-0 mt-4">Please wait as you are being redirected</h5>
        <div class="spinner-border ml-1 mt-4" role="status"></div>
      </div>
    </div>
  </div>
</div>






