<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- account setting page -->
    <section id="page-account-settings" *ngIf="currentUserInfo">
      <div class="row nav-vertical d-flex">
        <div class="col-md-3 mb-2 mb-md-0">
          <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
            <li class="nav-item" ngbNavItem>
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-pill-general"
                data-toggle="pill"
                href="#account-vertical-general"
                aria-expanded="true"
              >
                <i data-feather="user" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">General</span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <!-- header media -->
                    <div class="media">
                      <a href="javascript:void(0);" class="mr-25">
                        <img [src]="data.accountSetting.general.avatar" class="rounded mr-50" alt="profile image" height="76" width="80" />
                      </a>
                      <!-- upload and reset button for user image -->
                      <!--div class="media-body mt-75 ml-1">
                        <button
                          (click)="file.click()"
                          for="account-upload"
                          class="btn btn-sm btn-primary mb-75 mr-75"
                          rippleEffect
                        >
                          Upload
                        </button>
                        <input
                          type="file"
                          #file
                          id="account-upload"
                          hidden
                          accept="image/*"
                          (change)="uploadImage($event)"
                        />
                        <button class="btn btn-sm btn-outline-secondary mb-75" rippleEffect>Reset</button>
                        <p>Allowed JPG, GIF or PNG. Max size of 800kB</p>
                      </div-->
                      <!--/ upload and reset button -->
                    </div>
                    <!--/ header media -->

                    <!-- form -->
                    <form 
                      class="validate-form mt-2"
                      [formGroup]="userInfoForm"
                      (ngSubmit)="onSubmit()">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-username">Username</label>
                            <input
                              type="text"
                              formControlName="username"
                              class="form-control"
                              id="account-username"
                              name="username"
                              [class.error]="submitted && f.username.invalid"
                              placeholder="Username"
                            />
                            <span *ngIf="submitted && f.usernamevalid" class="invalid-form">
                              <small class="form-text text-danger" *ngIf="f.username.errors.required">Username must be valid!</small>
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-name">Name</label>
                            <input
                              type="text"
                              formControlName="name"
                              class="form-control"
                              id="account-name"
                              [class.error]="submitted && f.name.invalid"
                              name="name"
                              placeholder="Enter Name"
                            />
                            <span *ngIf="submitted && f.name.invalid" class="invalid-form">
                              <small class="form-text text-danger" *ngIf="f.name.errors.required">Name must be valid!</small>
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-e-phone-number">Phone Number</label>
                            <input
                              type="text"
                              formControlName="phoneNumber"
                              class="form-control"
                              id="account-e-mail"
                              [class.error]="submitted && f.phoneNumber.invalid"
                              name="phoneNUmber"
                              placeholder="Enter Phone Number"
                            />
                            <span *ngIf="submitted && f.phoneNumber.invalid" class="invalid-form">
                              <small class="form-text text-danger" *ngIf="f.phoneNumber.errors.required">PhoneNumber must be valid!</small>
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-email">Email</label>
                            <input
                              type="text"
                              formControlName="email"
                              [class.error]="submitted && f.email.invalid"
                              class="form-control"
                              id="account-email"
                              name="email"
                              placeholder="Email Address"
                            />
                            <span *ngIf="submitted && f.email.invalid" class="invalid-form">
                              <small class="form-text text-danger" *ngIf="f.email.errors.required">Email must be valid!</small>
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6" *ngIf="isIndependentStudent || isStudent">
                          <div class="form-group">
                            <label for="account-form">Form</label>
                            <input
                              type="number"
                              formControlName="form"
                              class="form-control"
                              id="account-form"
                              [class.error]="submitted && f.form.invalid"
                              name="form"
                              placeholder="Enter Form"
                            />
                            <span *ngIf="submitted && f.form.invalid" class="invalid-form">
                              <small class="form-text text-danger" *ngIf="f.form.errors.required">Form must be valid!</small>
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6" *ngIf="isStudent">
                          <div class="form-group">
                            <label for="account-Stream">Stream</label>
                            <input
                              type="text"
                              formControlName="streamName"
                              class="form-control"
                              id="account-Stream"
                              name="Stream"
                              [class.error]="submitted && f.streamName.invalid"
                              placeholder="Enter Stream"
                            />
                            <span *ngIf="submitted && f.streamName.invalid" class="invalid-form">
                              <small class="form-text text-danger" *ngIf="f.streamName.errors.required">StreamName must be valid!</small>
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6" *ngIf="isStudent">
                          <div class="form-group">
                            <label for="account-school">School</label>
                            <input
                              type="text"
                              formControlName="school"
                              class="form-control"
                              id="account-school"
                              [class.error]="submitted && f.school.invalid"
                              name="school"
                              placeholder="School name"
                            />
                            <span *ngIf="submitted && f.school.invalid" class="invalid-form">
                              <small class="form-text text-danger" *ngIf="f.school.errors.required">School must be valid!</small>
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6" >
                          <div class="form-group">
                            <label for="account-birth-date">Birth date</label>
                            <ng2-flatpickr
                              [config]="birthDateOptions"
                              formControlName="dateOfBirth"
                              id="account-birth-date"
                              name="account-birth-date"
                              placeholder="Pick Date"
                            ></ng2-flatpickr>
                          </div>
                        </div>
                        <!--div class="col-12 mt-75">
                          <div class="alert alert-warning mb-50" role="alert">
                            <h4 class="alert-heading">Your email is not confirmed. Please check your inbox.</h4>
                            <div class="alert-body">
                              <a href="javascript: void(0);" class="alert-link">Resend confirmation</a>
                            </div>
                          </div>
                        </div-->
                        <div class="col-12">
                          <!--button *ngIf="!editMode" type="button" class="btn btn-primary mt-2 mr-1" rippleEffect (click)="toggleMode()">Make changes</button-->
                          <button 
                            [disabled]="this.userInfoForm.pristine" 
                            type="submit" 
                            class="btn btn-primary mt-2 mr-1" rippleEffect>Save changes</button>
                          <!--button type="button" class="btn btn-outline-secondary mt-2" rippleEffect (click)="toggleMode()" *ngIf="editMode">Cancel</button-->
                        </div>
                      </div>
                    </form>
                    <!--/ form -->
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="nav-item" ngbNavItem>
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-pill-password"
                data-toggle="pill"
                href="#account-vertical-password"
                aria-expanded="false"
              >
                <i data-feather="lock" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold">Change Password</span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <!-- form -->
                    <form 
                      [formGroup]="changePasswordForm"
                      class="validate-form"
                      (ngSubmit)="onSubmitPassword()">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-current-password">Current Password</label>
                            <div class="input-group form-password-toggle input-group-merge">
                              <input
                                [type]="passwordTextTypeOld ? 'text' : 'password'"
                                class="form-control"
                                id="account-current-password"
                                name="password"
                                formControlName="currentPassword"
                                [ngClass]="{ 'is-invalid error': p_submitted && pf.currentPassword.errors }"
                                placeholder="Current Password"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"
                                  ><i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeOld,
                                      'icon-eye': !passwordTextTypeOld
                                    }"
                                    (click)="togglePasswordTextTypeOld()"
                                  ></i
                                ></span>
                              </div>
                            </div>
                            <div
                                *ngIf="p_submitted && pf.currentPassword.errors"
                                class="invalid-feedback"
                                [ngClass]="{ 'd-block': p_submitted && pf.currentPassword.errors }"
                            >
                                <div *ngIf="pf.currentPassword.errors.required">Password required</div>
                            </div>
                            <div
                            *ngIf="p_submitted && passwordCheckFailed "
                            class="invalid-feedback"
                            [ngClass]="{ 'd-block': p_submitted && passwordCheckFailed }"
                        >
                            <div>Incorrect password</div>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-new-password">New Password</label>
                            <div class="input-group form-password-toggle input-group-merge">
                              <input
                                [type]="passwordTextTypeNew ? 'text' : 'password'"
                                id="account-new-password"
                                name="new-password"
                                formControlName="password"
                                class="form-control"
                                placeholder="New Password"
                                [ngClass]="{ 'is-invalid error': p_submitted && pf.password.errors }"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"
                                  ><i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeNew,
                                      'icon-eye': !passwordTextTypeNew
                                    }"
                                    (click)="togglePasswordTextTypeNew()"
                                  ></i
                                ></span>
                              </div>
                            </div>
                            <div
                                *ngIf="p_submitted && pf.password.errors"
                                class="invalid-feedback"
                                [ngClass]="{ 'd-block': p_submitted && pf.password.errors }"
                            >
                                <div *ngIf="pf.password.errors.required">Password is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-retype-new-password">Re-Enter New Password</label>
                            <div class="input-group form-password-toggle input-group-merge">
                              <input
                                [type]="passwordTextTypeRetype ? 'text' : 'password'"
                                class="form-control"
                                id="account-retype-new-password"
                                name="confirm-new-password"
                                formControlName="confirmPassword"
                                [ngClass]="{ 'is-invalid error': p_submitted && pf.confirmPassword.errors }"
                                placeholder="New Password"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer">
                                  <i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeRetype,
                                      'icon-eye': !passwordTextTypeRetype
                                    }"
                                    (click)="togglePasswordTextTypeRetype()"
                                  >
                                </i>
                                </span>
                              </div>
                            </div>
                            <div
                                *ngIf="p_submitted && pf.confirmPassword.errors"
                                class="invalid-feedback"
                                [ngClass]="{ 'd-block': p_submitted && pf.confirmPassword.errors }"
                            >
                                <div *ngIf="pf.confirmPassword.errors.required">Password required</div>
                            </div>
                            <div
                                *ngIf="p_submitted && pf.password.value !== pf.confirmPassword.value "
                                class="invalid-feedback"
                                [ngClass]="{ 'd-block': p_submitted && pf.password.value !== pf.confirmPassword.value }"
                            >
                                <div>Password does not match</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <button type="submit" class="btn btn-primary mr-1 mt-1" rippleEffect>Save changes</button>
                          <button type="reset" class="btn btn-outline-secondary mt-1" rippleEffect>Cancel</button>
                        </div>
                      </div>
                    </form>
                    <!--/ form -->
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
        <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
      </div>
    </section>
    <!--/ account setting page -->
  </div>
</div>
