import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { catchError, first, map, take } from "rxjs/operators";
import { environment } from "environments/environment";
import { AuthenticationService } from "@core/services/auth";
import { IAnalytics } from "./interfaces/analytics";
import { IPendingRequests } from "./interfaces/adminMetrics";
import { chartData } from "./interfaces/learningActivity";
import { SubBreakDown } from "@core/models/subscription-break-down";
import { ContentConsumptionTotal } from "@core/models/content-consumption-total";
import { SchoolContentConsumption } from "@core/models/school-content-consumption";
import { Params } from "@angular/router";
import { PAGE_PARAMS } from "@core/constants/consts";
import { Page } from "@core/models/pagee";
import { LessonAccess } from "@core/models/lesson-access";
import { FreeContentStatus } from "@core/models/freeContentStatus";

@Injectable({
  providedIn: "root",
})
export class DashboardModuleService {
  url: string;
  analyticsUrl: string;

  private countdownValue = +localStorage.getItem("countdownValue") || 60;
  private countdownInterval: any;
  public countdown$ = new BehaviorSubject<number>(this.countdownValue);
  public showTimer$ = new BehaviorSubject<boolean>(false);
  public freemium$ = new BehaviorSubject<boolean>(false);
  public recallFreemiumStatus$ = new BehaviorSubject<boolean>(false);
  // public adminAnalytics$ = new BehaviorSubject<IAnalytics[]>([]);
  // public newData: IAnalytics = {};
  // public adminAnalytics$ = new BehaviorSubject<IAnalytics>(this.newData);

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    this.url = `${environment.baseUrl}`;
    this.analyticsUrl = `${environment.googleAnalyticsUrl}`;
  }

  startCountdown() {
    // console.log("weeeklyy");
    // this.updateWeeklyFreemium(this.countdownValue);
    // console.log("wapiii");

    const timerRunning = localStorage.getItem("timerRunning");
    if (timerRunning == "true") {
      // return;
    }
    localStorage.setItem("timerRunning", "true");
    this.enableSubscription();
    this.setShowTimer(true);
    clearInterval(this.countdownInterval);
    this.countdownInterval = setInterval(() => {
      // alert(this.countdownValue)
      if (this.countdownValue > 0) {
        this.countdownValue--;
        this.countdown$.next(this.countdownValue);
        localStorage.setItem("countdownValue", this.countdownValue.toString());
      } else {
        this.stopCountdown();
      }
    }, 1000);
  }

  stopCountdown() {
    clearInterval(this.countdownInterval);
    localStorage.removeItem("countdownValue");
    localStorage.removeItem("timerRunning");
    this.showTimer$.next(false);
    this.disableSubscription();
  }

  restoreCountdown(apiCountdownValue: number) {
    console.log("restoreCountdown:startCountDown");
    localStorage.setItem("timerRunning", "true");
    this.setShowTimer(true);
    const countdownValue = localStorage.getItem("countdownValue");
    this.countdownValue =
      countdownValue != undefined && countdownValue != null
        ? Number(countdownValue)
        : apiCountdownValue;
    this.countdown$.next(this.countdownValue);
    this.startCountdown();
  }

  disableSubscription() {
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && currentUser?.subscription) {
      currentUser.subscription.subscribed = false;
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
      this.authService.setUserValue();
      this.updateWeeklyFreemium(0).pipe(first()).subscribe(); //lesson
      this.recallFreemiumStatus$.next(true); //weekly
    }
  }

  get showTimer() {
    return this.showTimer$;
  }

  setShowTimer(value: boolean) {
    this.showTimer$.next(value);
  }

  resetTimer() {
    this.countdownValue = 60;
  }

  enableSubscription() {
    let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));

    currentUser.subscription.subscribed = true;
    currentUser.subscription.subscribedAt = Date.parse(new Date().toString());
    currentUser.subscription.expiresAt = Date.parse(new Date().toString());
    currentUser.subscription.daysLeft = 1;

    localStorage.setItem("currentUser", JSON.stringify(currentUser));

    this.authService.setUserValue();
  }

  //   getFreemimStatus(): Observable<any> {
  //     return this.http.get(`${this.url}/freemium/weekly`);
  // }

  updateWeeklyFreemium(secondsWatched: number): Observable<any> {
    // alert("whataa");
    return this.http.post(`${this.url}/freemium/weekly/lesson`, {
      secondsWatched: secondsWatched,
    });
  }

  setFreemiumStatus(data: any) {
    this.freemium$.next(data);
  }
  //Parent Dash Endpoints
  //Learning Activity
  // getLearningActivity(): Observable<chartData[]> {
  //   return this.http.get<chartData[]>(`${this.url}/dependant/report`);
  // }

  getLearningActivity(
    userId: number,
    startDate: string,
    endDate: string,
    form: number
  ): Observable<any> {
    const body = {
      userId: userId,
      startDate: startDate,
      endDate: endDate,
      form: form,
    };
    return this.http.post(`${this.url}/dependant/report`, body);
  }

  //Access Summary
  getAccessSummary(userId: number, form: number): Observable<any> {
    return this.http.get(
      `${this.url}/dependant/report/summary?${userId}&${form}`
    );
  }

  /** Start Admin Dashboard Endpoints **/
  getActiveSubscriptions(): Observable<any> {
    return this.http.get(`${this.url}/subscription/report/active`);
  }

  failedPaymentAttempts(): Observable<any> {
    return this.http.get(`${this.url}/subscription/report/failed/attempts`);
  }

  totalLearners(): Observable<any> {
    return this.http.get(`${this.url}/user/students`);
  }

  unigueLogins(): Observable<any> {
    return this.http.get(`${this.url}/reports/today/users/average`);
  }

  fetchUniqueLogins(startDate: string, endDate: string) {
    const requestBody = {
      startDate: startDate,
      endDate: endDate,
    };

    return this.http.post<any>(
      `${this.url}/reports/daily/users/average`,
      requestBody
    );
  }

  pendingRequests(): Observable<IPendingRequests> {
    return this.http.get<IPendingRequests>(
      `${this.url}/school/pending?pageSize=5&page=1`
    );
  }

  subscriptionList(startDate: string, endDate: string): Observable<any> {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    return this.http.post(
      `${this.url}/subscription/report/active/list/1?page=0&pageSize=4`,
      body
    );
  }

  newSubscribersPaginated(
    plan: number,
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    return this.http.post(
      `${this.url}/subscription/report/active/list/${plan}?page=${page}&pageSize=${pageSize}`,
      body
    );
  }

  dailyActiveUsersPaginated(
    role: number,
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    return this.http.post(
      `${this.url}/reports/daily/users/average/list/${role}?page=${page}&pageSize=${pageSize}`,
      body
    );
  }

  getAdminAnalytics(): Observable<IAnalytics> {
    return this.http.get<IAnalytics>(
      `${this.analyticsUrl}/googleAnalyticsData`
    );
  }

  averageSessionEngagementTime(
    startDate: string,
    endDate: string
  ): Observable<any> {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    return this.http.post(`${this.analyticsUrl}/averageEngagementTime`, body);
  }

  dailyActiveUsersMobile(startDate: string, endDate: string): Observable<any> {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    return this.http.post(`${this.analyticsUrl}/dailyActiveUsers`, body);
  }

  newLearningUsers(monthCount: number): Observable<any> {
    const body = { monthCount };
    return this.http.post(`${this.analyticsUrl}/newLearningMobileUsers`, body);
  }

  getLoginsToday(
    role: number,
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    return this.http.post(
      `${this.url}/reports/logins/schools/${role}?page=${page}&pageSize=${pageSize}`,
      body
    );
  }

  downloadLoginsToday(
    role: number,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    return this.http.post(
      `${this.url}/reports/logins/schools/${role}?page=${1}&pageSize=${50000}`,
      body
    );
  }

  //Excel Downloads
  // DAU
  downloadDAU(startDate: string, endDate: string): Observable<any> {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    return this.http.post(
      `${this.url}/reports/daily/users/average/list/all/100`,
      body
    );
  }
  //New Subs
  downloadSubscribers(startDate: string, endDate: string): Observable<any> {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    return this.http.post(
      `${this.url}/reports/daily/users/average/list/all/100`,
      body
    );
  }
  //Subscription Stats
  downloadSubscriptionStats(
    startDate: string,
    endDate: string,
    total: number
  ): Observable<any> {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    return this.http.post(
      `${this.url}/subscription/report/active/list/all${total}`,
      body
    );
  }


  getTotalSubsBreakdown(): Observable<SubBreakDown> {
    return this.http.get<SubBreakDown>(`${this.url}/subscription/report/plans`)
  }

  getMostAccessedSubject(): Observable<any> {
    return this.http.get<any>(`${this.url}/lesson-access/report/subject`)
  }

  getContentConsumptionTotal(): Observable<ContentConsumptionTotal> {
    return this.http.get<ContentConsumptionTotal>(`${this.url}/access/report`)
  }

  getSchoolContentConsumption(role: number, params?: Params): Observable<Page<SchoolContentConsumption>> {
    params = { ...PAGE_PARAMS, ...params };
    return this.http.get<Page<SchoolContentConsumption>>(`${this.url}/access/report/consumption?role=${role}`, { params })
  }

  getLessonAccess(limit: number): Observable<LessonAccess[]> {
    return this.http.get<LessonAccess[]>(`${this.url}/lesson-access/report/topic?limit=${limit}`)
  }

  getFreeLessonStatus(): Observable<FreeContentStatus> {
    return this.http.get<FreeContentStatus>(`${this.url}/free`)
  }

  selectFreeLesson(lessonId: number): Observable<any> {
    return this.http.post<any>(`${this.url}/free/lesson?lessonId=${lessonId}`, {})
  }

    /** End Admin Dashboard Endpoints **/

  //shareZl
  shareZerakiLearning(medium: string) {
    const body = { medium };
    return this.http.post(`${this.url}/share`, body);
  }

  // Dependants

  // Parent
  createConnection(dependantUsername: string): Observable<any> {
    const body = { dependantUsername };
    return this.http.post(`${this.url}/dependant`, body);
  }

  cancelConnectionRequest(dependantId: number): Observable<any> {
    return this.http.put(
      `${this.url}/dependant/cancel?dependantId=${dependantId}`,
      {}
    );
  }

  unlinkDependants(dependantId: number): Observable<any> {
    return this.http.put(
      `${this.url}/dependant/unlink?dependantId=${dependantId}`,
      {}
    );
  }

  // Student
  acceptConnectionRequest(parentId: number): Observable<any> {
    return this.http.put(
      `${this.url}/dependant/accept?parentId=${parentId}`,
      {}
    );
  }

  rejectConnectionRequest(parentId: number): Observable<any> {
    return this.http.put(
      `${this.url}/dependant/reject?parentId=${parentId}`,
      {}
    );
  }

  // Teacher
  getRecentlyCreatedAssignments(form: number) {
    return this.http.get(`${this.url}/assignment/recently-created`);
  }

  // dependant get requests
  getDependants(): Observable<any> {
    return this.http.get(`${this.url}/dependant`);
  }

  getDependantRequests(): Observable<any> {
    return this.http.get(`${this.url}/dependant/requests`);
  }

  getLinkedParentAccounts(): Observable<any> {
    return this.http.get(`${this.url}/dependant/parents`);
  }

  // AccessList
  dashSubjectSummary(): Observable<any> {
    return this.http.get(`${this.url}/access/subject-summary`);
  }

  getSubjectSummaries(queryParams: any): Observable<any> {
    return this.http.get(`${this.url}/access/subject-summary`, {
      params: { ...queryParams },
    });
  }

  getStudentSubjectSummary(params?: Params): Observable<any> {
    return this.http.get<any>(`${this.url}/access/subject-summary`, { params })
  }

  getDependentStatistics(studentId: number) {
    return this.http.get(
      `${this.url}/access/subject-summary?studentId=${studentId}&allSubjects=false`
    );
  }

  getStreamAccesList(streamId: number) {
    return this.http.get(`${this.url}/access/stream?streamId=${streamId}`);
  }

  getSubscriptionStatistics(streamId: number) {
    return this.http.get<any>(
      `${this.url}/subscription/statistics?streamId=${streamId}`
    );
  }

  getActiveStudentsIntakeData() {
    return this.http.get(`${this.url}/access/intake/active`);
  }

  getLessonSuggestions() {
    return this.http.get(`${this.url}/lesson/suggestion`);
  }

  getQuizSuggestions() {
    return this.http.get(`${this.url}/quiz/suggestion`);
  }

  // Intakes
  getIntakes(): Observable<any> {
    return this.http.get(`${this.url}/intakes`);
  }

  getByIntake(intakeId: number): Observable<any> {
    return this.http.get(`${this.url}/streams?intakeId=${intakeId}`);
  }

  //test
  getAllSubjectWithContentStatisticsPerForm(studentId: number, form: number) {
    return this.http.get<any>(
      `${this.url}/access/subject-summary?allSubjects=true&studentId=${studentId}&form=${form}`
    );
  }

  getStudentStatisticsByIntakeAndSubject(
    userId: number,
    subjectId: number,
    form: number
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/access/subject-summary?userId=${userId}&subjectId=${subjectId}&form=${form}`
    );
  }

  // test subject
  getSubjects(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/subject/content`).pipe(
      map((response) => response),
      catchError((e: any) => {
        throw new Error(e);
      })
    );
  }

  getSubjectsData(subjectId: number): Observable<any> {
    return this.http
      .get(
        `${environment.baseUrl}/subject/content/records?subjectId=${subjectId}`
      )
      .pipe(
        map((response) => response),
        catchError((e: any) => {
          throw new Error(e);
        })
      );
  }
}

// saveCountDownValue() {
//   const timerRunning = localStorage.getItem('timerRunning');
//   if (timerRunning === 'true') {
//     localStorage.setItem('countdownValue', this.countdownValue.toString());
//     localStorage.setItem('timerRunning', 'true');
//     if(this.countdownValue % 300 == 0) {
//       this.updateWeeklyFreemium(this.countdownValue)
//     }
//   }
// }

// updateWeeklyFreemiumQuiz(payload: any): Observable<any> {
//   return this.http.post(`${this.url}/freemium/weekly/quiz`, payload);
// }

// updateWeeklyFreemiumQuiz(gotCorrect: number, attempts: number, topicId: number):Observable<any> {
//   const payload = {
//     gotCorrect: gotCorrect,
//     attempts: attempts,
//     topicId: topicId
//   };
//   return this.http.post(`${this.url}/freemium/weekly/quiz`, payload);
// }

// stopCountdown() {
//   clearInterval(this.countdownInterval);
//   this.countdown$.next(this.countdownValue);
//   localStorage.setItem('countdownValue', this.countdownValue.toString());
//   localStorage.setItem('timerRunning', 'false');
// }
