import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject, User } from "@core/models";
import { Topic } from "@core/models/topic";
import { AuthenticationService, UserService } from "@core/services/auth";
import { CoreConfigService } from "@core/services/config.service";
import { SubjectService } from "@core/services/subject.service";
import { Observable, forkJoin } from "rxjs";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: ["./onboarding.component.scss"],
})
export class OnboardingComponent implements OnInit {
  selectedForm!: number;
  previousSelectedForm!: number;
  stepper = 1;
  subjects!: Subject[];
  subjectsWithContent!: Subject[];
  topics!: Topic[];
  selectedSubjects: any[] = [];
  isSelectingSubjects = false;
  currentUser: User;
  userName!: string;
  isEnabled = true;
  isIndependentStudent!: boolean;
  subjectsHasChanged!: boolean;

  studentProfile = new FormGroup({
    topicId: new FormControl(null, Validators.required),
    subjectId: new FormControl(null, Validators.required),
    form: new FormControl(null, Validators.required),
  });

  constructor(
    private subjectService: SubjectService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private coreConfigService: CoreConfigService,
    private router: Router
  ) { }

  frontStepper(): void {
    if (this.stepper < 5) {
      this.stepper += 1;
    }

    if (this.stepper == 2 && !this.isIndependentStudent) {
      this.stepper = 3;
    }

    if (this.stepper == 3 && this.selectedForm && this.selectedForm !== this.previousSelectedForm) {
      this.previousSelectedForm = this.selectedForm;
      this.setStudentForm();
    }

    if (this.stepper == 3) {
      this.isSelectingSubjects = true;
    }

    if (this.stepper == 3 && this.selectedSubjects.length > 6) {
      this.isSelectingSubjects = false;
    }

    if (this.stepper == 4) {
      this.isSelectingSubjects = false;
    }

    if (this.stepper == 4 && this.selectedSubjects.length > 6 && this.subjectsHasChanged) {
      this.saveSelectedSubjects();
    }
  }

  backStepper(): void {
    if (this.stepper > 1) {
      this.stepper -= 1;
    }

    if (this.stepper == 2 && !this.isIndependentStudent) {
      this.isSelectingSubjects = false;
      this.stepper = 1;
    }

    if (this.stepper == 3) {
      this.isSelectingSubjects = true;
    }

    if (this.stepper == 3 && this.selectedSubjects.length > 6) {
      this.isSelectingSubjects = false;
    }

    if (this.stepper == 2) {
      this.isSelectingSubjects = false;
    }
  }

  selectSubject(subject: any): void {
    this.isSelectingSubjects = true;
    this.subjectsHasChanged = true;
    subject.selected = !subject.selected;
    if (subject.selected) {
      this.selectedSubjects.push(subject.subjectId);
    } else {
      const index = this.selectedSubjects.indexOf(subject.subjectId);
      if (index !== -1) {
        this.selectedSubjects.splice(index, 1);
      }
    }

    if (this.selectedSubjects.length > 6) {
      this.isSelectingSubjects = false;
    }
  }

  getTopics(): void {
    const subjectId = this.studentProfile.get("subjectId")?.value;
    const form = this.studentProfile.get("form")?.value;
    // const [topicId,subjectId,form] = { ...this.studentProfile.value }
    if (subjectId && form) {
      this.subjectService.getTopicsBySubjectAndForm(subjectId, form).subscribe(
        (res) => {
          this.topics = res;
          this.studentProfile.get("topicId").setValue(null)
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  saveSelectedSubjects(): void {
    const params = {
      subjects: this.selectedSubjects.map((subjectId) => ({ subjectId })),
    };
    this.subjectService.postSelectedSubjects(params).subscribe(
      (res) => {
        this.subjectsHasChanged = false;
        this.getSubjectsWithContent();
        console.log(res)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  getSubjectsWithContent(): void {
    this.subjectService.getSubjectsWithContent().subscribe(
      (res) => {
        console.log(res)
        this.subjectsWithContent = res;
      },
      (err) => {
        console.error(err)
      }
    )
  }

  setStudentForm(): void {
    this.userService.setStudentForm(this.selectedForm).subscribe(
      (res) => {console.log(res)},
      (err) => {console.log("Error:", err)}
    )
  }

  submitStudentProfileForm(): void {
    const params = { ...this.studentProfile.value };
    this.subjectService.createStudentDashboardContent(params).subscribe(
      (res) => {
        this.router.navigate(["/dashboard/new"]);
      },
      (err) => {
        console.log("Error:", err);
      }
    )
  }

  hideSideMenu(): void {
    // Configure the layout
    this.coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getAllSubjects();
    this.hideSideMenu();
  }

  getCurrentUser(): void {
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      this.isIndependentStudent = this.currentUser?.role === 31;
      this.userName = this.currentUser.name;
    }
  }

  getAllSubjects(): void {
    const imageUrls = [
      "../../../../assets/img/english.jpg",
      "../../../../assets/img/gpe_newsdec28-2_0.jpg",
      "../../../../assets/img/maths.jpg",
      "../../../../assets/img/biology.jpg",
      "../../../../assets/img/phy.jpeg",
      "../../../../assets/img/chemistry.jpg",
      "../../../../assets/img/history.jpg",
      "../../../../assets/img/geography.jpg",
      "../../../../assets/img/church.jpg",
      "../../../../assets/img/mosque.jpg",
      "../../../../assets/img/craft.jpg",
      "../../../../assets/img/agri.jpeg",
      "../../../../assets/img/processor.jpg",
      "../../../../assets/img/france.jpg",
      "../../../../assets/img/business.jpg",
    ];

    this.subjectService.getAll().subscribe(
      (res) => {
        this.subjects = res;
        this.subjects = this.subjects.map((subject, index) => ({
          ...subject,
          selected: false,
          imageUrl:
            imageUrls[index] ||
            "https://images.pexels.com/photos/12699625/pexels-photo-12699625.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
        }));
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
