import {
  Component,
  OnDestroy,
  OnInit,
  HostBinding,
  HostListener,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";

import * as _ from "lodash";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

import { AuthenticationService } from "@core/services/auth";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreMediaService } from "@core/services/media.service";

import { User } from "@core/models";

import { NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";

import { PaymentService } from "../../../../@core/services/payment.service";
import Swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Plan } from "../../../main/pages/payment-page/components/provide-subscription-plan/provide-subscription-plan.component";
import { paymentStages } from "../../../main/pages/payment-page/upgrade-page.component";
import { ToastrService } from "ngx-toastr";
import { DashboardModuleService } from "app/main/dashboards/dashboard-module.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  public horizontalMenu: boolean;
  public hiddenMenu: boolean;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: User;
  public isStudent = false;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;
  hideNightVision = true;

  isLoadingPlans = false;
  plans: Plan[] = [];
  paymentProcessStage!: number;
  freemiumStatus: any;
  currentUrl: string;

  @HostBinding("class.fixed-top")
  public isFixed = false;

  @HostBinding("class.navbar-static-style-on-scroll")
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == "navbar-static-top" &&
      this.coreConfig.layout.type == "horizontal"
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  showTimer = false;
  countdownValue = 300;
  countdownSubscription: Subscription;
  showTimerSubscription: Subscription;
  showContentForm = true;

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    private paymentService: PaymentService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private modalService: NgbModal,
    private location: Location,
    private dashboardService: DashboardModuleService
  ) {
    this.currentUser = this._authenticationService.currentUserValue;
    if (this.currentUser) {
      this.isStudent = this.currentUser.role === 30 || this.currentUser.role === 31;
    }

    this.currentUrl = window.location.href;

    // this.dashboardService.startCountdown();
    // this.dashboardService.updateWeeklyFreemium(
    //   Number(localStorage.getItem("countDownValue"))
    // );

    this.countdownSubscription = this.dashboardService.countdown$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.countdownValue = value;
      });
    this.showTimerSubscription = this.dashboardService.showTimer$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.showTimer = value;
      });
    if (this.currentUser) {
      this.showTimerSubscription = this.dashboardService.recallFreemiumStatus$
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          this.loadFreemiumStatus();
        });
    }

    this.languageOptions = {
      en: {
        title: "English",
        flag: "us",
      },
      fr: {
        title: "French",
        flag: "fr",
      },
      de: {
        title: "German",
        flag: "de",
      },
      pt: {
        title: "Portuguese",
        flag: "pt",
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig(
      { app: { appLanguage: language } },
      { emitEvent: true }
    );
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem("prevSkin");

    if (this.currentSkin === "dark") {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : "default" } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem("prevSkin", this.currentSkin);
      this._coreConfigService.setConfig(
        { layout: { skin: "dark" } },
        { emitEvent: true }
      );
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout();
    // this._router.navigate(['login']);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.onEvt();
    // Subscribe to the config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
        this.horizontalMenu = config.layout.type === "horizontal";
        this.hiddenMenu = config.layout.menu.hidden === true;
        this.currentSkin = config.layout.skin;

        // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
        if (this.coreConfig.layout.type === "vertical") {
          setTimeout(() => {
            if (this.coreConfig.layout.navbar.type === "fixed-top") {
              this.isFixed = true;
            }
          }, 0);
        }
      });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == "horizontal") {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          const isFixedTop = this._mediaObserver.isActive("bs-gt-xl");
          if (isFixedTop) {
            this.isFixed = false;
          } else {
            this.isFixed = true;
          }
        });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang,
    });

    // share url
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = window.location.href;
      }
    });

    // check if I should show the content form in the navbar for students
    this.checkCurrentRoute();
  }

  checkCurrentRoute(): void {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showContentForm = this._router.url === "/dashboard/new";
      }
    });
  }

  checkUserSubscription() {
    this.paymentService
      .checkCurrentUserSubscription()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this.paymentService.currentUserSubscription.next(res);
          if (res && res?.subscription?.subscribed) {
            this.currentUser.subscription.subscribed =
              res.subscription.subscribed;
          }

          if (res && !res.privacyPolicyStatus) {
            // this.showUpdateTermsAndCondition();
            this.promptPrivacyStatus();
          }
        },
        (err) => {}
      );
  }

  promptPrivacyStatus() {
    Swal.fire({
      title: "Updates to our privacy policy",
      html: `
            <p>Hello 👋, we have made some updates to our 
             <a href="https://zerakilearningprivacypolicy.blogspot.com/2022/06/zeraki-learning-application-privacy.html" target="_blank" class="text-underline text-primary">privacy policy.</a>
            </p>
            <p>
                <small>By proceeding, you agree to the stipulated terms in our privacy policy.</small>
            </p>
          <h4>What's New ?</h4>
          <p>We added clause number 14 to our privacy policy clarifying Zeraki's approach to promotional messages. You can review the entire document on our policy page.</p>
            `,
      confirmButtonText: "Ok",
      customClass: {
        confirmButton: "btn btn-primary btn-block waves-effect waves-light",
        content: "text-left",
      },
      allowOutsideClick: false,
      focusConfirm: true,
    }).then((confirmed) => {
      if (confirmed.isConfirmed) this.updatePrivacyStatus();
    });
  }

  updatePrivacyStatus() {
    this.paymentService.updatePrivacyPolicyStatus().subscribe();
  }

  modalOpenVC(modalVC) {
    this.modalService.open(modalVC, {
      centered: true,
      size: "lg",
    });
  }

  shareUrl() {
    this.modalService.dismissAll();
    navigator.clipboard.writeText(this.currentUrl).then(() => {
      this.dashboardService.shareZerakiLearning("Web").subscribe(() => {
        this._toastr.success("Link copied successfully!", "Success");
      });
    });
  }

  @ViewChild("upgradeModal") upgradeModal: any;

  public loadUpgradePage() {
    this._modalService.open(this.upgradeModal, {
      windowClass: "modal",
      centered: true,
      size: "lg",
      backdrop: true,
      keyboard: false,
    });
    // this.paymentService.initiatePaymentModule(this.upgradeModal);
  }

  formatTime(timeInSeconds: number) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} mins`;
  }

  loadFreemiumStatus() {
    // const timerRunning = localStorage.getItem("timerRunning");
    // if (!(timerRunning != undefined && timerRunning != null)) {
    //   this.paymentService
    //     .getFreemiumStatus()
    //     .pipe(takeUntil(this.destroy$))
    //     .subscribe((respData) => {
    //       if (respData) {
    //         this.freemiumStatus = respData;
    //         this.dashboardService.setFreemiumStatus(respData);
    //       }
    //     });
    // }
  }

  loadPaymentPlans() {
    this.isLoadingPlans = true;
    this.paymentService.getPlans().subscribe(
      (data) => {
        this.plans = data.plans;
        this.isLoadingPlans = false;
      },
      (error) => {
        this.isLoadingPlans = false;
      }
    );
  }

  processStage(stage: any) {
    this.paymentProcessStage = stage;
  }

  cancelPaymentProcess() {
    if (this.paymentProcessStage == paymentStages.DISPLAY_PAYMENT_SUCCESS)
      window.location.reload();
    this._modalService.dismissAll();
  }

  onEvt() {
    this.paymentService.paymentEvent.subscribe((name: string) => {
      this.loadUpgradePage();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();

    this.countdownSubscription.unsubscribe();
    this.showTimerSubscription.unsubscribe();
    this.dashboardService.stopCountdown();
  }
}
