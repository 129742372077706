<div class="on-boarding d-flex align-items-center justify-content-center">
  <div class="overlay"></div>
    <div class="card onboarding-card mt-2">
      <div class="card-body">
        <div class="step-indicator mt-2">
          <div class="dot" [ngClass]="{'active' : stepper == 1 }"></div>
          <div class="dot" [ngClass]="{'active' : stepper == 2 }" ></div>
          <div class="dot" [ngClass]="{'active' : stepper == 3 }"></div>
          <div class="dot" [ngClass]="{'active' : stepper == 4 }"></div>
          <div class="dot" [ngClass]="{'active' : stepper == 5 }"></div>
        </div>
        <!-- welcome -->
        <ng-container *ngIf="stepper == 1">
        <div class="welcome d-flex flex-column justify-content-center align-items-center">
            <img
              src="https://images.pexels.com/photos/12699625/pexels-photo-12699625.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load"
              height="90"
              width="90"
              class="rounded-circle"
              alt="avatar"
            />
          <h4 class="text-center mt-1 mb-1">
            Welcome to Zeraki Learning {{ userName }}
          </h4>
          <p class="text-center">
            Your answers to the next questions will help us customize your
            dashboard among other cool features
          </p>
        </div>
      </ng-container>
        <!-- Form select -->
        <ng-container *ngIf="stepper == 2 && isIndependentStudent">
        <div class="form-selection">
          <h4 class="text-center mb-2">Please select your form</h4>
          <form>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [(ngModel)]="selectedForm"
                value="1"
                name="formCheck"
                id="flexCheckDefault1"
              />
              <label class="form-check-label" for="flexCheckDefault1">
                Form One
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [(ngModel)]="selectedForm"
                value="2"
                name="formCheck"
                id="flexCheckDefault2"
              />
              <label class="form-check-label" for="flexCheckDefault2">
                Form Two
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                [(ngModel)]="selectedForm"
                type="radio"
                value="3"
                name="formCheck"
                id="flexCheckDefault3"
              />
              <label class="form-check-label" for="flexCheckDefault3">
                Form Three
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [(ngModel)]="selectedForm"
                value="4"
                name="formCheck"
                id="flexCheckDefault4"
              />
              <label class="form-check-label" for="flexCheckDefault4">
                Form Four
              </label>
            </div>
          </form>
        </div>
      </ng-container>
        <!-- subject selection -->
        <ng-container *ngIf="stepper == 3">
        <div class="subject-selection">
          <h4 class="text-center mt-4 mb-3">Please select at least seven subjects</h4>
          <div class="row">
            <div *ngFor="let subject of subjects" class="col-xl-2 col-md-4 col-sm-6">
              <div class="card border-0 text-white cursor-pointer" [ngClass]="{'selected': subject.selected}" (click)="selectSubject(subject)">
                <img
                  [src]="subject.imageUrl"
                  alt="Subject image"
                  class="card-img"
                />
                <div class="card-img-overlay bg-overlay">
                  <p class="card-text font-weight-bolder">
                    {{ subject.name }}
                  </p>
                </div>
              </div>
            </div>
          <ng-template #isLoadingImages>
            <app-loader [height]="100"></app-loader>
          </ng-template>
          </div>
        </div>
      </ng-container>
        <!-- Subject content selection -->
        <ng-container *ngIf="stepper == 4">
        <div class="study-profile text-center mt-4">
          <h4>What do you want to study today?</h4>
          <form [formGroup]="studentProfile" class="row gx-3 gy-2 mt-3 topic-selection">
            <div class="col-4">
              <ng-select formControlName="subjectId" placeholder="Select Subject" (change)="getTopics()">
                <ng-option *ngFor="let subject of subjectsWithContent" [value]="subject.subjectId" [attr.selected]="subjects[0]">{{subject.name}}</ng-option>
             </ng-select>
            </div>
            <div class="col-4">
              <ng-select formControlName="form" placeholder="Select Form" (change)="getTopics()">
                <ng-option value=1>Form One</ng-option>
                <ng-option value=2>Form Two</ng-option>
                <ng-option value=3>Form Three</ng-option>
                <ng-option value=4>Form Four</ng-option>
             </ng-select>
            </div>
            <div class="col-4">
              <ng-select formControlName="topicId" placeholder="Select Topic">
                <ng-container *ngIf="topics?.length; else loadingTopics">
                <ng-option *ngFor="let topic of topics" [value]="topic.topicId">{{topic.title}}</ng-option>
              </ng-container>
              <ng-template #loadingTopics>
                <ng-option>Loading...</ng-option>
              </ng-template>
             </ng-select>
            </div>
          </form>
        </div>
      </ng-container>
        <!-- Zeraki download -->
        <ng-container *ngIf="stepper == 5">
        <div class="download d-flex align-items-center justify-content-center">
          <div class="download-text mr-1">
            <img src="../../../../assets/img/learning.png" width="170"
            alt="zeraki logo">
            <h4 class="mt-1 mb-1 font-weight-bolder">
             Get Zeraki Learning on your phone
            </h4>
            <p>Scan the QR Code with your phone's camera to download our app</p>
          </div>
          <div>
            <img src="../../../../assets/img/mockup.png"
            height="570"
           >
          </div>
        </div>
      </ng-container>
      </div>
      <div class="card-footer mb-4">
        <div class="d-grid col-6 mx-auto">
          <ng-container *ngIf="(stepper == 3 && selectedSubjects.length < 7)">
          <button class="btn btn-primary btn-block font-weight-bolder">
            Pick {{ 7 - selectedSubjects.length }} more subject{{ selectedSubjects.length == 6 ? '' : 's'}}
          </button>
        </ng-container>
          <ng-container *ngIf="(stepper != 5 && !isSelectingSubjects)">
            <button class="btn btn-primary btn-block" (click)="frontStepper()" [disabled]="!isEnabled || (stepper == 4 && !studentProfile.valid)">
              Continue
            </button>
          </ng-container>
          <ng-container *ngIf="stepper == 5" #finish>
            <button class="btn btn-primary btn-block" (click)="submitStudentProfileForm()">
              Finish
            </button>
          </ng-container>
          <ng-container *ngIf="stepper > 1">
            <button class="btn btn-primary btn-block" (click)="backStepper()">
              Back
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
