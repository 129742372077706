<li ngbDropdown class="nav-item dropdown-notification mr-25" *ngIf="notifications">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">{{
      notifications.assignments?.length + notifications.notes?.length + notifications.lessons?.length
    }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ notifications.assignments?.length + notifications.notes?.length + notifications.lessons?.length }} New
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li 
      class="scrollable-container media-list" 
      [perfectScrollbar]
      style="max-height: 300px;">
      <div class="media d-flex align-items-start cursor-pointer" *ngFor="let message of notifications.notes">
        <div class="media-left">
          <div class="avatar p-50 bg-primary">
            <i data-feather="book-open" class="" size="25"></i>
          </div>
        </div>
        <div 
          class="media-body"  
          [routerLink]="[ '/notes/view', message.noteId ]" >
          <p class="media-heading">
            <span class="font-weight-bolder">New note 📖</span> uploaded!
          </p>
          <small class="notification-text">{{ message.noteTitle }}</small>
        </div>
        <div class="media-right cursor-pointer m-0" (click)="deleteNotification(12,message.noteId)">
          <i data-feather="x" class="text-danger" size="25"></i>
        </div>
      </div>
      <div class="media d-flex align-items-start cursor-pointer" *ngFor="let message of notifications.assignments">
        <div class="media-left">
          <div class="avatar bg-secondary p-50">
            <i data-feather="edit" size="25"></i>
          </div>
        </div>
        <div 
          class="media-body"
          (click)="processAssignmentNotification(message)"
        >
          <p class="media-heading">
            <span class="font-weight-bolder">New assignment 📖</span> uploaded!
          </p>
          <small class="notification-text">{{ message.assignmentTitle }}</small>
        </div>
        <div class="media-right text-danger m-0 cursor-pointer" (click)="deleteNotification(10,message.assignmentId)">
          <i data-feather="x" class="text-danger mt-50" size="25"></i>
        </div>
      </div>
      <div class="media d-flex align-items-center cursor-pointer" *ngIf="notifications.lessons?.length > 0">
        <h6 class="font-weight-bolder mr-auto mb-0">Video Notifications</h6>
      </div>
      <div 
        class="media d-flex align-items-start"
        *ngFor="let videoMessage of notifications.lessons">
        <div class="media-left">
          <div class="avatar" [ngClass]="'bg-light-success'" >
            <div class="avatar-content">
              <i data-feather="play"></i>
            </div>
          </div>
        </div>
        <div 
          class="media-body"
          [routerLink]="['/learn/subject',videoMessage.subjectId,'form',videoMessage.form,'topic',videoMessage.topicId]"
          [queryParams]="{lessonId: videoMessage.lessonId}">
          <p class="media-heading">
            <span class="font-weight-bolder">New lesson 📖</span> uploaded!
          </p>
          <small class="notification-text">{{ videoMessage.lessonTitle }}</small>
        </div>
        <div class="media-right" (click)="deleteNotification(11,videoMessage.lessonId)">
          <i data-feather="x" class="text-danger" size="25"></i>
        </div>
      </div>
    </li>
    <!--/ Notifications content -->
    
    <!-- Notifications clear notifications -->
    <li
      class="dropdown-menu-footer cursor-pointer" 
      *ngIf=" notifications.assignments?.length + notifications.notes?.length + notifications.lessons?.length > 0"
      (click)="clearNotifications()" >
      <a class="btn btn-info btn-block text-black"  href="javascript:void(0)">Clear notifications</a>
    </li>
    <!--/ Notifications clear notifications -->

    <!-- Notifications no content -->
    <li class="dropdown-menu-footer" *ngIf=" notifications.assignments?.length + notifications.notes?.length + notifications.lessons?.length == 0">
      <h3 class="bg-secondary text-center p-1 text-white">No new notifications</h3>
    </li>
    <!--/ Notifications no content -->

    <!-- Notifications footer -->
    <!--li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a>
    </li-->
    <!--/ Notifications footer -->
  </ul>
</li>
