import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Router } from "@angular/router";
import { Role, User } from "@core/models";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import Subjects from "../../assets/json/subjects.json";

@Injectable({
  providedIn: "root",
})
export class HelperFunctionsService {
  /**
   * Constructor
   *
   */
  constructor(private _toastrService: ToastrService, private _router: Router) {}

  // Public methods

  /**
   * Redirect to user's appropriate dashboard
   */
  navigateToUsersDashboard(currentUser: User) {
    if (
      currentUser.userRole == Role.Student ||
      currentUser.userRole == Role.independentStudent
    ) {
      this._router.navigate(["/dashboard/new"]);
    }
    if (currentUser.userRole == Role.Parent) {
      this._router.navigate(["/dashboard/parent"]);
    }
    if (
      currentUser.userRole == Role.Teacher ||
      currentUser.userRole == Role.CustomerSupportAdmin
    ) {
      this._router.navigate(["/dashboard/teacher"]);
    }
    if (currentUser.userRole == Role.Admin) {
      this._router.navigate(["/dashboard/admin"]);
    }
    if (currentUser.userRole == Role.SuperAdmin) {
      this._router.navigate(["/dashboard/super-admin"]);
    }
  }

  navigateToAssignmentsView(currentUser: User) {
    if (
      currentUser.userRole == Role.Student ||
      currentUser.userRole == Role.independentStudent
    ) {
      this._router.navigate(["/assignments/student"]);
    } else {
      this._router.navigate(["/assignments/teacher"]);
    }
  }

  /**
   * Show a Success Toastr
   *
   */
  showSucessToastr(message: string, heading: string = "Success!"): void {
    this._toastrService.success(`🙌 ${message}`, heading, {
      timeOut: 5000,
      toastClass: "toast ngx-toastr",
      closeButton: true,
    });
  }

  /**
   * Show a Success Toastr
   *
   */
  showWarningToastr(message: string, heading: string = "Error!") {
    this._toastrService.warning(`⚠️ ${message}`, heading, {
      timeOut: 5000,
      toastClass: "toast ngx-toastr",
      closeButton: true,
    });
  }

  /**
   * Retrieve subject's icon
   *
   */
  getIconURL(subjectId: number | string, form: number | string = 0) {
    const subject = Subjects.find((sbj) => sbj.subjectId == subjectId);
    let img = "assets/images/icons/subjects/default.jpg";
    if (subject) {
      img =
        form == 0
          ? subject.subject_style.icon
          : subject.subject_style.formIcons[form];
    }
    return img;
  }

  /**
   * Retrieve subject's icon
   *
   */
  getColorCode(subjectId: number | string) {
    const subject = Subjects.find((sbj) => sbj.subjectId == subjectId);
    if (subject) {
      return subject.subject_style.color;
    }
    return "#2EA5DE";
  }

  /**
   * Retrieve subject's image
   *
   */
  getImageUrl(subjectId: number | string) {
    const subject = Subjects.find((sbj) => sbj.subjectId == subjectId);
    if (subject) {
      return subject.subject_style.image
        ? subject.subject_style.image
        : "assets/images/slider/03.jpg";
    }
    return "assets/images/slider/03.jpg";
  }

  getRandomImage(subjectName: string) {
    return `assets/images/subjects/${subjectName
      .toLowerCase()
      .split(".")
      .join("")}${Math.ceil(Math.random() * 10)}.jpg`;
  }

  /**
   * Get Subject Name
   */
  getSubjectName(subjectId: number | string) {
    const subject = Subjects.find((sbj) => sbj.subjectId == subjectId);
    if (subject) {
      return subject.name;
    }

    return "Subject";
  }

  triggerSuccessAlert(title, message) {
    Swal.fire({
      title: title,
      text: message,
      icon: "success",
      timer: 2000,
      showConfirmButton: false,
    });
  }

  triggerWarningAlert(title, message) {
    Swal.fire({
      title: title,
      text: message,
      icon: "success",
      timer: 2000,
      showConfirmButton: false,
    });
  }

  intakeAsNumber(form) {
    return Number(form.match(/(\d+)/)[0]);
  }

  matchPassword(firstControl, secondControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get(firstControl).value;
      const confirm = control.get(secondControl).value;

      if (password != confirm) {
        return { noMatch: true };
      }

      return null;
    };
  }
}
