import { QuizAnswer } from ".";

export interface QuizQuestion{
  answerDTOS            : Array<QuizAnswer>;
  description           : string;
  descriptionMediaList  : Array<any>;
  prompt                : string;
  questionId            : number;
  type                  : number;
  correctAnswer         : QuizAnswer;
  index?                : number;
  checkList?            : any;
}